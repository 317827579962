import {BaseIconProps} from '@/assets/icons/types';

export const TabletIcon = ({
    width = '22',
    height = '28',
    viewBox = '0 0 22 28',
    fill = '#001a70',
    testId,
}: BaseIconProps) => (
    <svg data-testid={testId} width={width} height={height} viewBox={viewBox}>
        <path
            d="M18.6315789,0 L5.36842105,0 C4.06105263,0 3,1.12 3,2.5 L3,21.5 C3,22.88 4.06105263,24 5.36842105,24 L18.6315789,24 C19.9389474,24 21,22.88 21,21.5 L21,2.5 C21,1.12 19.9389474,0 18.6315789,0 Z M12,23 C11.2136842,23 10.5789474,22.33 10.5789474,21.5 C10.5789474,20.67 11.2136842,20 12,20 C12.7863158,20 13.4210526,20.67 13.4210526,21.5 C13.4210526,22.33 12.7863158,23 12,23 Z M19.1052632,3 L19.1052632,19 L4.89473684,19 L4.89473684,3 L19.1052632,3 Z M13,14 L11,14 L11,16 L13,16 L13,14 Z M13,6 L11,6 L11,12 L13,12 L13,6 Z"
            fill={fill}
        />
    </svg>
);
