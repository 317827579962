import {createSlice} from '@reduxjs/toolkit';

import {PageNotAccessibleState} from './stateTypes';

const initialState: PageNotAccessibleState = {
    showModal: false,
};

const SLICE_NAME = 'pageNotAccessible';

const PageNotAccessibleSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        showNotAccessibleModal: (state) => {
            state.showModal = true;
        },
        hideNotAccessibleModal: (state) => {
            state.showModal = false;
        },
    },
});

const {reducer, actions} = PageNotAccessibleSlice;

export const {showNotAccessibleModal, hideNotAccessibleModal} = actions;

export default reducer;
