import {VideoSystemDevices} from '@/types/video';

import {loadPrevSelectedDevice} from './loadPrevSelectedDevice';

type Props = {
    onAdded?: (localDevice: {id: string; name: string}) => void;
    onRemoved?: (localDeviceId: string) => void;
    setCurDevice?: (device: any) => void;
    deviceName: VideoSystemDevices;
};

declare global {
    interface Window {
        [key: string]: any;
    }
}

export const deviceListenerCb = ({onAdded, onRemoved, setCurDevice, deviceName}: Props) => {
    return {
        onAdded: function (localDevice: any) {
            if (!window[deviceName]) {
                window[deviceName] = [];
            }

            if (window[deviceName]?.find((dev: any) => dev.id === localDevice.id)) {
                return;
            }

            if (localDevice.id.match(/communications/i)) {
                return;
            }

            if (localDevice.id === 'default') {
                const newDevice = {
                    ...localDevice,
                    name: `System default`,
                };
                window[deviceName].unshift(newDevice);
                onAdded?.(newDevice);
            } else {
                const newDevice = {
                    ...localDevice,
                    name: `${localDevice.name.replace(/\s*\([a-zA-Z0-9]+:[a-zA-Z0-9]+\)/, '')}`,
                };
                window[deviceName].push(newDevice);
                onAdded?.(newDevice);
            }

            loadPrevSelectedDevice(deviceName);

            console.info(`[VideoWidget] ${deviceName} added: ` + localDevice.name);
        },
        onRemoved: function (localDevice: any) {
            window[deviceName] = window[deviceName].filter(function (dev: any) {
                return dev.id !== localDevice.id;
            });
            onRemoved?.(localDevice.id);
            console.info(`[VideoWidget] ${deviceName} removed: ` + localDevice.name);
        },
        onSelected: function (localDevice: any) {
            console.info(`[VideoWidget] ${deviceName} selected: ` + localDevice.name);
            const device = window[deviceName]?.find((dev: any) => dev.id === localDevice.id);
            device && setCurDevice?.(device);
        },
        onStateUpdated: function (localDevice: any, state: any) {
            console.info(`[VideoWidget] ${deviceName} state updated: ` + localDevice.name + ' State: ' + state);
        },
    };
};
