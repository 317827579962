import errorBoundaryListener from '@/components/base/MHErrorBoundary/redux/featureListeners';
import referralFieldsListener from '@/components/features/AppointmentInformation/redux/featureListeners';
import confirmPartnerChangeDialog from '@/components/features/ConfirmPartnerChangeDialog/redux/featureListeners';
import createImprovementLogEntryButtonListener from '@/components/features/CreateImprovementLogEntryButton/redux/featureListeners';
import educationDocumentHistory from '@/components/features/EducationDocumentHistory/redux/featureListeners';
import PatientInfoAndScheduleListener from '@/components/features/PatientInfoAndSchedule/redux/featureListeners';
import referralDocumentationListener from '@/components/features/ReferralDocumentation/redux/featureListeners';
import SelectStatesListener from '@/components/features/SelectStates/redux/featureListeners';
import shortPatientInfoStringListener from '@/components/features/ShortPatientInfoString/redux/featureListeners';
import undoCheckInCheckOutDialog from '@/components/features/UndoCheckInCheckOutDialog/redux/featureListeners';
import changeMissionControl from '@/components/general/ChangeMissionControl/redux/featureListener';
import commonData from '@/redux/commonData/featureListeners';
import commonUserData from '@/redux/commonUserData/featureListeners';

import type {AppStartListening} from './store/listenerMiddleware';

export const connectRootListeners = (startAppListening: AppStartListening) => {
    educationDocumentHistory(startAppListening);
    undoCheckInCheckOutDialog(startAppListening);
    confirmPartnerChangeDialog(startAppListening);
    referralDocumentationListener(startAppListening);
    referralFieldsListener(startAppListening);
    createImprovementLogEntryButtonListener(startAppListening);
    PatientInfoAndScheduleListener(startAppListening);
    SelectStatesListener(startAppListening);
    shortPatientInfoStringListener(startAppListening);
    commonData(startAppListening);
    commonUserData(startAppListening);
    changeMissionControl(startAppListening);
    errorBoundaryListener(startAppListening);
};
