import {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {MenuItem} from '@mui/material';
import classnames from 'classnames';

import {MHErrorBoundary, MHLoader} from '@/components/base';
import routes from '@/constants/routes';

import {Patient} from '../../shared/Patient';
import {activeMeetingsSelector} from '../redux/selectors';
import {getActiveMeetings, markSeenCalls} from '../redux/slice';
import {CallActions} from './CallActions';
import {Meetings} from './Meetings';

import commonStyles from '../../shared/common.module.scss';
import styles from './content.module.scss';

type ContentProps = {
    onClose: () => void;
};

export const Content = ({onClose}: ContentProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {getActiveMeetingsLoading, data} = useSelector(activeMeetingsSelector, shallowEqual);

    useEffect(() => {
        dispatch(getActiveMeetings());

        return () => {
            dispatch(markSeenCalls());
        };
    }, [dispatch]);

    if (getActiveMeetingsLoading) {
        return <MHLoader />;
    }
    if (!data.patientMissedCalls?.length) {
        return <div className={commonStyles.noDataMessage}>No Missed Patient Calls</div>;
    }
    const goToPatientPage = (id: number) => {
        history.push(routes.PATIENT_SINGLE_CALL_HISTORY(id.toString()));
        onClose();
    };

    return (
        <MHErrorBoundary>
            {data.patientMissedCalls.map(({patientData, missedCalls, latestPhoneNumber}) => (
                <MenuItem
                    classes={{
                        root: classnames(styles.menuItem, {
                            [styles.missedCall]: missedCalls.some(({viewed}) => !viewed),
                        }),
                    }}
                    key={patientData.id}
                    onClick={() => goToPatientPage(patientData.id)}
                >
                    <Patient {...patientData} fullName={`${patientData.lastName}, ${patientData.firstName}`} />
                    <Meetings missedCalls={missedCalls} patientTimeZone={patientData.timeZone} />

                    <div className={styles.callIcons}>
                        <CallActions
                            patientId={patientData.id}
                            patientFirstName={patientData.firstName}
                            patientLastName={patientData.lastName}
                            callType={missedCalls[0].type}
                            hasActiveKitAssigned={patientData.hasActiveKitAssigned}
                            latestPhoneNumber={latestPhoneNumber}
                        />
                    </div>
                    <div className={styles.icon}>
                        <ChevronRightIcon fontSize="inherit" />
                    </div>
                </MenuItem>
            ))}
        </MHErrorBoundary>
    );
};
