import {shallowEqual, useSelector} from 'react-redux';
import {Table, TableContainer} from '@mui/material';
import classnames from 'classnames';

import {MHTableBody} from '@/components/base';
import {QueueARNItem} from '@/redux/commonUserData/stateTypes';
import awsConnectService from '@/services/awsConnectApiService';

import {transferQueueListSelector} from '../redux/selectors';
import {AvailableCallTransferAgentsProps} from '../redux/stateTypes';
import {getColumnConfig} from './columnConfig';

import commonStyles from '../shared/common.module.scss';

const AGENT_QUEUE_NAME = 'Agent Transfer';

export const TransferQueuesListTable = ({handlePopoverClose}: AvailableCallTransferAgentsProps) => {
    const {queueARN} = useSelector(transferQueueListSelector, shallowEqual);

    const callTransferToQueueHandler = (queue: QueueARNItem) => {
        awsConnectService.transferToQueue(queue);
        handlePopoverClose();
    };

    const tableData = queueARN.filter(({name}) => name !== AGENT_QUEUE_NAME);
    const loadedAndNoData = !tableData.length;

    return (
        <TableContainer className={commonStyles.tableContainerRoot}>
            {loadedAndNoData && <div className={commonStyles.noDataMessage}>No available queues</div>}
            <Table>
                <MHTableBody
                    data={tableData}
                    columns={getColumnConfig({callTransferToQueueHandler})}
                    externalRowStyles={(classes) => ({
                        ...classes,
                        root: classnames(classes.root, commonStyles.tableRoot),
                    })}
                />
            </Table>
        </TableContainer>
    );
};
