import {combineReducers, Reducer} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {configureStore} from '@reduxjs/toolkit';
import set from 'lodash/set';

import allReducers from '../rootReducer';
import sagas from '../rootSaga';
import {listenerMiddleware} from './listenerMiddleware';
import createSagaInjector from './sagaInjector';

type ReducerMap = Record<string, Reducer>;

type DeepReducerMap = Record<string, Reducer | ReducerMap>;

const rawReducerMap: ReducerMap = {...allReducers};

const setReducerOrReducerMap = (path: string, reducer: Reducer | ReducerMap) => {
    set(rawReducerMap, path, reducer);
};

const isReducer = (reducerOrMap: Reducer | ReducerMap): reducerOrMap is Reducer => typeof reducerOrMap === 'function';

const mapReducersDeep = <T extends DeepReducerMap>(rawReducers: T): ReducerMap => {
    return Object.keys(rawReducers).reduce((reducerMap, key: keyof T) => {
        const reducer = rawReducers[key];

        if (isReducer(reducer)) {
            return {
                ...reducerMap,
                [key]: reducer,
            };
        } else {
            const converted = combineReducers(mapReducersDeep(reducer));
            return {
                ...reducerMap,
                [key]: converted,
            };
        }
    }, {} as ReducerMap);
};

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: combineReducers(rawReducerMap),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({serializableCheck: false}).concat(sagaMiddleware).prepend(listenerMiddleware.middleware),
});

export type AppDispatch = typeof store.dispatch;

const injectSaga = createSagaInjector(sagaMiddleware.run, sagas);

const injectReducer = (path: string, reducerOrReducerMap: Reducer | ReducerMap) => {
    setReducerOrReducerMap(path, reducerOrReducerMap);

    const deepReducerMap = mapReducersDeep(rawReducerMap);

    const combinedReducers = combineReducers(deepReducerMap);

    store.replaceReducer(combinedReducers);
};

export {type DeepReducerMap, injectReducer, injectSaga, mapReducersDeep, store};
