import {useEffect, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';

import {initAwsConnect} from '@/services/awsConnectApiService';

import {employmentSelector, patientIdSelector, phoneCallDataSelector} from '../redux/selectors';
import {AGENT_STATUSES} from '../shared/constants';
import {CallControls} from './CallControls';
import {CallToNumberInput} from './CallToNumberInput';
import {CCPControls} from './CCPControls';

export const CallHandler = () => {
    const {isOutboundCallActive, awsConnectAgentStatus, needToShowCPPPanel} = useSelector(
        phoneCallDataSelector,
        shallowEqual
    );
    const {routingProfile} = useSelector(employmentSelector, shallowEqual);
    const patientId = useSelector(patientIdSelector, shallowEqual);

    const isLargerScreen = useMediaQuery('(min-width:1150px)');

    const [showCPPPanel, setShowCPPPanel] = useState(false);

    const history = useHistory();

    useEffect(() => {
        routingProfile && initAwsConnect(history);
    }, [routingProfile, history]);

    useEffect(() => {
        awsConnectAgentStatus && setShowCPPPanel(false);
    }, [awsConnectAgentStatus, setShowCPPPanel]);

    useEffect(() => {
        if (needToShowCPPPanel) setShowCPPPanel(true);
    }, [needToShowCPPPanel, setShowCPPPanel]);

    const showInputNumber = awsConnectAgentStatus === AGENT_STATUSES.AVAILABLE && !isOutboundCallActive;

    return (
        <>
            {isOutboundCallActive && <CallControls />}
            <CCPControls handleCCPStatus={setShowCPPPanel} ccpStatus={showCPPPanel} />
            {showInputNumber && isLargerScreen && (
                <CallToNumberInput
                    onOutboundCall={setShowCPPPanel}
                    outboundCallLogData={patientId ? {patient_context: patientId} : null}
                />
            )}
        </>
    );
};
