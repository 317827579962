import {BaseIconProps} from '@/assets/icons/types';

type PerformanceImprovementIconProps = BaseIconProps & {
    className?: string;
};

export const PerformanceImprovementIcon = ({
    width = '24',
    height = '24',
    viewBox = '0 0 24 24',
    fill = 'currentColor',
    className,
}: PerformanceImprovementIconProps) => {
    return (
        <svg width={width} height={height} viewBox={viewBox} className={className}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 2H4C2.9 2 2.01 2.9 2.01 4L2 19L4.00389 16.9961L4.01 6C4.01 4.9 4.9 4 6 4L18 4C18 2.9 17.1 2 16 2ZM8 6H20C21.1 6 22 6.9 22 8V17C22 18.1 21.1 19 20 19H10L6 23L6.01 8C6.01 6.9 6.9 6 8 6ZM9.17 17H20V8H8V18.17L8.58 17.59L9.17 17ZM15 13.5H13V15H15V13.5ZM15 9.75H13V12.75H15V9.75Z"
                fill={fill}
            />
        </svg>
    );
};
