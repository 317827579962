import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {PatientResponseDTO} from '@/types/gatewayDataModels';

import {ShortPatientInfoStringState} from './stateTypes';

const initialState: ShortPatientInfoStringState = {
    patientInfo: null,
};

const {actions, reducer} = createSlice({
    name: 'shortPatientInfoString',
    initialState,
    reducers: {
        getPatientInfo: (
            state,
            _action: PayloadAction<{
                patientId: number;
            }>
        ) => state,
        getPatientInfoSuccess: (state, action: PayloadAction<PatientResponseDTO>) => {
            state.patientInfo = action.payload;
        },
        getPatientInfoFailed: (state) => state,
        clearState: () => initialState,
    },
});

export const {getPatientInfo, getPatientInfoSuccess, getPatientInfoFailed, clearState} = actions;

export default reducer;
