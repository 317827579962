import {ChangeEvent, useState} from 'react';
import {Tabs} from '@mui/material';

import {MHButton, MHTab, MHTabPanel} from '@/components/base';

import {SettingsBgFilters} from './SettingsBgFilters';
import {SettingsDevices} from './SettingsDevices';
import {SelectOption} from './VideoCallWidget';

import styles from './videoCall.module.scss';

export type SettingsDevicesProps = {
    micList: SelectOption[];
    speakerList: SelectOption[];
    cameraList: SelectOption[];
    curMic: SelectOption;
    curSpeaker: SelectOption;
    curCamera: SelectOption;
    setCurMic: (mic: SelectOption) => void;
    setCurSpeaker: (speaker: SelectOption) => void;
    setCurCamera: (camera: SelectOption) => void;
};

type SettingsProps = {
    onClose: () => void;
} & SettingsDevicesProps;

export default function Settings({onClose, ...devicesProps}: SettingsProps) {
    const [mainTabValue, setMainTabValue] = useState(1);
    const handleMainTabChange = (_event: ChangeEvent<any>, index: number) => setMainTabValue(index);

    return (
        <div className={styles.settingsWrapper}>
            <div className={styles.settings}>
                <span
                    style={{
                        color: 'white',
                        fontSize: '20px',
                        paddingBottom: '20px',
                    }}
                >
                    Settings
                </span>

                <Tabs
                    value={mainTabValue}
                    classes={{
                        indicator: styles.muiTabsIndicator,
                    }}
                    onChange={handleMainTabChange}
                >
                    <MHTab className={styles.muiTabRoot} key={1} tabIndex={1} label={'Background filters'} value={1} />
                    <MHTab className={styles.muiTabRoot} key={2} tabIndex={2} label={'Devices'} value={2} />
                </Tabs>
                <MHTabPanel value={mainTabValue} index={1}>
                    <SettingsBgFilters />
                </MHTabPanel>
                <MHTabPanel value={mainTabValue} index={2}>
                    <SettingsDevices {...devicesProps} />
                </MHTabPanel>
            </div>

            <MHButton
                className={styles.settingsButton}
                onClickHandler={() => {
                    onClose();
                }}
                text="Close"
            />
        </div>
    );
}
