import {OptionProps} from './types';

const interpreters: OptionProps[] = [
    {value: '7002025916@csip.stratusvideo.com', label: 'Amharic Interpreter'},
    {value: '7002025917@csip.stratusvideo.com', label: 'Arabic Interpreter'},
    {value: '7002025918@csip.stratusvideo.com', label: 'Armenian Interpreter'},
    {value: '7002025974@csip.stratusvideo.com', label: 'ASL Interpreter'},
    {value: '7002026060@csip.stratusvideo.com', label: 'Audio Only Languages'},
    {value: '7002025919@csip.stratusvideo.com', label: 'Bengali Interpreter'},
    {value: '7002025920@csip.stratusvideo.com', label: 'Bosnian Interpreter'},
    {value: '7002025921@csip.stratusvideo.com', label: 'Burmese Interpreter'},
    {value: '7002025922@csip.stratusvideo.com', label: 'Cambodian Interpreter'},
    {value: '7002025923@csip.stratusvideo.com', label: 'Cantonese Interpreter'},
    {value: '7002038599@csip.stratusvideo.com', label: 'Cape Verdean Creole Interpreter'},
    {value: '7002026059@csip.stratusvideo.com', label: 'CDI Interpreter'},
    {value: '7002025924@csip.stratusvideo.com', label: 'Croatian Interpreter'},
    {value: '7002030465@csip.stratusvideo.com', label: 'Dari Interpreter'},
    {value: '7002025925@csip.stratusvideo.com', label: 'Farsi Interpreter'},
    {value: '7002025926@csip.stratusvideo.com', label: 'French Interpreter'},
    {value: '7002025927@csip.stratusvideo.com', label: 'Haitian Creole Interpreter'},
    {value: '7002025928@csip.stratusvideo.com', label: 'Hindi Interpreter'},
    {value: '7002025929@csip.stratusvideo.com', label: 'Hmong Interpreter'},
    {value: '7002025930@csip.stratusvideo.com', label: 'Japanese Interpreter'},
    {value: '7002025931@csip.stratusvideo.com', label: 'Karen Interpreter'},
    {value: '7002034004@csip.stratusvideo.com', label: 'Kinyarwanda Interpreter'},
    {value: '7002026042@csip.stratusvideo.com', label: 'Korean Interpreter'},
    {value: '7002026043@csip.stratusvideo.com', label: 'Mandarin Interpreter'},
    {value: '7002026044@csip.stratusvideo.com', label: 'Nepali Interpreter'},
    {value: '7002026045@csip.stratusvideo.com', label: 'Pashto Interpreter'},
    {value: '7002026046@csip.stratusvideo.com', label: 'Polish Interpreter'},
    {value: '7002026047@csip.stratusvideo.com', label: 'Portuguese(BRA) Interpreter'},
    {value: '7002026048@csip.stratusvideo.com', label: 'Portuguese(EU) Interpreter'},
    {value: '7002026049@csip.stratusvideo.com', label: 'Punjabi Interpreter'},
    {value: '7002026050@csip.stratusvideo.com', label: 'Russian Interpreter'},
    {value: '7002026051@csip.stratusvideo.com', label: 'Serbian Interpreter'},
    {value: '7002026052@csip.stratusvideo.com', label: 'Somali Interpreter'},
    {value: '7002025915@csip.stratusvideo.com', label: 'Spanish Interpreter'},
    {value: '7002026053@csip.stratusvideo.com', label: 'Swahili Interpreter'},
    {value: '7002026054@csip.stratusvideo.com', label: 'Tagalog Interpreter'},
    {value: '7002026055@csip.stratusvideo.com', label: 'Tigrinya Interpreter'},
    {value: '7002026056@csip.stratusvideo.com', label: 'Ukrainian Interpreter'},
    {value: '7002026057@csip.stratusvideo.com', label: 'Urdu Interpreter'},
    {value: '7002026058@csip.stratusvideo.com', label: 'Vietnamese Interpreter'},
];

const testInterpreters: OptionProps[] = [
    {value: '3333@sip2sip.info', label: 'Amharic Interpreter'},
    {value: '3333@sip2sip.info', label: 'Arabic Interpreter'},
    {value: '3333@sip2sip.info', label: 'Armenian Interpreter'},
    {value: '3333@sip2sip.info', label: 'ASL Interpreter'},
    {value: '3333@sip2sip.info', label: 'Audio Only Languages'},
    {value: '3333@sip2sip.info', label: 'Bengali Interpreter'},
    {value: '3333@sip2sip.info', label: 'Bosnian Interpreter'},
    {value: '3333@sip2sip.info', label: 'Burmese Interpreter'},
    {value: '3333@sip2sip.info', label: 'Cambodian Interpreter'},
    {value: '3333@sip2sip.info', label: 'Cantonese Interpreter'},
    {value: '3333@sip2sip.info', label: 'Cape Verdean Creole Interpreter'},
    {value: '3333@sip2sip.info', label: 'CDI Interpreter'},
    {value: '3333@sip2sip.info', label: 'Croatian Interpreter'},
    {value: '3333@sip2sip.info', label: 'Dari Interpreter'},
    {value: '3333@sip2sip.info', label: 'Farsi Interpreter'},
    {value: '3333@sip2sip.info', label: 'French Interpreter'},
    {value: '3333@sip2sip.info', label: 'Haitian Creole Interpreter'},
    {value: '3333@sip2sip.info', label: 'Hindi Interpreter'},
    {value: '3333@sip2sip.info', label: 'Hmong Interpreter'},
    {value: '3333@sip2sip.info', label: 'Japanese Interpreter'},
    {value: '3333@sip2sip.info', label: 'Karen Interpreter'},
    {value: '3333@sip2sip.info', label: 'Kinyarwanda Interpreter'},
    {value: '3333@sip2sip.info', label: 'Korean Interpreter'},
    {value: '3333@sip2sip.info', label: 'Mandarin Interpreter'},
    {value: '3333@sip2sip.info', label: 'Nepali Interpreter'},
    {value: '3333@sip2sip.info', label: 'Pashto Interpreter'},
    {value: '3333@sip2sip.info', label: 'Polish Interpreter'},
    {value: '3333@sip2sip.info', label: 'Portuguese(BRA) Interpreter'},
    {value: '3333@sip2sip.info', label: 'Portuguese(EU) Interpreter'},
    {value: '3333@sip2sip.info', label: 'Punjabi Interpreter'},
    {value: '3333@sip2sip.info', label: 'Russian Interpreter'},
    {value: '3333@sip2sip.info', label: 'Serbian Interpreter'},
    {value: '3333@sip2sip.info', label: 'Somali Interpreter'},
    {value: '3333@sip2sip.info', label: 'Spanish Interpreter'},
    {value: '3333@sip2sip.info', label: 'Swahili Interpreter'},
    {value: '3333@sip2sip.info', label: 'Tagalog Interpreter'},
    {value: '3333@sip2sip.info', label: 'Tigrinya Interpreter'},
    {value: '3333@sip2sip.info', label: 'Ukrainian Interpreter'},
    {value: '3333@sip2sip.info', label: 'Urdu Interpreter'},
    {value: '3333@sip2sip.info', label: 'Vietnamese Interpreter'},
];

export {interpreters, testInterpreters};
