import moment from 'moment';
import {array, mixed, number, object, SchemaOf, string, TestContext} from 'yup';

import VALIDATION_MESSAGES from '@/constants/ValidationMessages';
import {ShiftRole} from '@/types/gatewayDataModels';
import {getDateTimeFormats} from '@/utils/timeFormatter';

import {AddShiftFormTypes} from './formTypes';

const {NEW_DATE_TIME_FORMAT} = getDateTimeFormats();

const requiredString = string().trim().required(VALIDATION_MESSAGES.required);

const validateDates = (_: string, context: TestContext) => {
    const {shiftStart, shiftEnd} = context.parent as AddShiftFormTypes;

    if (!shiftStart || !shiftEnd) {
        return true;
    }

    return moment(shiftStart, NEW_DATE_TIME_FORMAT).isSameOrBefore(moment(shiftEnd, NEW_DATE_TIME_FORMAT));
};

const rules = {
    personnelId: number().required(VALIDATION_MESSAGES.required),
    clusterIds: array().min(1).required(),
    role: mixed<ShiftRole>().oneOf(Object.values(ShiftRole)).required(VALIDATION_MESSAGES.required),
    shiftStart: requiredString.test('start', VALIDATION_MESSAGES.shiftStartDate, validateDates),
    shiftEnd: requiredString.test('end', VALIDATION_MESSAGES.shiftEndDate, validateDates),
};

const validationSchema: SchemaOf<AddShiftFormTypes> = object().shape(rules);

export {validationSchema};
