import {ShiftRole} from '@/types/gatewayDataModels';

const shiftRoles = [
    {label: 'Charge Nurse', value: ShiftRole.CHARGE_NURSE},
    {label: 'Acquisition Nurse', value: ShiftRole.ACQUISITION_NURSE},
    {label: 'Senior Service Coordinator', value: ShiftRole.SENIOR_SERVICE_COORDINATOR},
];

const roleToShiftRolesMapping: Record<string, ShiftRole[]> = {
    Nurse: [ShiftRole.CHARGE_NURSE, ShiftRole.ACQUISITION_NURSE],
    'Service Coordinator': [ShiftRole.SENIOR_SERVICE_COORDINATOR],
};

const shiftRoleToRoleMapping = {
    [ShiftRole.CHARGE_NURSE]: 'Nurse',
    [ShiftRole.ACQUISITION_NURSE]: 'Nurse',
    [ShiftRole.SENIOR_SERVICE_COORDINATOR]: 'Service Coordinator',
};

export {roleToShiftRolesMapping, shiftRoles, shiftRoleToRoleMapping};
