import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ACTION_QUEUE_SERVICE_REQUESTS} from '@/constants/constants';
import {SimplePersonnelDTO, VendorTreeDTO} from '@/types/gatewayDataModels';

import {ActionQueueReducerState} from './stateTypes';

const initialState: ActionQueueReducerState = {
    handOffCounters: {},
    serviceRequests: {},
    serviceCoordinators: [],
    vendors: [],
    loadingHandler: {
        getServiceRequestCountersLoading: false,
        getServiceCoordinatorsLoading: true,
        getVendorsLoading: true,
    },
};

const SLICE_NAME = 'actionQueueReducer';
const CancellationsTabSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        getServiceRequestCounters: (state, _action: PayloadAction) => {
            state.loadingHandler.getServiceRequestCountersLoading = true;
        },
        getServiceRequestCountersSuccess: (
            state,
            action: PayloadAction<{
                serviceRequestsCounters: {[key: string]: number};
                handOffCounters: {[key: string]: number};
            }>,
        ) => {
            const {handOffCounters, serviceRequestsCounters} = action.payload;
            const handOffCountNumber = Object.values(handOffCounters).reduce((acc, num) => acc + num, 0);
            state.handOffCounters = handOffCounters;
            state.serviceRequests = {
                ...serviceRequestsCounters,
                [ACTION_QUEUE_SERVICE_REQUESTS.HANDOFF]: handOffCountNumber,
            };
            state.loadingHandler.getServiceRequestCountersLoading = false;
        },
        getServiceRequestCountersFail: (state) => {
            state.loadingHandler.getServiceRequestCountersLoading = false;
        },
        getServiceCoordinators: (state) => {
            state.loadingHandler.getServiceCoordinatorsLoading = true;
        },
        getServiceCoordinatorsSuccess: (state, action: PayloadAction<SimplePersonnelDTO[]>) => {
            state.serviceCoordinators = action.payload;
            state.loadingHandler.getServiceCoordinatorsLoading = false;
        },
        getServiceCoordinatorsFail: (state) => {
            state.loadingHandler.getServiceCoordinatorsLoading = false;
        },
        getVendors: (state) => {
            state.loadingHandler.getVendorsLoading = true;
        },
        getVendorsSuccess: (state, action: PayloadAction<VendorTreeDTO[]>) => {
            state.vendors = action.payload;
            state.loadingHandler.getVendorsLoading = false;
        },
        getVendorsFail: (state) => {
            state.loadingHandler.getVendorsLoading = false;
        },
        setServiceRequestCount: (state, action: PayloadAction<{[key: string]: number}>) => {
            Object.assign(state, {
                serviceRequests: {
                    ...state.serviceRequests,
                    ...action.payload,
                },
            });
        },
        getInitialData: (state) => state,
        clearReducer: () => initialState,
    },
});
const {reducer, actions} = CancellationsTabSlice;

const {
    getServiceRequestCounters,
    getServiceRequestCountersSuccess,
    getServiceRequestCountersFail,
    getServiceCoordinators,
    getServiceCoordinatorsSuccess,
    getServiceCoordinatorsFail,
    getVendors,
    getVendorsSuccess,
    getVendorsFail,
    setServiceRequestCount,
    clearReducer,
    getInitialData,
} = actions;

export {
    clearReducer,
    getInitialData,
    getServiceCoordinators,
    getServiceCoordinatorsFail,
    getServiceCoordinatorsSuccess,
    getServiceRequestCounters,
    getServiceRequestCountersFail,
    getServiceRequestCountersSuccess,
    getVendors,
    getVendorsFail,
    getVendorsSuccess,
    reducer,
    setServiceRequestCount,
    SLICE_NAME,
};
