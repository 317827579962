import {useSelector} from 'react-redux';
import {Grid} from '@mui/material';

import {MHAvatar, MHDialog, MHRegularButton} from '@/components/base';

import {employmentSelector, userSelector} from '../../redux/selectors';

import styles from './userProfile.module.scss';

type UserProfileProps = {
    onClose: () => void;
    open: boolean;
};

export const UserProfile = ({onClose, open}: UserProfileProps) => {
    const auth0User = useSelector(userSelector);
    const user = useSelector(employmentSelector);
    return (
        <MHDialog
            onClose={onClose}
            title="Profile Info"
            isCloseIcon
            open={open}
            contentStyle={{root: styles.profileInfoModal}}
            headTitleStyles={styles.headTitle}
        >
            <div className={styles.userInfoBlock}>
                <div className={styles.userAvatarBlock}>
                    <MHAvatar
                        variant="square"
                        alt={`${user?.firstName}/${user?.lastName}`}
                        customStyles={{root: styles.userInfoAvatar}}
                    />
                </div>
                <div className={styles.userDetails}>
                    <div>
                        {user?.firstName} {user?.lastName}
                    </div>
                    <div>Nickname:</div>
                    <div>{auth0User?.nickname || ''}</div>
                    <div>Email:</div>
                    <div>{user?.email || ''}</div>
                    <div>Assigned roles:</div>
                    <div>{auth0User?.roles.join(', ')}</div>
                </div>
            </div>
            <Grid container justifyContent="flex-end" alignItems="center" gap="16px" marginTop="24px">
                <MHRegularButton text="Close" btnType="primary-positive" onClick={onClose} margin="24px 0 0 auto" />
            </Grid>
        </MHDialog>
    );
};
