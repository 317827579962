import {StoreStateShape} from '@/redux/store/storeStateTypes';

const activeMeetingsCountSelector = ({activeMeetings: {activeMeetingsCount}}: StoreStateShape) => activeMeetingsCount;

const activeMeetingsSelector = ({
    activeMeetings: {
        loadingHandler: {getActiveMeetingsLoading},
        data,
    },
}: StoreStateShape) => ({
    getActiveMeetingsLoading,
    data,
});

const userIdSelector = ({
    commonUserDataReducer: {
        employment: {id},
    },
}: StoreStateShape) => id;

const callsSelector = ({
    commonUserDataReducer: {
        phoneCallData: {awsConnectAgentStatus},
        user: {email},
    },
}: StoreStateShape) => ({
    awsConnectAgentStatus: awsConnectAgentStatus,
    email,
});

const isVideoCallsEnabledSelector = ({
    commonUserDataReducer: {
        features: {CapabilityVideoCalls},
    },
}: StoreStateShape) => ({
    isVideoCallsEnabled: CapabilityVideoCalls,
});

export {
    activeMeetingsCountSelector,
    activeMeetingsSelector,
    callsSelector,
    isVideoCallsEnabledSelector,
    userIdSelector,
};
