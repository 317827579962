/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {VideoFilterDevices, VideoFilterOptions} from '@/types/video';

import {tensorFlowBodyPixInit} from './tensorFlowBodyPix';

declare global {
    interface Window {
        vidyoConnector: any;
    }
}

const mapVirtualBgProcessors = {
    banuba: {
        setBlue: async () => {
            console.info('[VideoWidget] Trying to enable blur effect');
            await window.banuba.applyEffect('blur');
            return window.vidyoConnector.RegisterLocalCameraStreamInterceptor(window.banuba.effectBackground);
        },
        setBgImage: async (customBg: string) => {
            console.info('[VideoWidget] Trying to enable background image effect');
            await window.banuba.applyEffect('custom_bg', customBg);
            return window.vidyoConnector.RegisterLocalCameraStreamInterceptor(window.banuba.effectBackground);
        },
    },
    tensorflow: {
        setBlue: async () => {
            console.info('[VideoWidget] Trying to enable blur effect');
            const steamWrapper = await tensorFlowBodyPixInit('blur');
            setTimeout(() => {
                window.vidyoConnector.RegisterLocalCameraStreamInterceptor(steamWrapper);
            }, 0);
        },
        setBgImage: async (customBg: string) => {
            console.info('[VideoWidget] Trying to enable background image effect');
            const steamWrapper = await tensorFlowBodyPixInit('bg_image', customBg);
            setTimeout(() => {
                window.vidyoConnector.RegisterLocalCameraStreamInterceptor(steamWrapper);
            }, 0);
        },
    },
};

async function registerFilter(type: VideoFilterOptions, customBg?: string) {
    if (type === 'bg_noise_cancellation') {
        console.info('[VideoWidget] Trying to enable background noise cancellation');
        const rnnoisePlugin = await import('../../../../../../public/rnnoise/index.js');
        console.info(rnnoisePlugin);
        window.vidyoConnector.RegisterLocalMicrophoneStreamInterceptor(rnnoisePlugin.start);
        return;
    }

    const isVirtualBgType = type === 'blur' || type === 'bg_image';
    const isBanubaEnabled = window['VIDYO_IS_BANUBA_ENABLED'] === true;
    const curVirtualBgProcessor = isBanubaEnabled ? 'banuba' : 'tensorflow';

    if (isVirtualBgType && isBanubaEnabled && !window.banubaPluginReady) {
        return console.error('[VideoWidget] Banuba not ready');
    }

    if (type === 'blur') {
        return await mapVirtualBgProcessors[curVirtualBgProcessor].setBlue();
    }

    if (type === 'bg_image') {
        return await mapVirtualBgProcessors[curVirtualBgProcessor].setBgImage(customBg);
    }
}

async function unregisterFilter(type: VideoFilterDevices = 'camera') {
    if (type === 'camera') {
        return await window.vidyoConnector.UnregisterLocalCameraStreamInterceptor();
    }
    if (type === 'audio') {
        const rnnoisePlugin = await import('../../../../../../public/rnnoise/index.js');
        await rnnoisePlugin.stop();
        console.info('[VideoWidget] Rnnoise stopped...');
        return await window.vidyoConnector.UnregisterLocalMicrophoneStreamInterceptor();
    }
}

export {registerFilter, unregisterFilter};
