import {StoreStateShape} from '@/redux/store/storeStateTypes';

const callTransferToAgentQueueSelector = (state: StoreStateShape) => {
    const {destinationAgentEmail} = state.availableCallTransferAgentsReducer;
    const {
        phoneCallData: {initialContactId, sourceAgentEmail},
        user: {email},
    } = state.commonUserDataReducer;

    return {
        destinationAgentEmail,
        initialContactId,
        sourceAgentEmail,
        email,
    };
};

const transferAgentsListSelector = (state: StoreStateShape) => {
    const {users, loadingHandler} = state.availableCallTransferAgentsReducer;
    const user = state.commonUserDataReducer;

    return {user, users, loadingHandler};
};

const transferQueueListSelector = (state: StoreStateShape) => {
    const {
        phoneCallData: {queueARN},
    } = state.commonUserDataReducer;
    return {queueARN};
};

export {callTransferToAgentQueueSelector, transferAgentsListSelector, transferQueueListSelector};
