import {CompatClient, messageCallbackType, Stomp, StompHeaders, StompSubscription} from '@stomp/stompjs';
import SockJS from 'sockjs-client';

import {jwtToken} from '@/services/auth0';

class WebsocketService {
    private subscriptions: Record<string, StompSubscription> = {};
    private stompClient: CompatClient = null;
    private socket: WebSocket = null;
    private initializationCount = 0;

    public initWebSocket(successCallback?: () => void, errorCallback?: () => void) {
        this.initializationCount = this.initializationCount + 1;
        this.socket = new SockJS(`${window.REACT_APP_CORE_API_URL}/notifications/v1/notifications`);
        this.stompClient = Stomp.over(this.socket);

        this.stompClient.configure({
            debug: () => null,
            onStompError: () => {
                errorCallback && errorCallback();
            },
            onWebSocketError: () => {
                errorCallback && errorCallback();
            },
            onWebSocketClose: () => {
                if (this.initializationCount < 2) {
                    setTimeout(() => {
                        this.initWebSocket(successCallback, errorCallback);
                    }, 3000);
                } else {
                    errorCallback && errorCallback();
                }
            },
        });
        this.stompClient.connect({Authorization: 'Bearer ' + jwtToken}, successCallback, () => {
            setTimeout(() => {
                errorCallback && errorCallback();
                this.initWebSocket(successCallback, errorCallback);
            }, 3000);
        });
    }

    public subscribe(url: string, callback?: messageCallbackType) {
        if (this.stompClient?.connected) this.subscriptions[url] = this.stompClient.subscribe(url, callback);
    }

    public unsubscribe(url: string, callback?: (headers?: StompHeaders) => void) {
        if (this.stompClient?.connected) {
            this.subscriptions?.[url]?.unsubscribe();
            delete this.subscriptions?.[url];
            callback && callback();
        }
    }

    public sendMessage(url: string, data: string) {
        if (this.stompClient?.connected) this.stompClient.send(url, {}, data);
    }

    public disconnect(callback?: () => void) {
        if (this.stompClient?.connected) {
            this.stompClient.disconnect(() => {
                callback && callback();
            });
        }
    }
}

const websocketService = new WebsocketService();

export default websocketService;
