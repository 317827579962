import {createSlice} from '@reduxjs/toolkit';

const SLICE_NAME = 'MHHeader';
const InHomeAppointmentReviewSelfCheckInSlice = createSlice({
    name: SLICE_NAME,
    initialState: {},
    reducers: {
        getInitialData: (state) => state,
    },
});

const {reducer, actions} = InHomeAppointmentReviewSelfCheckInSlice;

const {getInitialData} = actions;

export {getInitialData, reducer, SLICE_NAME};
