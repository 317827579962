import {deviceListenerCb} from './utils/deviceListenerCb';

declare global {
    interface Window {
        vidyoConnector: any;
        microphone: any;
    }
}

type Props = {
    onAdded?: (localMicrophone: {id: string; name: string}) => void;
    onRemoved?: (localMicrophoneId: string) => void;
    setCurMic?: (mic: any) => void;
};

export const registerMicrophone = async (props?: Props) => {
    const {onAdded, onRemoved, setCurMic} = props || {};
    window['microphone'] = [];

    try {
        await window.vidyoConnector.RegisterLocalMicrophoneEventListener(
            deviceListenerCb({onAdded, onRemoved, setCurDevice: setCurMic, deviceName: 'microphone'}),
        );
        console.info('LocalMicrophoneEventListener registered');
    } catch (error) {
        console.error('RegisterLocalMicrophoneEventListener failed', error);
    }
};
