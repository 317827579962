import palette from '../palette';

export default {
    stickyHeader: {
        backgroundColor: palette.lightBlue.light,
    },
    root: {
        fontSize: '14px',
    },
};
