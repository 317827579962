import {datadogLogs} from '@datadog/browser-logs';

const negativeKeywords: string[] = [
    'Load',
    'load',
    'AMZ_WEB_SOCKET_MANAGER',
    'polling',
    'Running',
    'Saved',
    'notification',
    'test',
    'dispose',
    'bg_image',
    'Preparing...',
    'been cleared',
];

export const initializeDatadogLogs = () => {
    datadogLogs.init({
        clientToken: window.DATADOG_CLIENT_TOKEN,
        env: window.DATADOG_ENV,
        site: 'datadoghq.com',
        forwardErrorsToLogs: false,
        forwardConsoleLogs: ['info', 'log', 'warn', 'error'],
        sessionSampleRate: 100,
        version: APP_VERSION,
        service: 'mh-ui',
        beforeSend: (log) => {
            if (['warn', 'error'].some((level) => level === log.status)) return true;

            const message = log.message || '';
            const containsNegativeKeyword = negativeKeywords.some((keyword) => message.includes(keyword));
            return !containsNegativeKeyword;
        },
    });
};
