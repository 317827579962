import {MHErrorBoundary, MHTypography} from '../../base';
import {AvailableCallTransferAgentsProps} from './redux/stateTypes';
import {TransferAgentsListTable} from './TransferAgentsListTable';
import {TransferQueuesListTable} from './TransferQueuesListTable';

import styles from './availableCallTransferAgents.module.scss';

export const AvailableCallTransferAgents = ({handlePopoverClose}: AvailableCallTransferAgentsProps) => {
    return (
        <MHErrorBoundary>
            <div className={styles.wrapper}>
                <MHTypography
                    width="100%"
                    padding="24px 0"
                    variant="headline4"
                    color="black_5"
                    bgcolor="teal_100"
                    align="center"
                >
                    Transfer Call
                </MHTypography>
                <TransferAgentsListTable {...{handlePopoverClose}} />
                <TransferQueuesListTable {...{handlePopoverClose}} />
            </div>
        </MHErrorBoundary>
    );
};
