import {Tab, TabClassKey, TabProps} from '@mui/material';
import classnames from 'classnames';

import styles from './styles.module.scss';

type MHTabProps = TabProps & {
    customClasses?: {[key in TabClassKey]?: string};
};

const MHTab = ({customClasses = {}, ...props}: MHTabProps) => {
    const {root: customClassesRoot, ...otherCustomClasses} = customClasses;

    return <Tab classes={{root: classnames(styles.tabStyle, customClassesRoot), ...otherCustomClasses}} {...props} />;
};

export default MHTab;
