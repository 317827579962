import {AppStartListening} from '@/redux/store/listenerMiddleware';
import appointmentsService from '@/services/appointmentsService';

import {getPotentialIssue, getPotentialIssueFail, getPotentialIssueStart, getPotentialIssueSuccess} from './slice';

const addFeatureListeners = (startListening: AppStartListening) => {
    startListening({
        actionCreator: getPotentialIssue,
        effect: async (action, listenerApi) => {
            try {
                listenerApi.dispatch(getPotentialIssueStart());
                const data = await appointmentsService.getAppointmentPotentialIssue(action.payload);
                listenerApi.dispatch(
                    getPotentialIssueSuccess({
                        appointmentId: action.payload,
                        potentialIssuesMessages: data,
                    })
                );
            } catch (err) {
                listenerApi.dispatch(getPotentialIssueFail());
            }
        },
    });
};

export default addFeatureListeners;
