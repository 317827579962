import {APPOINTMENT_VISIT_TYPES} from '@/constants/commonDataNames';
import {AppStartListening} from '@/redux/store/listenerMiddleware';
import spiService from '@/services/spiService';
import {generalNotify} from '@/utils/notifyMessages';

import {formatDataToApi} from '../GMRForm/shared/utils';
import {
    getAppointmentRequestDetails,
    getAppointmentRequestDetailsFail,
    getAppointmentRequestDetailsSuccess,
    getGMRHistory,
    getGMRHistoryFail,
    getGMRHistorySuccess,
    getInitialData,
    saveGMRData,
    saveGMRDataFail,
    saveGMRDataSuccess,
} from './slice';
import {getCommonData} from './softDependencies';

const addFeatureListeners = (startListening: AppStartListening) => {
    startListening({
        actionCreator: getInitialData,
        effect: (_action, listenerApi) => {
            listenerApi.dispatch(getCommonData([APPOINTMENT_VISIT_TYPES]));
        },
    });

    startListening({
        actionCreator: saveGMRData,
        effect: async (action, listenerApi) => {
            try {
                const {onSuccess, ...payload} = action.payload;
                const formattedPayload = formatDataToApi(payload);
                await spiService.sendAppointmentRequest(formattedPayload);
                onSuccess();
                generalNotify({
                    title: 'Success',
                    message: 'Appointment request sent',
                    status: 'success',
                });
                listenerApi.dispatch(saveGMRDataSuccess());
            } catch (err) {
                listenerApi.dispatch(saveGMRDataFail());
            }
        },
    });

    startListening({
        actionCreator: getGMRHistory,
        effect: async (action, listenerApi) => {
            try {
                const gmrHistoryData = await spiService.getGMRHistoryData(action.payload);
                listenerApi.dispatch(getGMRHistorySuccess(gmrHistoryData));
            } catch (err) {
                listenerApi.dispatch(getGMRHistoryFail());
            }
        },
    });

    startListening({
        actionCreator: getAppointmentRequestDetails,
        effect: async (action, listenerApi) => {
            try {
                const appointmentRequestDetails = await spiService.getAppointmentRequestDetails(action.payload);
                listenerApi.dispatch(getAppointmentRequestDetailsSuccess(appointmentRequestDetails));
            } catch (err) {
                listenerApi.dispatch(getAppointmentRequestDetailsFail());
            }
        },
    });
};

export default addFeatureListeners;
