import {useForm} from 'react-hook-form';
import {Grid} from '@mui/material';

import {MHDialog, MHRegularButton, MHTextField} from '@/components/base';
import {resolver} from '@/utils/resolver';

import {EndShiftFormTypes} from './formTypes';
import {validationSchema} from './validationSchema';

import styles from './endShiftModal.module.scss';

type EndShiftModalProps = {
    onClose: () => void;
    onConfirm: (values: EndShiftFormTypes) => void;
    isLoading: boolean;
};

export const EndShiftModal = ({onClose, onConfirm, isLoading}: EndShiftModalProps) => {
    const {handleSubmit, formState, register} = useForm<EndShiftFormTypes>({
        resolver: resolver(validationSchema),
        mode: 'all',
    });

    return (
        <MHDialog
            onClose={onClose}
            dialogStyle={{paper: styles.dialog}}
            contentStyle={{root: styles.dialogContent}}
            open
            isCloseIcon
            title="End shift now"
        >
            <form onSubmit={handleSubmit(onConfirm)}>
                <MHTextField
                    {...register('shiftEndReason')}
                    errorMessage={formState.errors?.shiftEndReason?.message}
                    showRequiredAsterisk
                    multiline
                    rows="4"
                    label="Add Comment"
                    placeholder="Comment"
                />
                <Grid container justifyContent="flex-end" alignItems="center" gap="16px" marginTop="24px">
                    <MHRegularButton
                        disabled={!formState.isValid}
                        type="submit"
                        text="End shift"
                        loading={isLoading}
                        btnType="primary-positive"
                    />
                    <MHRegularButton text="Cancel" btnType="secondary-positive" onClick={onClose} />
                </Grid>
            </form>
        </MHDialog>
    );
};
