import {MouseEventHandler, useState} from 'react';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import {IconButton, Menu, MenuItem, MenuProps} from '@mui/material';
import classnames from 'classnames';

import {MHTypography} from '@/components/base';
import {PatientResponseDTO} from '@/types/gatewayDataModels';
import {getParamFromLocalStorage} from '@/utils/localStoreUtils';

import {PatientListItem} from './PatientListItem';

import styles from '../shared/common.module.scss';

const StyledMenu = (props: MenuProps) => (
    <Menu
        classes={{paper: styles.menuPaper, list: styles.menuList}}
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
        {...props}
    />
);

export const LastViewedPatients = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const patients: PatientResponseDTO[] = getParamFromLocalStorage('lastPatients', []);

    return (
        <div>
            <IconButton
                classes={{root: styles.iconButton}}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="large"
            >
                <PeopleOutlinedIcon className={styles.icon} />
                <div className={classnames(styles.triangle, open && styles.triangleActive)} />
            </IconButton>
            <StyledMenu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                <MenuItem classes={{root: styles.menuItem}} key="Last-15-Charts" onClick={handleClose}>
                    <div className={styles.listTitle}>
                        <MHTypography color="white" variant="headline5">
                            Recent Charts
                        </MHTypography>
                    </div>
                </MenuItem>
                <div className={styles.scrollableDiv}>
                    {patients.length ? (
                        patients.map((patient) => (
                            <MenuItem classes={{root: styles.menuItem}} key={patient.id} onClick={handleClose}>
                                <PatientListItem patient={patient} />
                            </MenuItem>
                        ))
                    ) : (
                        <div className={styles.noDataMessage}>No Recent Charts</div>
                    )}
                </div>
            </StyledMenu>
        </div>
    );
};
