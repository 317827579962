import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ReferralFieldsState} from './stateTypes';

const initialState: ReferralFieldsState = {
    loadingHandler: {changeAppointmentReferralDateTimeLoading: false},
};

const {actions, reducer} = createSlice({
    name: 'referralFields',
    initialState,
    reducers: {
        changeAppointmentReferralDateTime: (
            state,
            _action: PayloadAction<{
                id: number;
                sentValue?: string | null;
                acceptedValue?: string | null;
            }>
        ) => {
            state.loadingHandler.changeAppointmentReferralDateTimeLoading = true;
        },
        changeAppointmentReferralDateTimeSuccess: (state) => {
            state.loadingHandler.changeAppointmentReferralDateTimeLoading = false;
        },
        changeAppointmentReferralDateTimeFail: (state) => {
            state.loadingHandler.changeAppointmentReferralDateTimeLoading = false;
        },
    },
});

export const {
    changeAppointmentReferralDateTime,
    changeAppointmentReferralDateTimeSuccess,
    changeAppointmentReferralDateTimeFail,
} = actions;

export default reducer;
