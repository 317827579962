export default {
    root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        textTransform: 'none',
        marginBottom: 30,
    },
    indicator: {
        backgroundColor: 'rgba(54,72,125, 1)',
    },
};
