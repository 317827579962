import {BaseIconProps} from '@/assets/icons/types';

type SirenIconProps = BaseIconProps;

export const SirenIcon = ({width = '48', height = '48', viewBox = '0 0 48 48', fill = '#EC655E'}: SirenIconProps) => (
    <svg width={width} height={height} viewBox={viewBox} version="1.1" data-testid="SirenIcon">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
                d="M36,42 C38.209139,42 40,43.790861 40,46 L40,48 L8,48 L8,46 C8,43.8578046 9.68396847,42.1089211 11.8003597,42.0048953 L12,42 L36,42 Z M24,12 C30.627417,12 36,17.372583 36,24 L36,40 L12,40 L12,24 C12,17.372583 17.372583,12 24,12 Z M25,32 L23,32 L23,34 L25,34 L25,32 Z M25,20 L23,20 L23,30 L25,30 L25,20 Z M48,23 L48,25 L42,25 L42,23 L48,23 Z M6,23 L6,25 L0,25 L0,23 L6,23 Z M7.73654403,6.32233047 L11.6776695,10.263456 L10.263456,11.6776695 L6.32233047,7.73654403 L7.73654403,6.32233047 Z M40.263456,6.32233047 L41.6776695,7.73654403 L37.736544,11.6776695 L36.3223305,10.263456 L40.263456,6.32233047 Z M25,1.13870535e-13 L25,6 L23,6 L23,1.13503141e-13 L25,1.13870535e-13 Z"
                id="siren-icon"
                fill={fill}
            />
        </g>
    </svg>
);
