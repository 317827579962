import {DATE_TIME_API_FORMAT} from '@/constants/timeFormats';
import {AppStartListening} from '@/redux/store/listenerMiddleware';
import spiService from '@/services/spiService';
import {formatDateTime, getDateTimeFormats} from '@/utils/timeFormatter';

import {
    changeAppointmentReferralDateTime,
    changeAppointmentReferralDateTimeFail,
    changeAppointmentReferralDateTimeSuccess,
} from './slice';

const {NEW_DATE_TIME_FORMAT} = getDateTimeFormats();

export default (startListening: AppStartListening) => {
    startListening({
        actionCreator: changeAppointmentReferralDateTime,
        effect: async (action, listenerApi) => {
            try {
                const {id, sentValue, acceptedValue} = action.payload;
                const transformValue = (date?: string | null) =>
                    date ? formatDateTime(date, DATE_TIME_API_FORMAT, NEW_DATE_TIME_FORMAT) : null;

                const referrals = {
                    sent: transformValue(sentValue),
                    accepted: transformValue(acceptedValue),
                };

                await spiService.changeAppointmentReferralDateTime({id, referrals});
                listenerApi.dispatch(changeAppointmentReferralDateTimeSuccess());
            } catch (err) {
                listenerApi.dispatch(changeAppointmentReferralDateTimeFail());
            }
        },
    });
};
