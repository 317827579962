import {StoreStateShape} from '@/redux/store/storeStateTypes';

const changeMissionControlSelector = ({
    changeMissionControlReducer: {selectedMissionControlId, confirmModalData},
    commonDataReducer: {missionControls},
    commonUserDataReducer: {
        missionControlIds,
        user: {email},
    },
}: StoreStateShape) => ({
    missionControls,
    missionControlIds,
    email,
    selectedMissionControlId,
    confirmModalData,
});

const changeMissionControlSagaSelector = ({
    commonDataReducer: {missionControls},
    commonUserDataReducer: {
        user: {email},
    },
}: StoreStateShape) => ({
    missionControls,
    email,
});

export {changeMissionControlSagaSelector, changeMissionControlSelector};
