import {StoreStateShape} from '@/redux/store/storeStateTypes';

const navigationSelector = ({
    commonUserDataReducer: {
        routes,
        user,
        employment,
        loadingHandler: {loadInitialUserDataLoading},
    },
}: StoreStateShape) => ({
    routes,
    email: user?.email,
    nurseId: employment?.id,
    loading: loadInitialUserDataLoading,
});

const isVideoCallsEnabledSelector = ({
    commonUserDataReducer: {
        features: {CapabilityVideoCalls},
    },
}: StoreStateShape) => ({
    isVideoCallsEnabled: CapabilityVideoCalls,
});

export {isVideoCallsEnabledSelector, navigationSelector};
