import {MenuItem} from '@mui/material';
import classnames from 'classnames';

import {ShiftDTO} from '@/types/gatewayDataModels';

import {ShiftItem} from './ShiftItem';

import styles from '../../../shared/common.module.scss';
import shiftListStyles from './shiftList.module.scss';

type ShiftListProps = {
    shifts: ShiftDTO[];
    rowExpandedStateById: Record<string, boolean>;
    toggleRowExpand: (shiftId: number) => void;
    openEndShiftModal: (id: number) => void;
    canModifyShifts: boolean;
};

export const ShiftList = ({
    shifts,
    rowExpandedStateById,
    toggleRowExpand,
    openEndShiftModal,
    canModifyShifts,
}: ShiftListProps) => {
    if (!shifts?.length) {
        return <div className={styles.noDataMessage}>No Shifts</div>;
    }

    return (
        <>
            {shifts.map((shift) => (
                <MenuItem
                    classes={{root: classnames(styles.menuItem, shiftListStyles.menuItem)}}
                    key={shift.id}
                    onClick={() => toggleRowExpand(shift.id)}
                >
                    <ShiftItem
                        isExpanded={rowExpandedStateById[shift.id]}
                        shift={shift}
                        openEndShiftModal={openEndShiftModal}
                        canModifyShifts={canModifyShifts}
                    />
                </MenuItem>
            ))}
        </>
    );
};
