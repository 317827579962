import {createSlice} from '@reduxjs/toolkit';

const SLICE_NAME = 'selectStatesReducer';

const SelectStatesSlice = createSlice({
    name: SLICE_NAME,
    initialState: {},
    reducers: {
        getAllStates: (state) => state,
    },
});
const {reducer, actions} = SelectStatesSlice;

export const {getAllStates} = actions;

export default reducer;
