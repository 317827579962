import {TabletIcon} from '@/assets/icons';
import {MHTypography} from '@/components/base';
import {getMetricIcons} from '@/pages/Patient/PatientPage/Metrics/shared/utils';
import {IncidentData} from '@/types/observationsModels';
import {formatDateTimeToPatient, getDateTimeFormats} from '@/utils/timeFormatter';

import styles from './incidents.module.scss';

const {DATE_FORMAT, TIME_FORMAT} = getDateTimeFormats();
type IncidentsProps = {
    incidents: IncidentData[];
    patientTimeZone: string;
};
export const Incidents = ({incidents, patientTimeZone}: IncidentsProps) => {
    return (
        <div className={styles.container}>
            {incidents.map(({name, measurementUnit, observationType, startTime, triggeredValue}, index) => (
                <div key={index} className={styles.wrapper}>
                    <div className={styles.info}>
                        {observationType ? (
                            getMetricIcons({width: '20px', height: '20px', fill: '#7f8589'})[observationType]
                        ) : (
                            <TabletIcon width="20" height="20" fill="#7f8589" />
                        )}
                        <MHTypography width="330px" noWrap variant="bodyM" marginLeft="8px">
                            {name}: {triggeredValue} {measurementUnit}
                        </MHTypography>
                    </div>

                    <MHTypography variant="bodyM" color="black_60">
                        {formatDateTimeToPatient(startTime, TIME_FORMAT, null, patientTimeZone)},{' '}
                        {formatDateTimeToPatient(startTime, DATE_FORMAT, null, patientTimeZone)}
                    </MHTypography>
                </div>
            ))}
        </div>
    );
};
