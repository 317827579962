import {ReactNode, RefObject, useEffect, useRef, useState} from 'react';
import {usePreviousDistinct} from 'react-use';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import classnames from 'classnames';
import moment, {Moment} from 'moment';

import MHDatePicker from '@/components/base/MHDatePicker';
import MHTimePicker from '@/components/base/MHTimePicker';
import {formatDateTime, getDateTimeFormats} from '@/utils/timeFormatter';

import styles from './styles.module.scss';

const {DATE_FORMAT, TIME_FORMAT, NEW_DATE_TIME_FORMAT} = getDateTimeFormats();

const returnValue = (value: string, resultFormat: string, inputFormat: string) =>
    value ? formatDateTime(value, resultFormat, inputFormat) : '';

type OnChange<DisabledType extends boolean> = DisabledType extends true
    ? {
          onChangeHandler?: (value: string) => void;
      }
    : {
          onChangeHandler: (value: string) => void;
      };

type MHDateTimeFieldProps<DisabledType extends boolean> = {
    focused?: boolean;
    disabled?: DisabledType;
    disabledTime?: DisabledType;
    outputDateFormat?: string;
    outputTimeFormat?: string;
    inputFormat?: string;
    defaultTimeValue?: string;
    timeFieldClassName?: string;
    label?: ReactNode;
    showRequiredAsterisk?: boolean;
    /**
     * The format is 'M/D/YYYY'.
     */
    value: string;
    errorMessage?: string;
    patientTimeZone?: string;
    /**
     * This is the first date on the calendar that is available for selection.
     * The format is 'M/D/YYYY'.
     */
    minDate?: string;
    dateForceChangeOnBlur?: boolean;
    externalCalendarClassName?: string;
    wrapperClassName?: string;
    isSecondaryStyle?: boolean;
    onBlur?: (date?: string, cb?: (newDate: string) => void) => void;
    dateTimeRef?: RefObject<HTMLInputElement>;
} & OnChange<DisabledType>;

const MHDateTimeField = <DisabledType extends boolean = false>({
    focused = false,
    disabled,
    disabledTime,
    outputDateFormat = DATE_FORMAT,
    outputTimeFormat = TIME_FORMAT,
    inputFormat = NEW_DATE_TIME_FORMAT,
    defaultTimeValue = '0000',
    timeFieldClassName,
    label,
    showRequiredAsterisk,
    value,
    onChangeHandler,
    errorMessage,
    patientTimeZone,
    minDate,
    dateForceChangeOnBlur,
    externalCalendarClassName,
    wrapperClassName,
    isSecondaryStyle = false,
    onBlur,
    dateTimeRef,
}: MHDateTimeFieldProps<DisabledType>) => {
    const ref = useRef(null);
    const timePickerRef = dateTimeRef || ref;
    const [showCalendarOuter, setShowCalendarOuter] = useState(false);
    const [dateValue, setDateValue] = useState(returnValue(value, outputDateFormat, inputFormat));
    const [timeValue, setTimeValue] = useState(returnValue(value, outputTimeFormat, inputFormat));

    const prevValue = usePreviousDistinct(value);

    useEffect(() => {
        if (!value && prevValue !== value) {
            setDateValue('');
            setTimeValue('');
        }
        if (value) {
            setDateValue(returnValue(value, outputDateFormat, inputFormat));
            setTimeValue(returnValue(value, outputTimeFormat, inputFormat));
        }
    }, [inputFormat, outputDateFormat, outputTimeFormat, value, prevValue]);

    const shortcutsMap: {[key: string]: () => {date: Moment; time: string}} = {
        N: () => ({
            date: moment(),
            time: patientTimeZone ? moment().tz(patientTimeZone).format(TIME_FORMAT) : moment().format(TIME_FORMAT),
        }),
        T: () => ({date: moment(), time: ''}),
        'T+1': () => ({date: moment().add(1, 'days'), time: ''}),
        'W+1': () => ({date: moment().add(14, 'days'), time: ''}),
        'N+1': () => ({
            date: moment(),
            time: patientTimeZone
                ? moment().add(1, 'minutes').tz(patientTimeZone).format(TIME_FORMAT)
                : moment().add(1, 'minutes').format(TIME_FORMAT),
        }),
    };

    const dateChangeHandler = (date: string) => {
        if (date && !timeValue) {
            onChangeHandler(`${date} ${defaultTimeValue}`);
            setTimeValue(defaultTimeValue);
        } else {
            onChangeHandler(date ? `${date} ${timeValue}` : '');
        }
        setDateValue(date);
    };

    const timeChangeHandler = (time: string) => {
        setTimeValue(time);
        if (dateValue && time) {
            onChangeHandler(`${dateValue} ${time}`);
        } else if (dateValue && !time) {
            onChangeHandler(`${dateValue} ${defaultTimeValue}`);
        } else {
            onChangeHandler('');
        }
    };

    const iconClickHandler = () => {
        setShowCalendarOuter((prevValue) => !prevValue);
    };

    const parseShortcut = (string: string) =>
        shortcutsMap[string] ? shortcutsMap[string]() : {date: string, time: timeValue};

    const datePickerOnBlur = (string: string, callback?: (formattedDateValue: string) => void) => {
        const {date, time} = parseShortcut(string.toUpperCase());
        const dateIsValid = moment(
            date,
            [
                'M/D/YYYY',
                'YYYY-MM-DD',
                'D/M/YYYY',
                'YYYY-DD-MM',
                'DD MM YYYY',
                'MM DD YYYY',
                'M/D/YY',
                'YY-MM-DD',
                'D/M/YY',
                'YY-DD-MM',
                'DD MM YY',
                'MM DD YY',
            ],
            true,
        ).isValid();

        if (dateIsValid) {
            const formattedDateValue = formatDateTime(date, DATE_FORMAT, inputFormat);
            // clears state before set new value, if value is the same as in date picker
            if (formattedDateValue === dateValue) setDateValue('');

            setDateValue(formattedDateValue);
            callback?.(formattedDateValue);
            setTimeValue(time);
            onChangeHandler(`${formattedDateValue} ${time}`);
            onBlur && onBlur();
            // input focusing works only with setTimeout help
            setTimeout(() => {
                if (shortcutsMap[string] && !time) timePickerRef.current.focus();
            }, 100);
        } else {
            setDateValue('');
            callback?.('');
            setTimeValue('');
            onChangeHandler('');
            onBlur && onBlur();
        }
    };

    return (
        <div className={classnames(styles.dateTimeWrapper, wrapperClassName)}>
            {label && (
                <div
                    className={classnames(styles.label, {
                        [styles.labelDisabled]: disabled,
                        [styles.error]: !!errorMessage,
                    })}
                >
                    {label} {showRequiredAsterisk && '*'}
                </div>
            )}

            <div className={classnames(styles.dateTimeContent, errorMessage && styles.error)}>
                <div
                    className={classnames(styles.calendarBtn, {[styles.calendarBtnDisabled]: disabled})}
                    onClick={!disabled ? iconClickHandler : null}
                >
                    <TodayOutlinedIcon
                        classes={{
                            root: classnames(styles.calendarIcon, {[styles.calendarIconSecondary]: isSecondaryStyle}),
                        }}
                    />
                </div>

                <MHDatePicker
                    focused={focused}
                    disabled={disabled}
                    externalContainerClassName={styles.dateFieldContainer}
                    textFieldClassName={classnames(styles.dateField, {[styles.fieldSecondary]: isSecondaryStyle})}
                    value={dateValue}
                    showCalendarOuter={showCalendarOuter}
                    setShowCalendarOuter={setShowCalendarOuter}
                    onChangeHandler={dateChangeHandler}
                    onBlur={datePickerOnBlur}
                    disableShortcuts
                    minDate={minDate}
                    externalCalendarClassName={classnames(styles.calendarStyles, externalCalendarClassName)}
                    forceChangeOnBlur={dateForceChangeOnBlur}
                />

                <MHTimePicker
                    disabled={disabled || disabledTime}
                    rootClassName={classnames(
                        styles.timeField,
                        {[styles.fieldSecondary]: isSecondaryStyle},
                        timeFieldClassName,
                    )}
                    value={timeValue}
                    outputFormat={outputTimeFormat}
                    onChangeHandler={timeChangeHandler}
                    inputRef={timePickerRef}
                />
            </div>

            {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
        </div>
    );
};

export default MHDateTimeField;
