import {object, SchemaOf, string} from 'yup';

import VALIDATION_MESSAGES from '@/constants/ValidationMessages';

import {EndShiftFormTypes} from './formTypes';

const rules = {
    shiftEndReason: string().trim().required(VALIDATION_MESSAGES.required),
};

const validationSchema: SchemaOf<EndShiftFormTypes> = object().shape(rules);

export {validationSchema};
