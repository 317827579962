import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CallEndOutlinedIcon from '@mui/icons-material/CallEndOutlined';

import {MHButton} from '@/components/base';

import {InviteInterpreter} from './InviteInterpreter';

import styles from './videoCall.module.scss';

type VideoCallToolbarProps = {
    onGoToPatientPage: () => void;
    onEndCall: () => void;
    meetingId: string;
};

export const VideoCallToolbar = ({onEndCall, meetingId, onGoToPatientPage}: VideoCallToolbarProps) => {
    return (
        <div className={`${styles.headerToolbar}`}>
            <MHButton buttonType="primaryThird" onClickHandler={onGoToPatientPage} className={styles.goToPatientButton}>
                <AccountCircleOutlinedIcon />
                <span>Go to patient page</span>
            </MHButton>
            <InviteInterpreter meetingId={meetingId} />
            <MHButton buttonType="primary" onClickHandler={onEndCall} className={styles.endCallButton}>
                <CallEndOutlinedIcon />
                <span>End call</span>
            </MHButton>
        </div>
    );
};
