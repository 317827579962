import {MouseEvent, ReactNode, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import {IconButton, Menu, MenuItem, PopoverProps} from '@mui/material';

import {MHRegularButton, MHTypography} from '@/components/base';
import {getParamFromLocalStorage} from '@/utils/localStoreUtils';
import {generalNotify} from '@/utils/notifyMessages';

import ErrorBoundary from '../../base/MHErrorBoundary';
import {changeMissionControlSelector} from './redux/selectors';
import {
    clearMissionControlsReducerData,
    getMissionControls,
    setConfirmModalData,
    setSelectedMissionControlId,
} from './redux/slice';
import {ConfirmDialog} from './ConfirmDialog';
import {getMissionControlName, updateLocalStorage} from './utils';

import styles from './changeMissionControl.module.scss';

type StyledMenuProps = {
    id: string;
    anchorEl: PopoverProps['anchorEl'];
    keepMounted: boolean;
    open: boolean;
    onClose: () => void;
    children?: ReactNode;
};

const StyledMenu = (props: StyledMenuProps) => (
    <Menu
        classes={{paper: styles.menuPaper, list: styles.menuList}}
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: -50,
        }}
        {...props}
    />
);

export const ChangeMissionControl = () => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const {missionControls, missionControlIds, email, selectedMissionControlId, confirmModalData} = useSelector(
        changeMissionControlSelector,
        shallowEqual
    );

    useEffect(() => {
        const selectedMissionControls = getParamFromLocalStorage('missionControls', {});
        dispatch(getMissionControls(selectedMissionControls));
        return () => {
            dispatch(clearMissionControlsReducerData());
        };
    }, [dispatch, email]);

    const handleClick = (event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleConfirmDialogToggle = (missionControl: {id?: number; name?: string} | null) => {
        dispatch(setConfirmModalData(missionControl || null));
    };

    const setNewMissionControl = () => {
        const {name = '', id = ''} = confirmModalData;
        dispatch(setSelectedMissionControlId(+id));
        updateLocalStorage(email.toLowerCase(), +id);
        localStorage.removeItem('inactivePatientsFilters');
        //TODO Removed redundant code, when working on missions controls we should clear newPatientreducers
        handleConfirmDialogToggle(null);
        handleClose();
        generalNotify({
            title: 'Success',
            message: `You Successfully Switched To The ${getMissionControlName(name)}`,
            status: 'success',
        });
    };

    const availableMissionControls = missionControls.filter(({id}) => missionControlIds.indexOf(id) >= 0);
    const selectedMissionControl = selectedMissionControlId
        ? missionControls.find(({id}) => +id === +selectedMissionControlId)
        : {};
    const selectedMissionControlName = selectedMissionControl ? selectedMissionControl.name : '';

    if (availableMissionControls.length < 2) return null;
    return (
        <ErrorBoundary>
            <IconButton
                classes={{root: styles.iconButton}}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="large"
            >
                <BusinessOutlinedIcon />
                {open && <div className={styles.triangle} />}
            </IconButton>
            <StyledMenu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                <MenuItem classes={{root: styles.menuItem}} key="Last-15-Charts" onClick={handleClose}>
                    <div className={styles.listTitle}>
                        <MHTypography variant="headline4">Switch Mission Control</MHTypography>
                    </div>
                </MenuItem>
                <div className={styles.scrollableDiv}>
                    <div className={styles.currently}>
                        <span className={styles.currentlyLabel}>Currently In the </span>
                        <span className={styles.currentlyIcon}>
                            <BusinessOutlinedIcon />
                        </span>
                        <span className={styles.currentlyText}>
                            {getMissionControlName(selectedMissionControlName)}
                        </span>
                    </div>
                    {availableMissionControls.map(({id, name}) => (
                        <div key={id} className={styles.missionControlRow}>
                            <div className={styles.missionControlLabel}>{getMissionControlName(name)}</div>
                            <MHRegularButton
                                disabled={+selectedMissionControlId === +id}
                                text={getMissionControlName('Switch')}
                                btnType="secondary-positive"
                                onClick={() => handleConfirmDialogToggle({id, name})}
                            />
                        </div>
                    ))}
                </div>
                {!!confirmModalData && (
                    <ConfirmDialog
                        setNewMissionControl={setNewMissionControl}
                        handleConfirmDialogToggle={handleConfirmDialogToggle}
                        confirmModalData={confirmModalData}
                    />
                )}
            </StyledMenu>
        </ErrorBoundary>
    );
};

export default ChangeMissionControl;
