import {useEffect} from 'react';
import {Route} from 'react-router-dom';
import PropTypes from 'prop-types';

import {useAuth0} from '@/services/auth0';
import {clearLocalStorage} from '@/utils/localStoreUtils';

const PrivateRoute = ({component: Component, componentProps = {}, path, location = {}, ...rest}) => {
    const {isAuthenticated, loginWithRedirect, checkIsAuthenticated} = useAuth0();
    const {pathname} = location;

    useEffect(() => {
        const fn = async () => {
            const isUserAuthenticated = await checkIsAuthenticated();

            if (!isUserAuthenticated) {
                clearLocalStorage();
                loginWithRedirect({
                    appState: {targetUrl: path},
                });
            }
        };
        fn();

        return () => {
            sessionStorage.setItem('previousHistoryPath', JSON.stringify({path, pathname}));
        };
    }, [checkIsAuthenticated, isAuthenticated, loginWithRedirect, path, pathname]);

    const render = (props) => (isAuthenticated === true ? <Component {...props} {...componentProps} /> : null);
    return <Route path={path} render={render} {...rest} />;
};

PrivateRoute.propTypes = {
    path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};

export default PrivateRoute;
