import {BaseIconProps} from '@/assets/icons/types';

type VideoCallIconProps = BaseIconProps;

export const VideoCallIcon = ({width = '72', height = '48', viewBox = '0 0 72 48'}: VideoCallIconProps) => (
    <svg width={width} height={height} viewBox={viewBox}>
        <g id="1.5.-Incoming-Video-(Zoom)-Call" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.1.-Incoming-Video-(Zoom)-Call" transform="translate(-723.000000, -120.000000)">
                <g id="Icon---Incoming-Video-Call-48px" transform="translate(723.000000, 120.000000)">
                    <g id="videocam-24px" fill="#FFFFFF" fillRule="nonzero">
                        <path d="M48,8 L48,40 L8,40 L8,8 L48,8 M52,0 L4,0 C1.8,0 0,1.8 0,4 L0,44 C0,46.2 1.8,48 4,48 L52,48 C54.2,48 56,46.2 56,44 L56,30 L72,46 L72,2 L56,18 L56,4 C56,1.8 54.2,0 52,0 Z" />
                    </g>
                    <polygon
                        fill="#0AA6A8"
                        points="33.2348754 29.2550415 27.0901542 29.2550415 39 17.3451957 35.6548043 14 23.7449585 25.9098458 23.7449585 19.7651246 19 19.7651246 19 34 33.2348754 34"
                    />
                </g>
            </g>
        </g>
    </svg>
);
