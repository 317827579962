import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import ReportIcon from '@mui/icons-material/Report';
import {Grid} from '@mui/material';

import {MHDialog, MHRegularButton} from '@/components/base';
import routes from '@/constants/routes';

import {isModalOpenSelector} from './redux/selectors';
import {hideNotAccessibleModal} from './redux/slice';

import styles from './pageNotAccessibleModal.module.scss';

export const PageNotAccessibleModal = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const onClose = () => {
        history.push(routes.PATIENTS_CENSUS);
        dispatch(hideNotAccessibleModal());
    };
    const isModalOpen = useSelector(isModalOpenSelector);

    return (
        <MHDialog isCloseIcon onClose={onClose} title="Page not accessible" open={isModalOpen}>
            <div className={styles.image}>
                <ReportIcon />
            </div>
            <div className={styles.errorText}>It seems this page not accessible. Please, check URL of this page.</div>
            <Grid container justifyContent="flex-end" alignItems="center" gap="16px" marginTop="24px">
                <MHRegularButton text="Close" btnType="secondary-positive" onClick={onClose} />
            </Grid>
        </MHDialog>
    );
};
