import {shallowEqual, useSelector} from 'react-redux';

import {MHSwitch} from '@/components/base/MHSwitch';
import {LoggingActions} from '@/constants/loggingActions';
import awsConnectService from '@/services/awsConnectApiService';
import {loggerService} from '@/services/loggerService';

import {phoneCallDataSelector, userSelector} from '../redux/selectors';
import {AGENT_STATUSES} from '../shared/constants';

import styles from './availabilityStatusSwitcher.module.scss';

export const AvailabilityStatusSwitcher = () => {
    const {awsConnectAgentStatus} = useSelector(phoneCallDataSelector, shallowEqual);
    const {email} = useSelector(userSelector, shallowEqual);

    const {goOffline, goAvailable} = awsConnectService;

    const changeUserState = () => {
        const action = isAvailable ? goOffline : goAvailable;

        action && action();

        loggerService.logEvent({
            severity: 'Info',
            email: email,
            action: LoggingActions.amazonConnectStateChange,
            message: `State switcher was changed to ${isAvailable ? 'Offline' : 'Available'} by user`,
        });
    };

    const isAvailable = awsConnectAgentStatus === AGENT_STATUSES.AVAILABLE;
    const isOnCall =
        awsConnectAgentStatus === AGENT_STATUSES.BUSY || awsConnectAgentStatus === AGENT_STATUSES.ON_VIDEO_CALL;
    return (
        <div id="connectStatusSwitcher">
            <MHSwitch
                disabled={isOnCall}
                checked={isAvailable}
                onChange={changeUserState}
                label={awsConnectAgentStatus}
                switchWrapperClassName={isOnCall ? styles.busySwitch : ''}
            />
        </div>
    );
};
