import {AppStartListening} from '@/redux/store/listenerMiddleware';
import appointmentsService from '@/services/appointmentsService';
import {generalNotify} from '@/utils/notifyMessages';

import {
    getAppointment,
    getAppointmentFail,
    getAppointmentSuccess,
    undoCheckIn,
    undoCheckInFail,
    undoCheckInSuccess,
    undoCheckOut,
    undoCheckOutFail,
    undoCheckOutSuccess,
    undoConfirm,
    undoConfirmFail,
    undoConfirmSuccess,
} from './slice';

const addFeatureListeners = (startListening: AppStartListening) => {
    startListening({
        actionCreator: getAppointment,
        effect: async (action, listenerApi) => {
            try {
                const data = await appointmentsService.getAppointmentCheckOut(action.payload);
                listenerApi.dispatch(getAppointmentSuccess(data));
            } catch (err) {
                listenerApi.dispatch(getAppointmentFail());
            }
        },
    });

    startListening({
        actionCreator: undoCheckIn,
        effect: async (action, listenerApi) => {
            try {
                const {onActionSuccess, ...requestData} = action.payload;
                await appointmentsService.undoCheckIn(requestData);
                generalNotify({
                    title: 'Success',
                    message: 'Appointment check-in reverted.',
                    status: 'success',
                });
                onActionSuccess && onActionSuccess();
                listenerApi.dispatch(undoCheckInSuccess());
            } catch (err) {
                listenerApi.dispatch(undoCheckInFail());
            }
        },
    });

    startListening({
        actionCreator: undoCheckOut,
        effect: async (action, listenerApi) => {
            try {
                const {onActionSuccess, ...requestData} = action.payload;
                await appointmentsService.undoCheckOut(requestData);
                generalNotify({
                    title: 'Success',
                    message: 'Appointment check-out reverted.',
                    status: 'success',
                });
                onActionSuccess && onActionSuccess();
                listenerApi.dispatch(undoCheckOutSuccess());
            } catch (err) {
                listenerApi.dispatch(undoCheckOutFail());
            }
        },
    });

    startListening({
        actionCreator: undoConfirm,
        effect: async (action, listenerApi) => {
            try {
                const {onActionSuccess, ...requestData} = action.payload;
                await appointmentsService.undoConfirm(requestData);
                generalNotify({
                    title: 'Success',
                    message: 'Appointment confirm reverted.',
                    status: 'success',
                });
                onActionSuccess && onActionSuccess();
                listenerApi.dispatch(undoConfirmSuccess());
            } catch (err) {
                listenerApi.dispatch(undoConfirmFail());
            }
        },
    });
};

export default addFeatureListeners;
