import {AppStartListening} from '@/redux/store/listenerMiddleware';
import userService from '@/services/userService';

import {
    getUserInitialData,
    refreshUserFeatures,
    refreshUserFeaturesSuccess,
    setUserInitialData,
    updateUserData,
    updateUserDataSuccess,
} from './slice';

const addFeatureListeners = (startListening: AppStartListening) => {
    startListening({
        actionCreator: refreshUserFeatures,
        effect: async (_action, listenerApi) => {
            try {
                const featuresData = await userService.getUserFeatures();
                const features = featuresData.reduce((acc: {[key: string]: boolean}, {uid, enable}) => {
                    acc[uid] = enable;
                    return acc;
                }, {});
                listenerApi.dispatch(refreshUserFeaturesSuccess(features));
            } catch (error) {
                console.error('Get user features failed');
            }
        },
    });
    startListening({
        actionCreator: getUserInitialData,
        effect: async (action, listenerApi) => {
            const {tokenData, user} = action.payload;
            try {
                const employment = await userService.getCurrentUser();
                listenerApi.dispatch(refreshUserFeatures());
                await listenerApi.condition((action) => action.type === refreshUserFeaturesSuccess.type);

                listenerApi.dispatch(setUserInitialData({user, tokenData, employment}));
            } catch (error) {
                console.error('get initial user data failed');
            }
        },
    });
    startListening({
        actionCreator: updateUserData,
        effect: async (_action, listenerApi) => {
            try {
                const employment = await userService.getCurrentUser();

                listenerApi.dispatch(updateUserDataSuccess(employment));
            } catch (error) {
                console.error('get user data failed');
            }
        },
    });
};

export default addFeatureListeners;
