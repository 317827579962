import ring from '@/assets/sounds/ring.mp3';
import ringPers from '@/assets/sounds/ring-old-phone.mp3';

interface AudioElement extends HTMLAudioElement {
    setSinkId(id: string): Promise<undefined>;
}

type Config = {
    loop: boolean;
    isPERS: string;
};

class AudioService {
    private audioNodes: Array<HTMLAudioElement> = [];

    createAudioNodes = (callback: () => void, config: Config = {isPERS: 'false', loop: false}) => {
        (async () => {
            await navigator.mediaDevices.getUserMedia({audio: true});
            const all_devs = await navigator.mediaDevices.enumerateDevices();
            const audioouts = all_devs.filter(({kind}) => kind === 'audiooutput');

            audioouts.forEach((device) => {
                const audio = this._createAudioElement(device.deviceId, config);
                this.audioNodes.push(audio);
            });
            callback();
        })().catch(() => {
            console.error('error: check audio permissions');
            callback();
        });
    };

    playNodes = () => {
        this.audioNodes.forEach((audio) => {
            try {
                audio.play();
            } catch (error) {
                console.error('error: wrong audio device');
            }
        });
    };

    stopNodes = () => {
        this.audioNodes.forEach((audio) => {
            try {
                audio.pause();
            } catch (error) {
                console.error('error: wrong audio device');
            }
        });
    };

    _createAudioElement = (deviceId: string, {isPERS, loop}: Config) => {
        const audio: AudioElement = document.createElement('audio') as AudioElement;
        audio.src = isPERS === 'true' ? ringPers : ring;
        audio.loop = loop;
        audio.setSinkId(deviceId);
        return audio;
    };
}

export default () => {
    const instance = new AudioService();
    return instance;
};
