import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {
    AppointmentRequestDetailModel,
    LastAppointmentRequestDTO,
    SendAppointmentRequestDTO,
} from '@/types/gatewayDataModels';

import {GMRDialogState} from './stateTypes';

type SaveDialogDataPayload = SendAppointmentRequestDTO & {
    onSuccess: () => void;
};

const initialState: GMRDialogState = {
    gmrHistoryData: null,
    appointmentRequestDetails: null,
    loadingHandler: {
        saveGMRDataLoading: false,
        getGMRHistoryLoading: false,
        getAppointmentRequestDetailsLoading: false,
    },
};

const SLICE_NAME = 'gmrDialogState';

const gmrDialogSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        saveGMRData: (state: GMRDialogState, _action: PayloadAction<SaveDialogDataPayload>) => {
            state.loadingHandler.saveGMRDataLoading = true;
        },
        saveGMRDataSuccess: (state: GMRDialogState) => {
            state.loadingHandler.saveGMRDataLoading = false;
        },
        saveGMRDataFail: (state: GMRDialogState) => {
            state.loadingHandler.saveGMRDataLoading = false;
        },
        getGMRHistory: (state: GMRDialogState, _: PayloadAction<number>) => {
            state.loadingHandler.getGMRHistoryLoading = true;
        },
        getGMRHistorySuccess: (state: GMRDialogState, action: PayloadAction<LastAppointmentRequestDTO>) => {
            state.gmrHistoryData = action.payload;
            state.loadingHandler.getGMRHistoryLoading = false;
        },
        getGMRHistoryFail: (state: GMRDialogState) => {
            state.loadingHandler.getGMRHistoryLoading = false;
        },
        getAppointmentRequestDetails: (state: GMRDialogState, _action: PayloadAction<number>) => {
            state.loadingHandler.getAppointmentRequestDetailsLoading = true;
        },
        getAppointmentRequestDetailsSuccess: (
            state: GMRDialogState,
            action: PayloadAction<AppointmentRequestDetailModel>
        ) => {
            state.appointmentRequestDetails = action.payload;
            state.loadingHandler.getAppointmentRequestDetailsLoading = false;
        },
        getAppointmentRequestDetailsFail: (state: GMRDialogState) => {
            state.loadingHandler.getAppointmentRequestDetailsLoading = false;
        },
        getInitialData: (state: GMRDialogState) => state,
        clearGMRDialogData: () => initialState,
    },
});
const {reducer, actions} = gmrDialogSlice;

export const {
    saveGMRData,
    saveGMRDataSuccess,
    saveGMRDataFail,
    getGMRHistory,
    getGMRHistorySuccess,
    getGMRHistoryFail,
    clearGMRDialogData,
    getAppointmentRequestDetails,
    getAppointmentRequestDetailsSuccess,
    getAppointmentRequestDetailsFail,
    getInitialData,
} = actions;

export default reducer;
