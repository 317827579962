import classnames from 'classnames';

import {ActiveCall} from '@/redux/commonCalls/types';

import styles from './shared/common.module.scss';

type AdditionalInfoBlockProps = {
    callData: ActiveCall;
    isBlackLabels?: boolean;
};

export const AdditionalInfoBlock = ({callData, isBlackLabels}: AdditionalInfoBlockProps) => {
    const {phoneType, incomingPhoneNumber} = callData;
    const getAdditionalInfoRow = (label: string, info: string) => (
        <div className={styles.additionalInfoRowWrapper}>
            <div
                className={classnames(styles.additionalInfoLabel, {
                    [styles.additionalInfoLabelBlack]: isBlackLabels,
                })}
            >
                {label}
            </div>
            <div className={styles.additionalInfo}>{info}</div>
        </div>
    );

    return (
        <div
            className={classnames(styles.additionalInfoWrapper, {
                [styles.additionalInfoWrapperBlack]: isBlackLabels,
            })}
        >
            {(callData.nurseFirstName || callData.nurseLastName) &&
                getAdditionalInfoRow('Assigned Nurse:', `${callData.nurseFirstName} ${callData.nurseLastName}`)}
            {callData.patientSponsor && getAdditionalInfoRow('Sponsor:', callData.patientSponsor)}
            {incomingPhoneNumber && getAdditionalInfoRow(phoneType || 'Phone number:', incomingPhoneNumber)}
        </div>
    );
};
