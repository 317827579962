import {useState} from 'react';
import {useSelector} from 'react-redux';
import {Grid, Menu, MenuItem} from '@mui/material';
import classnames from 'classnames';

import {MHAvatar, MHTypography} from '@/components/base';
import {LoggingActions} from '@/constants/loggingActions';
import {handleFullLogout, useAuth0} from '@/services/auth0';
import {loggerService} from '@/services/loggerService';
import {formatDateTime, getDateTimeFormats} from '@/utils/timeFormatter';

import {employmentSelector, phoneCallDataSelector, selectBuildInfo} from '../redux/selectors';
import {UserProfile} from './UserProfile';

import styles from './rightSideMenu.module.scss';

const {DATE_FORMAT} = getDateTimeFormats();

export const RightSideMenu = () => {
    const {logout} = useAuth0();
    const user = useSelector(employmentSelector);
    const {isOutboundCallActive} = useSelector(phoneCallDataSelector);
    const buildInfo = useSelector(selectBuildInfo);

    const formattedBuildInfo = (() => {
        if (!buildInfo) return;

        const {version, time} = buildInfo;

        const formattedTime = time && formatDateTime(time, DATE_FORMAT);

        return [version, formattedTime].filter(Boolean).join(' • ');
    })();

    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setOpenDialog(false);
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleFullLogout(() => {
            setAnchorEl(null);
            logout({
                federated: true,
                returnTo: window.location.origin,
            });

            loggerService.logEvent({
                severity: 'Info',
                email: user?.email,
                action: LoggingActions.appLogout,
                message: 'Logout button click',
            });
        });
    };
    return (
        <>
            <Grid className={classnames(styles.patientName)} item>
                <MHTypography variant="headline4" color={isOutboundCallActive ? 'white' : 'black_80'} align="right">
                    {user?.firstName} {user?.lastName}
                </MHTypography>
            </Grid>
            <Grid className={styles.avatarWrapper} item>
                <MHAvatar
                    alt={`${user?.firstName}/${user?.lastName}`}
                    handleClick={(event) => setAnchorEl(event.currentTarget)}
                    customStyles={{root: styles.avatar}}
                />
            </Grid>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
                classes={{paper: styles.popupUserMenu}}
            >
                <MenuItem onClick={() => setOpenDialog(true)}>Profile Info</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>

                {formattedBuildInfo && (
                    <MenuItem classes={{root: styles.appVersion}}>
                        <MHTypography variant="bodyM" color="black_60">
                            {formattedBuildInfo}
                        </MHTypography>
                    </MenuItem>
                )}
            </Menu>

            <UserProfile aria-labelledby="confirmation-dialog-title" open={openDialog} onClose={handleClose} />
        </>
    );
};
