import {ReactNode} from 'react';

import {
    BloodGlucoseIcon,
    BloodPressureIcon,
    HeartRateIcon,
    HeatRateAtRestIcon,
    OrthostaticBloodPressureIcon,
    OxygenSaturationIcon,
    PainScaleIcon,
    RespiratoryRateAtRestIcon,
    RespiratoryRateIcon,
    SkinTemperatureIcon,
    TemperatureIcon,
    WeightIcon,
} from '@/assets/icons';

const defaultProps = {width: '32px', height: '28px', viewBox: '0 0 32 28'};
export const getMetricIcons = (customProps?: {[key: string]: string}): {[key: string]: ReactNode} => {
    const iconProps = {...defaultProps, ...customProps};
    return {
        BODY_TEMPERATURE: <TemperatureIcon {...iconProps} />,
        HEART_RATE: <HeartRateIcon {...iconProps} />,
        RESPIRATORY_RATE: <RespiratoryRateIcon {...iconProps} />,
        BLOOD_PRESSURE: <BloodPressureIcon {...iconProps} />,
        OXYGEN_SATURATION: <OxygenSaturationIcon {...iconProps} />,
        PERFUSION_INDEX: <OxygenSaturationIcon {...iconProps} />,
        BODY_WEIGHT: <WeightIcon {...iconProps} />,
        PAIN_SCALE: <PainScaleIcon {...iconProps} />,
        BLOOD_GLUCOSE: <BloodGlucoseIcon {...iconProps} />,
        ORTHOSTATIC_BLOOD_PRESSURE_SEATED: <OrthostaticBloodPressureIcon {...iconProps} />,
        ORTHOSTATIC_BLOOD_PRESSURE_STANDING: <OrthostaticBloodPressureIcon {...iconProps} />,
        ORTHOSTATIC_BLOOD_PRESSURE_RECLINING: <OrthostaticBloodPressureIcon {...iconProps} />,
        ORTHOSTATIC_BLOOD_PRESSURE: <OrthostaticBloodPressureIcon {...iconProps} />,
        HEART_RATE_AT_REST: <HeatRateAtRestIcon {...iconProps} />,
        RESPIRATORY_RATE_AT_REST: <RespiratoryRateAtRestIcon {...iconProps} />,
        SKIN_TEMPERATURE: <SkinTemperatureIcon {...iconProps} />,
    };
};
