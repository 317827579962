import {MouseEventHandler, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import WarningIcon from '@mui/icons-material/Warning';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {IconButton, Menu, MenuItem, MenuProps} from '@mui/material';
import {IMessage} from '@stomp/stompjs';
import classnames from 'classnames';

import {MHTypography} from '@/components/base';
import websocketService from '@/services/webSocketService';

import {urgentAlertsCountSelector, userIdSelector} from './redux/selectors';
import {getUrgentAlerts, getUrgentAlertsCount, setUrgentAlertsCount} from './redux/slice';
import {Content} from './Content';

import commonStyles from '../shared/common.module.scss';
import styles from './urgentAlerts.module.scss';

const StyledMenu = (props: MenuProps) => (
    <Menu
        classes={{paper: commonStyles.menuPaper, list: commonStyles.menuList}}
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
        {...props}
    />
);

export const UrgentAlerts = () => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState<Element>(null);
    const open = Boolean(anchorEl);

    const userId = useSelector(userIdSelector);
    const urgentAlertsCount = useSelector(urgentAlertsCountSelector);

    useEffect(() => {
        if (open) dispatch(getUrgentAlerts());
    }, [open, dispatch]);

    useEffect(() => {
        dispatch(getUrgentAlertsCount());
    }, [dispatch]);

    useEffect(() => {
        websocketService.subscribe(`/topic/alarms-count-${userId}`, (message: IMessage) => {
            const data: {count: string | number} = message?.body ? JSON.parse(message?.body) : {count: '0'};
            dispatch(setUrgentAlertsCount(+data?.count));
        });
        return () => {
            websocketService.unsubscribe(`/topic/alarms-count-${userId}`);
        };
    }, [userId, dispatch]);

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                classes={{root: commonStyles.iconButton}}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="large"
            >
                {urgentAlertsCount ? (
                    <>
                        <WarningIcon className={classnames(commonStyles.icon, styles.warningIcon)} />
                        <div className={styles.counter}>{urgentAlertsCount || ''}</div>
                    </>
                ) : (
                    <WarningAmberIcon className={commonStyles.icon} />
                )}
                <div className={classnames(commonStyles.triangle, {[commonStyles.triangleActive]: open})} />
            </IconButton>
            <StyledMenu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                <MenuItem classes={{root: commonStyles.menuItem}} key="urgent-alerts" onClick={handleClose}>
                    <div className={commonStyles.listTitle}>
                        <MHTypography color="white" variant="headline5">
                            Urgent Alerts
                        </MHTypography>
                    </div>
                </MenuItem>
                <div className={styles.content}>
                    <Content />
                </div>
            </StyledMenu>
        </div>
    );
};
