import {FC} from 'react';
import Box from '@mui/material/Box';
import classNames from 'classnames';

import style from './style.module.scss';

type TabPanelProps = {
    value: number;
    index: number;
    className?: string;
};

const TabPanel: FC<TabPanelProps> = ({children, value, index, className}) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        className={classNames(className)}
    >
        {value === index && (
            <Box p={3} className={style.boxRoot}>
                {children}
            </Box>
        )}
    </div>
);

export default TabPanel;
