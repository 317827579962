import {AppStartListening} from '@/redux/store/listenerMiddleware';
import availableAgentsService from '@/services/availableAgentsService';

import {callTransferToAgentQueueSelector} from './selectors';
import {
    callTransferToAgentQueue,
    callTransferToAgentQueueFail,
    callTransferToAgentQueueSuccess,
    getAvailableAgents,
    getAvailableAgentsFail,
    getAvailableAgentsSuccess,
} from './slice';

export const addFeatureListeners = (startListening: AppStartListening) => {
    startListening({
        actionCreator: getAvailableAgents,
        effect: async (_action, listenerApi) => {
            try {
                // @ts-ignore
                const {agentStatusList} = await availableAgentsService.getAgents();
                listenerApi.dispatch(getAvailableAgentsSuccess(agentStatusList));
            } catch (_error) {
                listenerApi.dispatch(getAvailableAgentsFail());
            }
        },
    });

    startListening({
        actionCreator: callTransferToAgentQueue,
        effect: async (_action, listenerApi) => {
            const {destinationAgentEmail, initialContactId, sourceAgentEmail, email} = callTransferToAgentQueueSelector(
                listenerApi.getState()
            );

            try {
                await availableAgentsService.callTransferToAgentQueue({
                    destinationAgentEmail,
                    initialContactId,
                    sourceAgentEmail: sourceAgentEmail || email,
                });
                listenerApi.dispatch(callTransferToAgentQueueSuccess());
            } catch (e) {
                listenerApi.dispatch(callTransferToAgentQueueFail());
            }
        },
    });
};

export default addFeatureListeners;
