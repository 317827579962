import {shallowEqual, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuItem from '@mui/material/MenuItem';

import {MHLoader, MHTypography} from '@/components/base';
import routes from '@/constants/routes';

import {Patient} from '../../shared/Patient';
import {getUrgentAlertsSelector} from '../redux/selectors';
import {Incidents} from './Incidents';

import styles from './content.module.scss';

export const Content = () => {
    const history = useHistory();
    const {getUrgentAlertsLoading, data} = useSelector(getUrgentAlertsSelector, shallowEqual);

    if (getUrgentAlertsLoading) {
        return <MHLoader />;
    }
    if (!data.length) {
        return (
            <div className={styles.container}>
                <MHTypography variant="headline4" color="black_60">
                    No Urgent Alerts
                </MHTypography>
            </div>
        );
    }
    const goToPatientPage = (id: number) => {
        history.push(routes.PATIENT_SINGLE_ALERTS(id.toString()));
    };

    return (
        <>
            {data.map(({patientData, incidents}) => (
                <MenuItem
                    classes={{root: styles.menuItem}}
                    key={patientData.id}
                    onClick={() => goToPatientPage(patientData.id)}
                >
                    <Patient {...patientData} />
                    <Incidents incidents={incidents} patientTimeZone={patientData.timeZone} />

                    <div className={styles.icon}>
                        <ChevronRightIcon fontSize="inherit" />
                    </div>
                </MenuItem>
            ))}
        </>
    );
};
