import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {EducationDocumentPackageHistoryDTO} from '@/types/gatewayDataModels';

import {EducationDocumentHistoryState} from './stateTypes';

const initialState: EducationDocumentHistoryState = {
    history: [],
    loadingHandler: {
        getHistoryLoading: true,
        getEducationMediaLoading: true,
    },
};

const SLICE_NAME = 'educationDocumentHistory';

const EducationDocumentHistorySlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        getEducationDocumentHistory: (state, _action: PayloadAction<{id: number}>) => {
            state.loadingHandler.getHistoryLoading = true;
        },
        getEducationDocumentHistorySuccess: (state, action: PayloadAction<EducationDocumentPackageHistoryDTO[]>) => {
            state.loadingHandler.getHistoryLoading = false;
            state.history = action.payload;
        },
        getEducationDocumentHistoryFail: (state) => {
            state.loadingHandler.getHistoryLoading = false;
        },
        getEducationMedia: (state, _action: PayloadAction<{fileName: string; cb: (url: string) => void}>) => {
            state.loadingHandler.getEducationMediaLoading = true;
        },
        getEducationMediaSuccess: (state) => {
            state.loadingHandler.getEducationMediaLoading = false;
        },
        getEducationMediaFail: (state) => {
            state.loadingHandler.getEducationMediaLoading = false;
        },
        clearState: () => initialState,
    },
});
const {reducer, actions} = EducationDocumentHistorySlice;

export const {
    getEducationDocumentHistory,
    getEducationDocumentHistorySuccess,
    getEducationDocumentHistoryFail,
    getEducationMedia,
    getEducationMediaSuccess,
    getEducationMediaFail,
    clearState,
} = actions;

export default reducer;
