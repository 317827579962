// runSaga is middleware.run function
// rootSaga is a your root saga for static sagas
const createSagaInjector = (runSaga, staticSagasList) => {
    // Create a dictionary to keep track of injected sagas
    const injectedSagas = new Map();

    const isInjected = (key) => injectedSagas.has(key);

    const injectSaga = (key, saga) => {
        // We won't run saga if it is already injected
        if (isInjected(key)) return;

        // Sagas return task when they executed, which can be used
        // to cancel them
        const task = runSaga(saga);

        // Save the task if we want to cancel it in the future
        injectedSagas.set(key, task);
    };

    for (let saga in staticSagasList) {
        if (staticSagasList.hasOwnProperty(saga)) {
            injectSaga(saga, staticSagasList[saga]);
        }
    }

    return injectSaga;
};

export default createSagaInjector;
