enum patients {
    CREATE_PATIENTS = 'create:patients',
    DISCHARGE_PATIENTS = 'manage:discharges',
    READ_PATIENTS = 'read:patients',
    READ_PATIENT_LOGS = 'read:patient_logs',
    EDIT_PATIENTS = 'edit:patients',
    ASSIGN_TO_NURSES = 'assign:patients_to_nurses',
    ASSIGN_TO_SERVICE_COORDINATOR = 'assign:patients_to_servicecoordinators',
    ASSIGN_TO_ATTENDINGS = 'assign:patients_to_attendings',
    ASSIGN_TO_RESPCLINICIANS = 'assign:patients_to_respclinicians',
    ASSIGN_KITS_PATIENTS = 'assign:kit_to_patients',
    CREATE_DEMO_PATIENTS = 'create:demo_patients',
    VOID_PATIENTS = 'void:patients',
    VOID_PENDING_PATIENTS = 'void:pending_patients',
    MANAGE_PRECAUTIONS = 'manage:precautions',
}

enum clinical_alerts {
    ACKNOWLEDGE_CLINICAL = 'acknowledge:clinical_alerts',
    CREATE_CLINICAL = 'create:clinical_alerts',
    EDIT_CLINICAL = 'edit:clinical_alerts',
}

enum tech_alerts {
    ACKNOWLEDGE_TECH = 'acknowledge:tech_alerts',
    CREATE_TECH = 'create:tech_alerts',
    EDIT_TECH = 'edit:tech_alerts',
}

enum urgent_alerts {
    MANAGE_URGENT_ALERTS = 'manage:urgent_alerts',
    READ_URGENT_ALERTS = 'read:urgent_alerts',
}

enum vital_signs {
    CREATE_VITAL_SIGNS = 'create:vital_signs',
}

enum surveys {
    CREATE_SURVEYS = 'create:surveys',
    EDIT_SURVEYS = 'edit:surveys',
}

enum appointments {
    CREATE_APPOINTMENTS = 'create:appointments',
    EDIT_APPOINTMENTS = 'edit:appointments',
}

enum personnel {
    MANAGE_PERSONNEL = 'manage:personnel',
}

enum clusters {
    MANAGE_CLUSTERS = 'manage:clusters',
    READ_CLUSTERS = 'read:clusters',
    CREATE_CLUSTERS = 'create:clusters',
    EDIT_CLUSTERS = 'edit:clusters',
}

enum manageTech {
    READ_DEVICES = 'read:devices',
    MANAGE_DEVICES = 'manage:devices',
}

enum patient_media {
    MANAGE_PATIENT_MEDIA = 'manage:patient_media',
}

enum manage_barcode {
    MANAGE_BARCODE_READINGS = 'manage:barcode_readings',
}

enum orders {
    READ_ORDERS = 'read:orders',
    MANAGE_ORDERS = 'manage:orders',
    MANAGE_CREATE_AND_ACKNOWLEDGE_MANUAL_ORDER = 'manage:create_and_acknowledge_manual_order',
    MANAGE_CREATE_ANOTHER_ORDER = 'manage:create_another_order',
}

enum loggedInUsers {
    READ_LOGGED_IN_USERS = 'read:logged_in_users',
}

enum oneOffTasks {
    MANAGE_STANDARD_TASK_TEMPLATES = 'manage:standard_task_templates',
    MANAGE_STANDARD_TASK = 'manage:tasks',
}

enum vendorDictionary {
    MANAGE_VENDOR_DICTIONARY = 'manage:vendor_dictionary',
    READ_VENDOR_DICTIONARY = 'read:vendor_dictionary',
}

enum messageHistory {
    READ_EHR_MESSAGE_HISTORY = 'read:ehr_message_history',
}

enum actionQueue {
    READ_SERVICE_REQUESTS = 'read:service_requests',
    MANAGE_SERVICE_REQUESTS = 'manage:service_requests',
}

enum manageServiceAreas {
    MANAGE_SERVICE_AREAS = 'manage:service_areas',
}

enum callRouting {
    MANAGE_CALL_ROUTING = 'manage:routing',
}

enum instanceSettings {
    MANAGE_FEATURES = 'manage:features',
}

enum educationDocuments {
    MANAGE_EDUCATION_DOCUMENTS = 'manage:education_files',
}

enum patientsApptsCalendar {
    MULTI_PATIENTS_CALENDAR = 'manage:multipatient_calendar',
    PROVIDER_VIEW_CALENDAR = 'manage:provider_view_calendar',
}

enum appointmentDocuments {
    MANAGE_APPOINTMENT_DOCUMENTS = 'manage:appointment_documents',
}

enum manageMissedCheckInCheckOut {
    MANAGE_MISSED_CHECK_IN_CHECK_OUT = 'manage:missed_checkin-out',
}

enum equipmentDepots {
    MANAGE_EQUIPMENT_DEPOT = 'manage:equipment_depot',
}

enum miniNotes {
    MANAGE_MINI_NOTE_GENERATE = 'manage:mininotegenerate',
}

enum myPage {
    MANAGE_MY_PAGE = 'manage:my_page',
}

enum customLocations {
    MANAGE_CUSTOM_LOCATIONS = 'manage:custom_locations',
}

enum referringFacilities {
    MANAGE_REFERRING_FACILITIES = 'manage:referring_facilities',
}

enum performanceImprovementLog {
    // Create and save new log entry
    CREATE_IMPROVEMENT_LOG = 'create:report_imp_log_entry',
    // View any reported log entry
    READ_REPORTED_IMPROVEMENT_LOG = 'read:report_imp_log_entry',
    // Edit any log entry details and status
    EDIT_REPORTED_IMPROVEMENT_LOG = 'edit:report_imp_log_entry',
    // Review any log entry, edit “In Review” log entry details, close any log entry
    MANAGE_REVIEW_IMPROVEMENT_LOG = 'manage:review_imp_log_entry',
    // View any closed log entry
    READ_CLOSED_IMPROVEMENT_LOG = 'read:closed_imp_log_entry',
    // Reopen any closed log entry
    MANAGE_CLOSED_IMPROVEMENT_LOG = 'manage:closed_imp_log_entry',
}

enum service_requests {
    CREATE_BYPASS_MODIFIED_SR = 'create:bypass_modified_SR',
}

const dataCollection = {
    CONFIGURE_DATA_COLLECTION: 'configure:data_collection',
    UPDATE_DATA_COLLECTION: 'update:data_collection',
};

const carePlanning = {
    MANAGE_CARE_PLANNING: 'manage:care_plan',
};

export default {
    ...patients,
    ...clinical_alerts,
    ...tech_alerts,
    ...urgent_alerts,
    ...vital_signs,
    ...surveys,
    ...appointments,
    ...personnel,
    ...clusters,
    ...manageTech,
    ...patient_media,
    ...orders,
    ...loggedInUsers,
    ...oneOffTasks,
    ...vendorDictionary,
    ...messageHistory,
    ...manageServiceAreas,
    ...callRouting,
    ...actionQueue,
    ...instanceSettings,
    ...educationDocuments,
    ...patientsApptsCalendar,
    ...appointmentDocuments,
    ...equipmentDepots,
    ...manageMissedCheckInCheckOut,
    ...miniNotes,
    ...myPage,
    ...customLocations,
    ...referringFacilities,
    ...performanceImprovementLog,
    ...service_requests,
    ...dataCollection,
    ...carePlanning,
    ...manage_barcode,
};
