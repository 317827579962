import errorBoundaryReducer from '@/components/base/MHErrorBoundary/redux/slice';
import referralFieldsReducer from '@/components/features/AppointmentInformation/redux/slice';
import confirmPartnerChangeDialogReducer from '@/components/features/ConfirmPartnerChangeDialog/redux/slice';
import createImprovementLogEntryButtonReducer from '@/components/features/CreateImprovementLogEntryButton/redux/slice';
import educationDocumentHistoryReducer from '@/components/features/EducationDocumentHistory/redux/slice';
import gmrDialogReducer from '@/components/features/GMRDialog/redux/initialize';
import patientInfoPanelReducer from '@/components/features/PatientInfoAndSchedule/redux/slice';
import referralDocumentationReducer from '@/components/features/ReferralDocumentation/redux/slice';
import selectStatesReducer from '@/components/features/SelectStates/redux/slice';
import shortPatientInfoStringReducer from '@/components/features/ShortPatientInfoString/redux/slice';
import undoCheckinCheckoutReducer from '@/components/features/UndoCheckInCheckOutDialog/redux/slice';
import availableCallTransferAgentsReducer from '@/components/general/AvailableCallTransferAgents/redux/initialize';
import changeMissionControlReducer from '@/components/general/ChangeMissionControl/redux/slice';
import leftNavbarReducer from '@/components/general/LeftNavbar/redux/initialize';
import pageNotAccessible from '@/components/general/PageNotAccessibleModal/redux/slice';
import {reducer as actionQueueReducer} from '@/pages/ActionQueuePage/redux/slice';

import callReducer from './commonCalls/slice';
import commonDataReducer from './commonData/slice';
import commonUserDataReducer from './commonUserData/slice';

export default {
    commonDataReducer,
    commonUserDataReducer,
    callReducer,
    undoCheckinCheckoutReducer,
    patientInfoPanelReducer,
    changeMissionControlReducer,
    educationDocumentHistoryReducer,
    confirmPartnerChangeDialogReducer,
    referralDocumentationReducer,
    referralFieldsReducer,
    shortPatientInfoStringReducer,
    createImprovementLogEntryButtonReducer,
    selectStatesReducer,
    pageNotAccessible,
    availableCallTransferAgentsReducer,
    leftNavbarReducer,
    gmrDialogReducer,
    errorBoundaryReducer,
    actionQueueReducer,
};
