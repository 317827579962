import MuiDrawer from './MuiDrawer';
import MuiFormControl from './MuiFormControl';
import MuiFormLabel from './MuiFormLabel';
import MuiLink from './MuiLink';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTabs from './MuiTabs';

export default {
    MuiPaper,
    MuiDrawer,
    MuiTableHead,
    MuiTableCell,
    MuiTabs,
    MuiLink,
    MuiFormControl,
    MuiFormLabel,
    MuiCssBaseline: {
        '@global': {
            body: {
                padding: 0,
                fontSize: '14px',
            },
            header: {
                padding: 0,
            },
        },
    },
    MuiDialogActions: {
        root: {
            padding: '24px 0 0 0',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 'auto',
            '& > button': {
                margin: '0 0 0 16px',
            },
        },
    },
    MuiBackdrop: {root: {backgroundColor: 'rgba(7, 19, 30, 0.9)'}, invisible: {backgroundColor: 'transparent'}},
    MuiTab: {root: {'&.Mui-selected': {color: 'rgba(0,26,112, 1)'}}},
};
