import jwtDecode from 'jwt-decode';

import permissions from '@/constants/PERMISSIONS';
import routesUrls from '@/constants/routes';

const {
    MANAGE_PERSONNEL,
    READ_DEVICES,
    MANAGE_ORDERS,
    READ_LOGGED_IN_USERS,
    MANAGE_STANDARD_TASK_TEMPLATES,
    MANAGE_CLUSTERS,
    READ_CLUSTERS,
    CREATE_CLUSTERS,
    EDIT_CLUSTERS,
    MANAGE_VENDOR_DICTIONARY,
    READ_VENDOR_DICTIONARY,
    READ_EHR_MESSAGE_HISTORY,
    MANAGE_CALL_ROUTING,
    READ_SERVICE_REQUESTS,
    MANAGE_SERVICE_REQUESTS,
    MANAGE_SERVICE_AREAS,
    MANAGE_FEATURES,
    READ_PATIENT_LOGS,
    MANAGE_EDUCATION_DOCUMENTS,
    EDIT_APPOINTMENTS,
    MULTI_PATIENTS_CALENDAR,
    PROVIDER_VIEW_CALENDAR,
    MANAGE_EQUIPMENT_DEPOT,
    READ_PATIENTS,
    MANAGE_MY_PAGE,
    MANAGE_CREATE_AND_ACKNOWLEDGE_MANUAL_ORDER,
    READ_REPORTED_IMPROVEMENT_LOG,
    EDIT_REPORTED_IMPROVEMENT_LOG,
    MANAGE_REVIEW_IMPROVEMENT_LOG,
    READ_CLOSED_IMPROVEMENT_LOG,
    CREATE_IMPROVEMENT_LOG,
    MANAGE_CLOSED_IMPROVEMENT_LOG,
    // MANAGE_CARE_PLANNING
} = permissions;

const routes: {route: string; title: string; entity?: string; exact?: boolean; access?: string[]}[] = [
    {
        route: '/my-page/:nurseId',
        title: 'My Page',
        entity: 'myPage',
        exact: false,
        access: [MANAGE_MY_PAGE],
    },
    {route: '/patients', title: 'Patients', exact: false, entity: 'patients', access: [READ_PATIENTS]},
    {
        route: routesUrls.ORDERS,
        title: '',
        exact: false,
        entity: 'orders',
        access: [MANAGE_ORDERS, MANAGE_CREATE_AND_ACKNOWLEDGE_MANUAL_ORDER, MANAGE_SERVICE_REQUESTS],
    },
    {
        route: routesUrls.APPOINTMENTS,
        title: '',
        exact: false,
        entity: 'appointments',
        access: [MANAGE_ORDERS, EDIT_APPOINTMENTS],
    },
    {
        route: routesUrls.PATIENT_SINGLE(),
        title: 'Patient page',
        exact: false,
        entity: 'patients',
        access: ['*'],
    },
    {
        route: '/calendar',
        title: 'Calendar',
        entity: 'calendar',
        exact: false,
        access: [MULTI_PATIENTS_CALENDAR, PROVIDER_VIEW_CALENDAR],
    },
    {
        route: routesUrls.VENDOR_DICTIONARY_PAGE(),
        exact: true,
        title: 'Vendor Dictionary',
        entity: 'vendors',
        access: [MANAGE_VENDOR_DICTIONARY, READ_VENDOR_DICTIONARY],
    },
    {
        route: routesUrls.ACTION_QUEUE,
        exact: false,
        title: 'Action Queue',
        entity: 'action-queue',
        access: [READ_SERVICE_REQUESTS, MANAGE_SERVICE_REQUESTS],
    },
    {
        route: routesUrls.ADMINISTRATE,
        title: 'Administration',
        entity: 'administrate',
        exact: false,
        access: [
            MANAGE_PERSONNEL,
            READ_LOGGED_IN_USERS,
            MANAGE_CALL_ROUTING,
            MANAGE_FEATURES,
            MANAGE_SERVICE_AREAS,
            MANAGE_EDUCATION_DOCUMENTS,
            READ_EHR_MESSAGE_HISTORY,
            READ_PATIENT_LOGS,
            READ_DEVICES,
            MANAGE_STANDARD_TASK_TEMPLATES,
            MANAGE_CLUSTERS,
            READ_CLUSTERS,
            CREATE_CLUSTERS,
            EDIT_CLUSTERS,
            MANAGE_EQUIPMENT_DEPOT,
        ],
    },
    {
        route: routesUrls.PERFORMANCE_IMPROVEMENT_LOG,
        title: 'Performance Improvement Log',
        exact: false,
        entity: 'performanceImprovementsLog',
        access: [
            CREATE_IMPROVEMENT_LOG,
            READ_REPORTED_IMPROVEMENT_LOG,
            EDIT_REPORTED_IMPROVEMENT_LOG,
            MANAGE_REVIEW_IMPROVEMENT_LOG,
            READ_CLOSED_IMPROVEMENT_LOG,
            MANAGE_CLOSED_IMPROVEMENT_LOG,
        ],
    },
    {
        route: routesUrls.MULTI_MULTI_NOT_SUPPORTED,
        title: '',
        exact: true,
        entity: 'MultiMultiNotSupported',
        access: ['*'],
    },
    {
        route: routesUrls.CARE_PLANNING(),
        title: '',
        exact: false,
        entity: 'carePlanning',
        access: ['*'], // TODO: replace to MANAGE_CARE_PLANNING
    },
];

class PermissionsManager {
    getRoleSpecifics = (
        token: string,
    ):
        | {
              routes: {route: string; title: string; entity?: string; exact?: boolean; access?: string[]}[];
              missionControlIds: number[];
              permissions: {[key: string]: boolean};
          }
        | {missionControlIds: number[]} => {
        const permissionsObj = this._getPermissionsFromToken(token);
        const {missionControlIds, formatted, original} = permissionsObj;

        if (original.length) {
            const routes = this._getRoutesFromPermissions(original);

            return {missionControlIds, permissions: formatted, routes};
        }

        return {missionControlIds};
    };

    _getPermissionsFromToken = (token: string) => {
        const output: {[key: string]: boolean} = {};
        const tokenData: {
            permissions: string[];
            ['https://cesia.mhgi.io/missionControlIds']: number[];
        } = jwtDecode(token);
        const permissions = tokenData.permissions;
        const missionControlIds = tokenData['https://cesia.mhgi.io/missionControlIds'];
        permissions.forEach((el) => {
            // ex. create:patient
            output[el] = true;
        });
        return {formatted: output, original: permissions, missionControlIds};
    };

    _getRoutesFromPermissions(permissions: string[]) {
        return routes.filter((el) => {
            return el.access[0] === '*' || el.access.find((q) => permissions.indexOf(q) !== -1);
        });
    }
}

const permissionsManager = new PermissionsManager();
export default permissionsManager;
