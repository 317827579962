import {MouseEventHandler, useState} from 'react';
import {BadgeOutlined} from '@mui/icons-material';
import {IconButton, Menu} from '@mui/material';
import classnames from 'classnames';

import {ShiftMenu} from './ShiftMenu';

import styles from '../shared/common.module.scss';

export const ShiftTracker = () => {
    const [anchorEl, setAnchorEl] = useState(null as HTMLButtonElement);
    const [rowExpandedStateById, setRowExpandedState] = useState({} as Record<string, boolean>);

    const open = Boolean(anchorEl);

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleRowExpand = (id: number) => {
        setRowExpandedState({...rowExpandedStateById, [id]: !rowExpandedStateById[id]});
    };

    return (
        <>
            <IconButton
                classes={{root: styles.iconButton}}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="large"
            >
                <BadgeOutlined className={styles.icon} />
                <div className={classnames(styles.triangle, open && styles.triangleActive)} />
            </IconButton>

            <Menu
                classes={{paper: styles.menuPaper, list: styles.menuList}}
                elevation={0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{vertical: 'top', horizontal: 'center'}}
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                {open && (
                    <ShiftMenu
                        toggleRowExpand={toggleRowExpand}
                        handleClose={handleClose}
                        rowExpandedStateById={rowExpandedStateById}
                    />
                )}
            </Menu>
        </>
    );
};
