import {
    VIDEO_DEFAULT_CAMERA_ID,
    VIDEO_DEFAULT_CAMERA_TITLE,
    VIDEO_DEFAULT_MIC_ID,
    VIDEO_DEFAULT_MIC_TITLE,
    VIDEO_DEFAULT_SPEAKER_ID,
    VIDEO_DEFAULT_SPEAKER_TITLE,
} from '@/constants/constants';
import {VideoSystemDevices} from '@/types/video';

const storageDevicesMapper = {
    camera: {
        id: VIDEO_DEFAULT_CAMERA_ID,
        title: VIDEO_DEFAULT_CAMERA_TITLE,
        setDevice: async (device: any) => {
            console.info(`[VideoWidget] [storageDevicesMapper] Selecting camera: `, device);
            await window.vidyoConnector.SelectLocalCamera({
                localCamera: device,
            });
        },
    },
    microphone: {
        id: VIDEO_DEFAULT_MIC_ID,
        title: VIDEO_DEFAULT_MIC_TITLE,
        setDevice: async (device: any) => {
            console.info(`[VideoWidget] [storageDevicesMapper] Selecting microphone: `, device);
            await window.vidyoConnector.SelectLocalMicrophone({
                localMicrophone: device,
            });
        },
    },
    speaker: {
        id: VIDEO_DEFAULT_SPEAKER_ID,
        title: VIDEO_DEFAULT_SPEAKER_TITLE,
        setDevice: async (device: any) => {
            console.info(`[VideoWidget] [storageDevicesMapper] Selecting speaker: `, device);
            await window.vidyoConnector.SelectLocalSpeaker({
                localSpeaker: device,
            });
        },
    },
};

const selectAnyNonIntegratedDevice = (deviceName: VideoSystemDevices) => {
    if (!window[deviceName]) {
        console.error(`[VideoWidget] No ${deviceName} devices found`);
        return;
    }

    const deviceCtrl = storageDevicesMapper[deviceName];

    const anyNonIntegratedDevice = window?.[deviceName]?.find((cam: any) => !cam.name.match(/integrated/i));
    if (anyNonIntegratedDevice) {
        console.info(`[VideoWidget] Found non-integrated ${deviceName}: `, anyNonIntegratedDevice);
        deviceCtrl.setDevice(anyNonIntegratedDevice);
    }
};

export const loadPrevSelectedDevice = (deviceName: VideoSystemDevices) => {
    if (!window[deviceName]) {
        console.error(`[VideoWidget] No ${deviceName} devices found`);
        return;
    }

    const deviceCtrl = storageDevicesMapper[deviceName];

    const savedDeviceId = localStorage.getItem(deviceCtrl.id);
    const savedDeviceTitle = localStorage.getItem(deviceCtrl.title);

    if (savedDeviceId || savedDeviceTitle) {
        savedDeviceId && console.info(`[VideoWidget] Found defeault ${deviceName} ID: `, savedDeviceId);
        savedDeviceTitle && console.info(`[VideoWidget] Found defeault ${deviceName} title: `, savedDeviceTitle);
        const savedDevice = window?.[deviceName]?.find(
            (cam: any) => cam.id === savedDeviceId || cam.name.match(savedDeviceTitle),
        );
        if (savedDevice) {
            deviceCtrl.setDevice(savedDevice);
        } else {
            selectAnyNonIntegratedDevice(deviceName);
        }
    } else {
        console.info(`[VideoWidget] No saved ${deviceName} found`);
        selectAnyNonIntegratedDevice(deviceName);
    }
};
