import {lazy} from 'react';

import {generalNotify} from './notifyMessages';

const lazyWithRetry: typeof lazy = (componentImport) =>
    lazy(async () => {
        try {
            return await componentImport();
        } catch (error) {
            generalNotify({
                title: 'Application version updated',
                message: 'Application has been updated, please reload the browser',
                status: 'warning',
            });
            throw error;
        }
    });

export default lazyWithRetry;
