import {AppStartListening} from '@/redux/store/listenerMiddleware';
import generalInfoService from '@/services/generalInfoService';

import getCommonDataService from '../../services/commonDataService';
import {
    getCommonData,
    getCommonDataFail,
    getCommonDataStart,
    getCommonDataSuccess,
    getFilteredPayorsProductsSponsors,
    getFilteredPayorsProductsSponsorsFail,
    getFilteredPayorsProductsSponsorsSuccess,
} from './slice';

const addFeatureListeners = (startListening: AppStartListening) => {
    startListening({
        actionCreator: getCommonData,
        effect: async (action, listenerApi) => {
            const toLoad = getCommonDataService.checkLoad(action.payload);
            if (toLoad.length) {
                listenerApi.dispatch(getCommonDataStart(toLoad));
                try {
                    const data = await getCommonDataService.getCommonData(toLoad);
                    listenerApi.dispatch(getCommonDataSuccess({data, keys: toLoad}));
                } catch (error) {
                    listenerApi.dispatch(getCommonDataFail(toLoad));
                }
            } else {
                //we need delay here for proper work of listenerApi.condition (to make this action asynchronous)
                await listenerApi.delay(1);
                listenerApi.dispatch(getCommonDataSuccess());
            }
        },
    });
    startListening({
        actionCreator: getFilteredPayorsProductsSponsors,
        effect: async (_action, {dispatch}) => {
            try {
                const results = await Promise.all([
                    generalInfoService.getPayors(true),
                    generalInfoService.getProducts(true),
                    generalInfoService.getSponsors(true),
                ]);

                const [payorsFiltered, productsFiltered, sponsorsFiltered] = results;

                dispatch(
                    getFilteredPayorsProductsSponsorsSuccess({
                        payorsFiltered,
                        productsFiltered,
                        sponsorsFiltered,
                    })
                );
            } catch (error) {
                console.error(error);
                dispatch(getFilteredPayorsProductsSponsorsFail());
            }
        },
    });
};

export default addFeatureListeners;
