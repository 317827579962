import {StoreStateShape} from '@/redux/store/storeStateTypes';

const getUrgentAlertsSelector = ({
    urgentAlerts: {
        loadingHandler: {getUrgentAlertsLoading},
        data,
    },
}: StoreStateShape) => ({
    getUrgentAlertsLoading,
    data,
});
const userIdSelector = ({
    commonUserDataReducer: {
        employment: {id},
    },
}: StoreStateShape) => id;

const urgentAlertsCountSelector = ({urgentAlerts: {urgentAlertsCount}}: StoreStateShape) => urgentAlertsCount;

export {getUrgentAlertsSelector, urgentAlertsCountSelector, userIdSelector};
