import * as React from 'react';

type PatientDataLogWrapperProps = {
    /**
     * Attribute on clickable elements
     * (or any of their parents) that is used
     * to name the action that will be sent
     * to the Datadog Real User Monitoring (RUM)
     */
    actionName: string;
    /**
     * The content of the component.
     * Includes PHI information such as:
     * participant name, address, age etc.
     */
    children?: React.ReactNode;
    /**
     * PHI information such as:
     * participant name, address, age etc.
     */
    patientInfo?: string;
    /**
     * Custom attribute used to provide
     * a unique identifier for testing purpose
     */
    testId?: string;
    /**
     * Class name
     */
    className?: string;
};

export const PatientDataLogWrapper = ({
    actionName,
    children,
    patientInfo,
    testId,
    className,
}: PatientDataLogWrapperProps) => {
    if (!children && !patientInfo) return null;

    return (
        <span data-testid={testId} data-dd-action-name={actionName}>
            {children || <span className={className}>{patientInfo}</span>}
        </span>
    );
};
