import {BUILD_INFO, MISSION_CONTROLS} from '@/constants/commonDataNames';
import {getCommonData} from '@/redux/commonData/slice';
import {AppStartListening} from '@/redux/store/listenerMiddleware';

import {getInitialData} from './slice';

export const addFeatureListeners = (startListening: AppStartListening) => {
    startListening({
        actionCreator: getInitialData,
        effect: (_action, {dispatch}) => {
            dispatch(getCommonData([MISSION_CONTROLS, BUILD_INFO]));
        },
    });
};
