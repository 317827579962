import {adaptV4Theme, createTheme} from '@mui/material/styles';

import overrides from './overrides';
import palette from './palette';
import typography from './typography';

import screenSize from '../styles/screenSize.module.scss';

const theme = createTheme(
    adaptV4Theme({
        palette,
        typography,
        overrides,
        zIndex: {
            appBar: 10,
            drawer: 20,
        },
        breakpoints: {
            keys: ['xs', 'sm', 'md', 'lg', 'xl'],
            values: {
                xs: 960,
                sm: +screenSize.sizeSmall,
                md: +screenSize.sizeMedium,
                lg: +screenSize.sizeLarge,
                xl: +screenSize.sizeLargeAndMore,
            },
        },
    })
);

export default theme;
