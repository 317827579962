import MissedVideoCallOutlinedIcon from '@mui/icons-material/MissedVideoCallOutlined';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import classnames from 'classnames';

import {SirenIcon} from '@/assets/icons';
import colorConstants from '@/styles/colors_styleguide.module.scss';
import {MissedCallSummary} from '@/types/observationsModels';

import styles from './callTypeStyles.module.scss';

type CallTypeProps = {
    type: MissedCallSummary['type'];
    viewed: boolean;
};

export const CallType = ({type, viewed}: CallTypeProps) => {
    switch (type) {
        case 'VIDEO':
            return <MissedVideoCallOutlinedIcon className={classnames(styles.icon, {[styles.missed]: !viewed})} />;
        case 'AUDIO':
        case 'WIFI':
            return <PhoneMissedIcon className={classnames(styles.icon, {[styles.missed]: !viewed})} />;
        case 'PERS':
            return (
                <SirenIcon
                    width="24px"
                    height="24px"
                    fill={viewed ? colorConstants.black_60 : colorConstants.red_100}
                />
            );
        default:
            return null;
    }
};
