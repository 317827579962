import FeedbackOutlined from '@mui/icons-material/FeedbackOutlined';
import classnames from 'classnames';

import {PerformanceImprovementIcon} from '@/assets/icons';
import {PerformanceImprovementLogLevelOfImpact} from '@/types/performanceIssueTrackerDataModels';

import styles from './performanceImprovementLogTabIcon.module.scss';

type PerformanceImprovementLogTabIconProps = {
    count: number;
    highestLevelOfImpact: PerformanceImprovementLogLevelOfImpact;
    mainTab?: boolean;
};

export const PerformanceImprovementLogTabIcon = ({
    count,
    highestLevelOfImpact,
    mainTab,
}: PerformanceImprovementLogTabIconProps) => (
    <div className={styles.infoContainer}>
        {mainTab ? (
            <PerformanceImprovementIcon className={styles.mainTabIcon} />
        ) : (
            <FeedbackOutlined className={styles.icon} />
        )}

        {!!count && (
            <span
                className={classnames(styles.counter, styles[`${highestLevelOfImpact?.toLowerCase()}Counter`], {
                    [styles.mainTabCounter]: mainTab,
                })}
            >
                {count}
            </span>
        )}
    </div>
);
