import {deviceListenerCb} from './utils/deviceListenerCb';

declare global {
    interface Window {
        vidyoConnector: any;
        camera: any;
    }
}

type Props = {
    onAdded?: (localCamera: {id: string; name: string}) => void;
    onRemoved?: (localCameraId: string) => void;
    setCurCamera?: (camera: any) => void;
};

export const registerCamera = async (props?: Props) => {
    const {onAdded, onRemoved, setCurCamera} = props || {};

    window['camera'] = [];

    try {
        await window.vidyoConnector.RegisterLocalCameraEventListener(
            deviceListenerCb({onAdded, onRemoved, setCurDevice: setCurCamera, deviceName: 'camera'}),
        );
        console.info('LocalCameraEventListener registered');
    } catch (error) {
        console.error('RegisterLocalCameraEventListener failed', error);
    }
};
