import {DischargeIcon, PrecautionIcon} from '@/assets/icons';
import {MHTypography} from '@/components/base';
import {PATIENT_TYPES} from '@/constants/constants';
import {PatientTypeOptionsDTO} from '@/types/gatewayDataModels';
import {formatDateTime, getDateTimeFormats} from '@/utils/timeFormatter';

import styles from './patient.module.scss';

const {DATE_FORMAT} = getDateTimeFormats();

type PatientProps = {
    fullName?: string;
    gender?: string;
    birthDate?: string;
    activePrecautions?: boolean;
    discharged?: boolean;
    patientType?: PatientTypeOptionsDTO;
    voided?: boolean;
};

const GENDER_MAP: {[key: string]: string} = {
    MALE: 'M',
    FEMALE: 'F',
    UNKNOWN: 'UNK',
    OTHER: 'OTH',
};

export const Patient = ({
    fullName,
    gender,
    birthDate,
    activePrecautions,
    discharged,
    patientType,
    voided,
}: PatientProps) => {
    return (
        <div className={styles.wrapper}>
            <MHTypography variant="headline5" margin="0 8px 8px 0">
                <span data-dd-action-name="Patient Name">{fullName}</span>
            </MHTypography>
            <MHTypography variant="bodyM" color="black_60" marginRight="8px">
                {GENDER_MAP[gender]} <b>&#183;</b> {formatDateTime(birthDate, DATE_FORMAT)}
            </MHTypography>
            {activePrecautions && <PrecautionIcon className={styles.icon} />}
            {discharged && <DischargeIcon className={styles.icon} />}
            {patientType === PATIENT_TYPES.DEMO && (
                <div className={styles.label}>
                    <MHTypography variant="bodyM" color="red_100">
                        Demo
                    </MHTypography>
                </div>
            )}
            {voided && (
                <div className={styles.label}>
                    <MHTypography variant="bodyM" color="red_100">
                        Voided
                    </MHTypography>
                </div>
            )}
        </div>
    );
};
