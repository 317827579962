import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {AppointmentItemDTO, EquipmentKitResponseDTO, PatientResponseDTO} from '@/types/gatewayDataModels';

import {PatientInfoAndScheduleState} from './stateTypes';

const initialState: PatientInfoAndScheduleState = {
    patientInfo: null,
    patientAppointments: [],
    patientKits: [],
    scheduleDate: '',
    loadingHandler: {
        get_patient: true,
        get_appointments: true,
        get_patient_kits: true,
    },
};

const PatientInfoAndScheduleSlice = createSlice({
    name: 'patientInfoPanelReducer',
    initialState,
    reducers: {
        clearPatientInfoPanelReducer: () => initialState,
        changeScheduleDate: (state, action: PayloadAction<string>) => {
            state.scheduleDate = action.payload;
        },
        getAppointments: (state, _action: PayloadAction<{scheduleDate: string; patientId: number}>) => {
            state.loadingHandler.get_appointments = true;
        },
        getAppointmentsSuccess: (state, action: PayloadAction<AppointmentItemDTO[]>) => {
            state.patientAppointments = action.payload;
            state.loadingHandler.get_appointments = false;
        },
        getAppointmentsFail: (state) => {
            state.loadingHandler.get_appointments = false;
        },
        getPatientKits: (state, _action: PayloadAction<number>) => {
            state.loadingHandler.get_patient_kits = true;
        },
        getPatientKitsSuccess: (state, action: PayloadAction<EquipmentKitResponseDTO[]>) => {
            state.patientKits = action.payload;
            state.loadingHandler.get_patient_kits = false;
        },
        getPatientKitsFail: (state) => {
            state.loadingHandler.get_patient_kits = false;
        },
        getPatient: (state, action: PayloadAction<{patientId: number; scheduleDate: string}>) => {
            state.scheduleDate = action.payload.scheduleDate;
            state.loadingHandler.get_patient = true;
        },
        getPatientSuccess: (state, action: PayloadAction<PatientResponseDTO>) => {
            state.patientInfo = action.payload;
            state.loadingHandler.get_patient = false;
        },
        getPatientFail: (state) => {
            state.loadingHandler.get_patient = false;
        },
    },
});
const {reducer, actions} = PatientInfoAndScheduleSlice;

export const {
    getPatient,
    getPatientSuccess,
    getPatientFail,
    getPatientKits,
    getPatientKitsSuccess,
    getPatientKitsFail,
    clearPatientInfoPanelReducer,
    changeScheduleDate,
    getAppointments,
    getAppointmentsSuccess,
    getAppointmentsFail,
} = actions;

export default reducer;
