import {call, takeEvery} from 'redux-saga/effects';

import {UI_MANAGED_SETTINGS} from '@/constants/commonDataNames';
import getCommonDataWorker from '@/redux/commonData/saga';

import * as types from './actionTypes';

export default function* watcherSaga() {
    yield takeEvery(types.GET_UI_MANAGED_CONFIGURATION, getUiManagedConfigurationWorker);
}

function* getUiManagedConfigurationWorker() {
    yield call(getCommonDataWorker, [UI_MANAGED_SETTINGS]);
}
