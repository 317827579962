import {useEffect, useMemo} from 'react';
import {useController, useForm} from 'react-hook-form';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Grid} from '@mui/material';

import {MHDateTimeField, MHDialog, MHRegularButton, MHSelect} from '@/components/base';
import {CLUSTERS} from '@/constants/commonDataNames';
import {getCommonData} from '@/redux/commonData/slice';
import {transformToOptions} from '@/utils/general';
import {resolver} from '@/utils/resolver';

import {roleToShiftRolesMapping, shiftRoles, shiftRoleToRoleMapping} from '../../constants';
import {clustersSelector, personnelSelector} from '../../redux/selectors';
import {getPersonnel} from '../../redux/slice';
import {AddShiftFormTypes} from './formTypes';
import {validationSchema} from './validationSchema';

import styles from './addShiftModal.module.scss';

type AddShiftModalProps = {
    onClose: () => void;
    onConfirm: (values: AddShiftFormTypes) => void;
    isLoading: boolean;
};

export const AddShiftModal = ({onClose, onConfirm, isLoading}: AddShiftModalProps) => {
    const dispatch = useDispatch();
    const personnel = useSelector(personnelSelector, shallowEqual);
    const {clusters} = useSelector(clustersSelector, shallowEqual);

    const {handleSubmit, control, trigger, formState} = useForm<AddShiftFormTypes>({
        resolver: resolver(validationSchema),
        mode: 'all',
    });

    const {
        field: {value: personnelId, onChange: onPersonnelIdChange},
    } = useController({name: 'personnelId', control});

    const {
        field: {value: roleId, onChange: onRoleIdChange},
    } = useController({name: 'role', control});

    const {
        field: {value: clusterIds, onChange: onClustersIdsChange},
    } = useController({name: 'clusterIds', control});

    const {
        field: {value: start, onChange: onStartChange},
        fieldState: startFieldState,
    } = useController({name: 'shiftStart', control});

    const {
        field: {value: end, onChange: onEndChange},
        fieldState: endFieldState,
    } = useController({name: 'shiftEnd', control});

    const selectedPersonnel = useMemo(() => {
        return personnel.find(({id}) => id === personnelId);
    }, [personnelId, personnel]);

    const clusterOptions = useMemo(() => {
        if (!clusters) {
            return [];
        }

        const availableClusters = selectedPersonnel?.clusters || clusters;

        return transformToOptions(availableClusters, {label: 'name', value: 'id'});
    }, [clusters, selectedPersonnel]);

    const personnelRoleOptions = useMemo(() => {
        if (selectedPersonnel) {
            return shiftRoles.filter(({value}) => selectedPersonnel.roles?.includes(shiftRoleToRoleMapping[value]));
        }

        return shiftRoles;
    }, [selectedPersonnel]);

    const personnelOptions = useMemo(() => {
        if (!personnel) {
            return [];
        }

        let availablePersonnel = personnel;

        if (clusterIds?.length) {
            availablePersonnel = personnel.filter((personnel) =>
                clusterIds?.every((clusterId) => personnel.clusters.some(({id}) => id === clusterId)),
            );
        }

        if (roleId) {
            availablePersonnel = availablePersonnel.filter((personnel) =>
                personnel.roles?.some((role) => roleToShiftRolesMapping[role].includes(roleId)),
            );
        }

        return transformToOptions(availablePersonnel, {
            label: ({firstName, lastName}) => `${firstName} ${lastName}`,
            value: 'id',
        });
    }, [personnel, roleId, clusterIds]);

    useEffect(() => {
        dispatch(getCommonData([CLUSTERS]));
        dispatch(getPersonnel());
    }, [dispatch]);

    return (
        <MHDialog
            onClose={onClose}
            dialogStyle={{paper: styles.dialog}}
            contentStyle={{root: styles.dialogContent}}
            open
            isCloseIcon
            title="Add new shift"
        >
            <form onSubmit={handleSubmit(onConfirm)}>
                <Grid container justifyContent="space-between" columnSpacing={2}>
                    <Grid item xs={6}>
                        <MHSelect
                            isClearable
                            rootClassName={styles.select}
                            label="Role"
                            showRequiredAsterisk
                            options={personnelRoleOptions}
                            value={personnelRoleOptions?.find(({value}) => value === roleId) || null}
                            onChangeHandler={(selected) => {
                                onRoleIdChange(selected?.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MHSelect
                            isClearable
                            rootClassName={styles.select}
                            label="Personnel"
                            showRequiredAsterisk
                            options={personnelOptions}
                            value={personnelOptions?.find(({value}) => value === personnelId) || null}
                            onChangeHandler={(selected) => {
                                onPersonnelIdChange(selected?.value);
                                const selectedItem = personnel.find(({id}) => id === selected?.value);
                                if (selectedItem) {
                                    onClustersIdsChange(selectedItem.clusters?.map(({id}) => id));
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MHSelect
                            isMulti
                            rootClassName={styles.select}
                            label="Cluster"
                            showRequiredAsterisk
                            options={clusterOptions}
                            value={clusterOptions?.filter(({value}) => clusterIds?.includes(value)) || null}
                            onChangeHandler={(selected) => {
                                const clusterIds = selected.map(({value}) => value);
                                onClustersIdsChange(clusterIds);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MHDateTimeField
                            label="Shift Start"
                            showRequiredAsterisk
                            value={start}
                            errorMessage={startFieldState.error?.message}
                            externalCalendarClassName={styles.calendar}
                            onChangeHandler={(value) => {
                                onStartChange(value);
                                if (end) {
                                    void trigger('shiftEnd');
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MHDateTimeField
                            label="Shift End"
                            showRequiredAsterisk
                            value={end}
                            errorMessage={endFieldState.error?.message}
                            externalCalendarClassName={styles.calendar}
                            onChangeHandler={(value) => {
                                onEndChange(value);
                                if (start) {
                                    void trigger('shiftStart');
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end" alignItems="center" gap="16px" marginTop="32px">
                    <MHRegularButton
                        text="Add"
                        loading={isLoading}
                        btnType="primary-positive"
                        type="submit"
                        disabled={!formState.isValid}
                    />
                    <MHRegularButton text="Cancel" btnType="secondary-positive" onClick={onClose} />
                </Grid>
            </form>
        </MHDialog>
    );
};
