import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {CreateShiftDTO, PersonnelWithRolesDTO, ShiftDTO} from '@/types/gatewayDataModels';

import {ShiftTrackerState} from './stateTypes';

const initialState: ShiftTrackerState = {
    shifts: [],
    isAddShiftModalActive: false,
    editedShiftId: null,
    personnel: [],
    loadingState: {
        areShiftsLoading: false,
        isShiftAddLoading: false,
        isShiftEndLoading: false,
    },
};

const SLICE_NAME = 'shiftTracker';

const {reducer, actions} = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        getShifts: (state) => {
            state.loadingState.areShiftsLoading = true;
        },
        getShiftsSuccess: (state, action: PayloadAction<ShiftDTO[]>) => {
            state.shifts = action.payload;
            state.loadingState.areShiftsLoading = false;
        },
        getShiftsFail: (state) => {
            state.loadingState.areShiftsLoading = false;
        },
        addShift: (state, _action: PayloadAction<{shift: CreateShiftDTO}>) => {
            state.loadingState.isShiftAddLoading = true;
        },
        addShiftSuccess: (state) => {
            state.loadingState.isShiftAddLoading = false;
            state.isAddShiftModalActive = false;
        },
        addShiftFail: (state) => {
            state.loadingState.isShiftAddLoading = false;
        },
        endShift: (state, _action: PayloadAction<{id: number; shiftEndReason: string}>) => {
            state.loadingState.isShiftEndLoading = true;
        },
        endShiftSuccess: (state) => {
            state.loadingState.isShiftEndLoading = false;
            state.editedShiftId = null;
        },
        endShiftFail: (state) => {
            state.loadingState.isShiftEndLoading = false;
        },
        showAddShiftModal: (state) => {
            state.isAddShiftModalActive = true;
        },
        hideAddShiftModal: (state) => {
            state.isAddShiftModalActive = false;
        },
        showEndShiftModal: (state, action: PayloadAction<{id: number}>) => {
            state.editedShiftId = action.payload.id;
        },
        hideEndShiftModal: (state) => {
            state.editedShiftId = null;
        },
        getPersonnel: (state) => state,
        getPersonnelSuccess: (state, action: PayloadAction<{personnel: PersonnelWithRolesDTO[]}>) => {
            state.personnel = action.payload.personnel;
        },
        getPersonnelFail: (state) => state,
        clearState: () => initialState,
    },
});

const {
    getShifts,
    getShiftsSuccess,
    getShiftsFail,
    showAddShiftModal,
    hideAddShiftModal,
    showEndShiftModal,
    hideEndShiftModal,
    addShiftSuccess,
    addShiftFail,
    addShift,
    clearState,
    endShiftSuccess,
    endShiftFail,
    endShift,
    getPersonnel,
    getPersonnelSuccess,
    getPersonnelFail,
} = actions;

export {
    addShift,
    addShiftFail,
    addShiftSuccess,
    clearState,
    endShift,
    endShiftFail,
    endShiftSuccess,
    getPersonnel,
    getPersonnelFail,
    getPersonnelSuccess,
    getShifts,
    getShiftsFail,
    getShiftsSuccess,
    hideAddShiftModal,
    hideEndShiftModal,
    reducer,
    showAddShiftModal,
    showEndShiftModal,
    SLICE_NAME,
};
