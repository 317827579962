import {useForm} from 'react-hook-form';
import {Grid} from '@mui/material';
import {parsePhoneNumberFromString} from 'libphonenumber-js';

import {MHRegularButton, MHTextInput} from '@/components/base';
import {outboundCall} from '@/services/awsConnectApiService';

import commonStyles from '../shared/common.module.scss';
import styles from './callToNumberInput.module.scss';

type CallToNumberInputProps = {
    onOutboundCall: (showCPPPanel: boolean) => void;
    outboundCallLogData: {
        patient_context: number;
    } | null;
};

export const CallToNumberInput = ({onOutboundCall, outboundCallLogData}: CallToNumberInputProps) => {
    const getPhoneNumber = (phone: string) => parsePhoneNumberFromString(phone, 'US');

    function validateAndSetPhone(phone: string) {
        const phoneNumber = getPhoneNumber(phone);

        // we validate the phone number and add a country code to it to be used for a call
        if (phoneNumber?.isValid()) {
            return true;
        }

        return 'Phone is not valid';
    }

    const {handleSubmit, reset, register, formState} = useForm({
        defaultValues: {phoneNumber: ''},
        mode: 'all',
    });

    const {isValid} = formState;

    const onSubmit = handleSubmit((values) => {
        // phoneToCall is used to have a valid phone number with country code that is added automatically
        const phoneNumber = getPhoneNumber(values.phoneNumber).number;
        outboundCall(phoneNumber, outboundCallLogData);
        onOutboundCall(false);
        // setTimeout is just for better UX
        setTimeout(() => {
            reset();
        }, 1000);
    });

    return (
        <Grid className={commonStyles.gridBorder} item>
            <form onSubmit={onSubmit}>
                <Grid container alignItems="center" spacing={3} className={styles.phoneNumberWrapper} flexWrap="nowrap">
                    <Grid item>
                        <MHTextInput
                            {...register('phoneNumber', {
                                validate: validateAndSetPhone,
                            })}
                            type="text"
                            placeholder="Phone number"
                        />
                    </Grid>
                    <Grid item>
                        <div className={styles.buttonWrapper}>
                            <MHRegularButton
                                btnType="primary-positive"
                                text="Call"
                                disabled={!isValid}
                                type="submit"
                                margin="10px"
                            />
                        </div>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
};
