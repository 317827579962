import {createContext, useContext, useEffect, useState} from 'react';
import createAuth0Client, {Auth0Client, RedirectLoginOptions, RedirectLoginResult} from '@auth0/auth0-spa-js';

import {getUserInitialData} from '@/redux/commonUserData/slice';
import {store} from '@/redux/store';
import {clearLocalStorage} from '@/utils/localStoreUtils';

import permissionsManager from '../../services/permissionsManager';
import awsConnectService from '../awsConnectApiService';
import {Auth0AppState, Auth0ContextValue, Auth0User, MHUser, WithAuth0AppState} from './types';

const Auth0Context = createContext<Auth0ContextValue>(undefined);
const useAuth0 = () => useContext(Auth0Context);

let jwtToken = '';

const Auth0Provider = ({
    children,
    onRedirectCallback,
    initOptions,
}: {
    children: React.ReactNode;
    onRedirectCallback: (state?: Auth0AppState) => void;
    initOptions: {
        max_age: string;
        audience: string;
        oidcClient: {clockTolerance: number};
        domain: string;
        client_id: string;
        leeway: number;
        redirect_uri: string;
    };
}) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [user, setUser] = useState<MHUser>();
    const [auth0Client, setAuth0] = useState<Auth0Client>();
    const [popupOpen] = useState(false);

    useEffect(() => {
        const initAuth0 = async () => {
            const auth0FromHook = await createAuth0Client(initOptions);
            setAuth0(auth0FromHook);

            if (window.location.search.includes('code=')) {
                // TODO: since auth0-spa-js version 1.19 you can pass this type as type param of handleRedirectCallback
                const result: WithAuth0AppState<RedirectLoginResult> = await auth0FromHook.handleRedirectCallback();
                const {appState} = result;
                onRedirectCallback(appState);
            }
            const isAuthenticated = await auth0FromHook.isAuthenticated();

            if (isAuthenticated) {
                const originalUser = await auth0FromHook.getUser<Auth0User>();

                const roles = originalUser['http://mhgi/roles'];
                delete originalUser['http://mhgi/roles'];

                const user: MHUser = {
                    ...originalUser,
                    roles,
                };

                void auth0FromHook.getTokenSilently().then((token) => {
                    jwtToken = token;

                    setIsAuthenticated(isAuthenticated);
                    store.dispatch(
                        getUserInitialData({
                            user,
                            tokenData: permissionsManager.getRoleSpecifics(token),
                        }),
                    );
                    setUser(user);
                });
            } else {
                clearLocalStorage();

                // TODO: since auth0-spa-js version 1.19 you can pass this type as type param of loginWithRedirect
                const options: WithAuth0AppState<RedirectLoginOptions> = {
                    appState: {targetUrl: '/' as const},
                };

                await auth0FromHook.loginWithRedirect(options);
            }
        };

        void initAuth0();
    }, [onRedirectCallback, initOptions]);

    return (
        <Auth0Context.Provider
            value={{
                isAuthenticated,
                user,
                popupOpen,
                checkIsAuthenticated: auth0Client?.isAuthenticated.bind(auth0Client),
                getIdTokenClaims: auth0Client?.getIdTokenClaims.bind(auth0Client),
                loginWithRedirect: auth0Client?.loginWithRedirect.bind(auth0Client),
                getTokenSilently: auth0Client?.getTokenSilently.bind(auth0Client),
                getTokenWithPopup: auth0Client?.getTokenWithPopup.bind(auth0Client),
                logout: auth0Client?.logout.bind(auth0Client),
            }}
        >
            {auth0Client && children}
        </Auth0Context.Provider>
    );
};

const handleFullLogout = (onFrameLoad: () => void) => {
    clearLocalStorage();

    if (awsConnectService.checkIfConnectionExists()) {
        awsConnectService.awsConnectLogout(onFrameLoad);
    } else {
        onFrameLoad?.();
    }
};

export {type Auth0AppState, Auth0Context, Auth0Provider, handleFullLogout, jwtToken, useAuth0};
