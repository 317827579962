import {ChangeEventHandler} from 'react';
import classnames from 'classnames';

import MHSwitchStyles from './mhSwitchStyles.module.scss';
import MHSwitchWithLabelsStyles from './mhSwitchWithLabels.module.scss';

const {
    thumbWrapper,
    switchWrapper,
    switchThumb,
    switchLabelChecked,
    switchThumbUnchecked,
    switchChecked,
    switchLabel,
    disabledLabel,
    disabledSwitch,
} = MHSwitchStyles;

type MHSwitchProps = {
    label?: string;
    checked: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
    disabled?: boolean;
    size?: number;
    labelClassName?: string;
    switchWrapperClassName?: string;
};

const MHSwitch = ({
    label,
    checked,
    onChange,
    disabled = false,
    size = 28,
    labelClassName = '',
    switchWrapperClassName = '',
}: MHSwitchProps) => {
    const labelId = label?.replaceAll(' ', '');
    return (
        <div className={thumbWrapper}>
            <label
                style={{width: `${2 * +size}px`, height: `${+size + 4}px`}}
                className={classnames(switchWrapper, switchWrapperClassName, {
                    [switchChecked]: checked,
                    [disabledSwitch]: disabled,
                })}
            >
                <input
                    type="checkbox"
                    onChange={onChange}
                    checked={checked}
                    disabled={disabled}
                    aria-labelledby={labelId}
                />
                <span
                    style={{
                        width: `${+size}px`,
                        height: `${+size}px`,
                        left: checked ? `calc(100% - ${+size + 2}px)` : '2px',
                    }}
                    className={classnames(switchThumb, {[switchThumbUnchecked]: !checked})}
                />
            </label>
            {label && (
                <span
                    className={classnames(switchLabel, labelClassName, {
                        [switchLabelChecked]: checked,
                        [disabledLabel]: disabled,
                    })}
                    id={labelId}
                >
                    {label}
                </span>
            )}
        </div>
    );
};

type MHSwitchWithLabelsProps = {
    checked: boolean;
    checkedLabel: string;
    unCheckedLabel: string;
    onChange(event: object): void;
    switchWrapperClassName?: string;
};

const MHSwitchWithLabels = ({
    checked,
    checkedLabel,
    unCheckedLabel,
    onChange,
    switchWrapperClassName,
}: MHSwitchWithLabelsProps) => (
    <div className={MHSwitchWithLabelsStyles.thumbWrapper}>
        <label className={classnames(MHSwitchWithLabelsStyles.switchWrapper, switchWrapperClassName)}>
            <input type="checkbox" onChange={onChange} checked={checked} />
            <span
                className={classnames(MHSwitchWithLabelsStyles.switchLabel, {
                    [MHSwitchWithLabelsStyles.switchChecked]: checked,
                })}
            >
                {checkedLabel}
            </span>
            <span
                className={classnames(MHSwitchWithLabelsStyles.switchLabel, {
                    [MHSwitchWithLabelsStyles.switchChecked]: !checked,
                })}
            >
                {unCheckedLabel}
            </span>
        </label>
    </div>
);

export {MHSwitch, MHSwitchWithLabels};
