import {defaultBgImage} from './defaultBgImage';

const canvasEl = document.createElement('canvas');
const webcamEl = document.createElement('video');
webcamEl.autoplay = true;
webcamEl.playsInline = true;
webcamEl.muted = true;
webcamEl.width = 1280;
webcamEl.height = 720;
canvasEl.width = 1280;
canvasEl.height = 720;

const tensorFlowBodyPixInit = async (type = 'blur', bgBase64 = '') => {
    console.info(type);

    onTensorFlowUnload();

    webcamEl.cancelVideoFrameCallback(window.videoFrameLoopId);

    window.segmentation = null;
    window.net = null;

    const bodyPix = window.bodyPix;

    const ctx = canvasEl.getContext('2d');

    const image = new Image();
    image.src = bgBase64 || defaultBgImage;
    image.style.transform = 'scaleY(-1)';

    // const bodyPixConfig = {
    //     architecture: 'MobileNetV1',
    //     outputStride: 8,
    //     quantBytes: 2,
    //     multiplier: 0.75,
    // };

    // const segmentationConfig = {
    //     flipHorizontal: false,
    //     internalResolution: 'low',
    // };

    const bodyPixConfig = {
        architecture: 'MobileNetV1',
        outputStride: 16,
        quantByte: 2,
        multiplier: 0.75,
    };

    const segmentationConfig = {
        flipHorizontal: false,
    };

    const streamWrapper = async (stream) => {
        webcamEl.cancelVideoFrameCallback(window.videoFrameLoopId);

        async function blurBg() {
            window.segmentation = null;
            window.net = null;

            webcamEl.cancelVideoFrameCallback(window.videoFrameLoopId);

            webcamEl.srcObject = stream;
            webcamEl.play();

            const backgroundBlurAmount = 10;
            const edgeBlurAmount = 1;

            window.net = await bodyPix.load(bodyPixConfig);

            async function renderLoop(now, metadata) {
                if (!metadata?.width || !metadata?.height) {
                    webcamEl.requestVideoFrameCallback(renderLoop);
                    return;
                }

                window.segmentation = await window.net.segmentPerson(webcamEl, segmentationConfig);

                bodyPix.drawBokehEffect(
                    canvasEl,
                    webcamEl,
                    window.segmentation,
                    backgroundBlurAmount,
                    edgeBlurAmount,
                    false,
                );
                window.videoFrameLoopId = webcamEl.requestVideoFrameCallback(renderLoop);
            }

            window.videoFrameLoopId = webcamEl.requestVideoFrameCallback(renderLoop);

            return canvasEl.captureStream(30);
        }

        async function customBg() {
            window.segmentation = null;
            window.net = null;

            webcamEl.cancelVideoFrameCallback(window.videoFrameLoopId);

            webcamEl.srcObject = stream;
            webcamEl.play();

            window.net = await bodyPix.load(bodyPixConfig);

            async function renderLoop(now, metadata) {
                if (!metadata?.width || !metadata?.height) {
                    webcamEl.requestVideoFrameCallback(renderLoop);
                    return;
                }

                window.segmentation = await window.net.segmentPerson(webcamEl, segmentationConfig);

                // 2. Creating a Background Mask
                const background = {r: 0, g: 0, b: 0, a: 0};
                const mask = bodyPix.toMask(window.segmentation, background, {r: 0, g: 0, b: 0, a: 255});

                if (mask) {
                    ctx.putImageData(mask, 0, 0);
                    ctx.globalCompositeOperation = 'source-in';

                    image.style.transform = `scaleY(-X)`;

                    // 3. Drawing the Background
                    ctx.drawImage(image, 0, 0, canvasEl.width, canvasEl.height);

                    // Draw the mask (segmentation)
                    ctx.globalCompositeOperation = 'destination-over';
                    ctx.drawImage(webcamEl, 0, 0, canvasEl.width, canvasEl.height);
                    ctx.globalCompositeOperation = 'source-over';

                    // Add a delay to control the frame rate (adjust as needed) less CPU intensive
                    // await new Promise((resolve) => setTimeout(resolve, 300));

                    // Continue updating the canvas
                    window.videoFrameLoopId = webcamEl.requestVideoFrameCallback(renderLoop);
                }
            }

            window.videoFrameLoopId = webcamEl.requestVideoFrameCallback(renderLoop);

            return canvasEl.captureStream(30);
        }

        if (type === 'bg_image') {
            return await customBg();
        }

        return await blurBg();
    };

    if (type === 'bg_image') {
        onTensorFlowUnload();
        return new Promise((resolve) => {
            image.onload = () => {
                resolve(streamWrapper);
            };
        });
    }

    return streamWrapper;
};

const onTensorFlowUnload = async () => {
    console.info('dispose');

    const tf = window.tf;
    await tf.dispose(window.segmentation);
    await tf.dispose(window.net);
};

export {onTensorFlowUnload, tensorFlowBodyPixInit};
