const updateLocalStorage = (email: string, selectedMissionControlId: number): void =>
    localStorage.setItem(
        'missionControls',
        JSON.stringify({
            ...(localStorage.missionControls ? JSON.parse(localStorage.missionControls) : {}),
            [email]: selectedMissionControlId,
        })
    );

const getMissionControlName = (name: string): string => `${name} M.C.`;

export {getMissionControlName, updateLocalStorage};
