import {BaseIconProps} from '@/assets/icons/types';

type MHLogoWhiteIconProps = BaseIconProps & {
    className?: string;
};

export const MHLogoWhiteIcon = ({height = '50px', viewBox = '0 0 157 50', className}: MHLogoWhiteIconProps) => (
    <svg height={height} viewBox={viewBox} className={className}>
        <defs>
            <linearGradient x1="2.08585859%" y1="97.9141414%" x2="97.1060606%" y2="2.89393939%" id="linearGradient-1">
                <stop stopColor="#2B3181" offset="0%" />
                <stop stopColor="#17BAB1" offset="36%" />
                <stop stopColor="#C6C32E" offset="71%" />
                <stop stopColor="#E6C937" offset="86%" />
                <stop stopColor="#FFCD3E" offset="100%" />
            </linearGradient>
            <linearGradient x1="-51.5724382%" y1="102.798251%" x2="114.637809%" y2="4.1198327%" id="linearGradient-2">
                <stop stopColor="#2AA7DF" offset="0%" />
                <stop stopColor="#17BAB1" offset="27%" />
                <stop stopColor="#3BBC96" offset="32%" />
                <stop stopColor="#76BF6A" offset="40%" />
                <stop stopColor="#A2C149" offset="47%" />
                <stop stopColor="#BCC235" offset="53%" />
                <stop stopColor="#C6C32E" offset="56%" />
                <stop stopColor="#DBC734" offset="69%" />
                <stop stopColor="#F5CB3B" offset="88%" />
                <stop stopColor="#FFCD3E" offset="100%" />
            </linearGradient>
            <linearGradient x1="-22.0386892%" y1="91.7914586%" x2="144.148043%" y2="-4.62356307%" id="linearGradient-3">
                <stop stopColor="#2AA7DF" offset="0%" />
                <stop stopColor="#17BAB1" offset="27%" />
                <stop stopColor="#28BBA9" offset="29%" />
                <stop stopColor="#73C283" offset="36%" />
                <stop stopColor="#AFC666" offset="43%" />
                <stop stopColor="#DBCA50" offset="49%" />
                <stop stopColor="#F5CC43" offset="54%" />
                <stop stopColor="#FFCD3E" offset="56%" />
                <stop stopColor="#FCCF3A" offset="67%" />
                <stop stopColor="#F5D330" offset="100%" />
            </linearGradient>
            <linearGradient x1="0%" y1="49.9791406%" x2="100%" y2="49.9791406%" id="linearGradient-4">
                <stop stopColor="#F9FBFC" stopOpacity="0" offset="0%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.01" offset="16%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.05" offset="29%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.12" offset="41%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.21" offset="53%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.34" offset="64%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.49" offset="74%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.66" offset="84%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.87" offset="94%" />
                <stop stopColor="#F9FBFC" offset="100%" />
            </linearGradient>
            <linearGradient x1="21.590106%" y1="30.9938943%" x2="98.8869258%" y2="62.0200777%" id="linearGradient-5">
                <stop stopColor="#F9FBFC" stopOpacity="0.25" offset="0%" />
                <stop stopColor="#F9FBFC" offset="100%" />
            </linearGradient>
            <linearGradient x1="-9.85865724%" y1="66.1295059%" x2="91.6784452%" y2="25.8827929%" id="linearGradient-6">
                <stop stopColor="#F9FBFC" stopOpacity="0" offset="0%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.04" offset="9%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.13" offset="23%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.29" offset="40%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.51" offset="61%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.79" offset="84%" />
                <stop stopColor="#F9FBFC" offset="100%" />
            </linearGradient>
            <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="linearGradient-7">
                <stop stopColor="#F9FBFC" stopOpacity="0" offset="0%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.04" offset="9%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.13" offset="23%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.29" offset="40%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.51" offset="61%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.79" offset="84%" />
                <stop stopColor="#F9FBFC" offset="100%" />
            </linearGradient>
            <linearGradient x1="50%" y1="100.943971%" x2="50%" y2="0.395858709%" id="linearGradient-8">
                <stop stopColor="#F9FBFC" stopOpacity="0" offset="0%" />
                <stop stopColor="#F9FBFC" offset="100%" />
            </linearGradient>
            <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="linearGradient-9">
                <stop stopColor="#F9FBFC" stopOpacity="0" offset="0%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.01" offset="23%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.04" offset="36%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.1" offset="48%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.18" offset="58%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.29" offset="67%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.41" offset="75%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.57" offset="83%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.74" offset="91%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.94" offset="98%" />
                <stop stopColor="#F9FBFC" offset="100%" />
            </linearGradient>
            <linearGradient x1="50%" y1="90.6666667%" x2="50%" y2="8.73737374%" id="linearGradient-10">
                <stop stopColor="#F9FBFC" stopOpacity="0" offset="0%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.01" offset="16%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.05" offset="29%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.12" offset="41%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.21" offset="53%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.34" offset="64%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.49" offset="74%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.66" offset="84%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.87" offset="94%" />
                <stop stopColor="#F9FBFC" offset="100%" />
            </linearGradient>
            <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="linearGradient-11">
                <stop stopColor="#F9FBFC" offset="0%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.91" offset="5%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.74" offset="16%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.59" offset="28%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.46" offset="41%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.37" offset="53%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.3" offset="67%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.26" offset="82%" />
                <stop stopColor="#F9FBFC" stopOpacity="0.25" offset="100%" />
            </linearGradient>
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="MH-Logo-White" transform="translate(0.000000, 0.000000)" fillRule="nonzero">
                <polygon
                    id="Path"
                    fill="#FFFFFF"
                    points="80.719697 23.2626263 77.7752525 23.2626263 77.7752525 12.1111111 74.1186869 21.8459596 71.0050505 21.8459596 67.3510101 12.1944444 67.3510101 23.2626263 64.4065657 23.2626263 64.4065657 8.48989899 68.7247475 8.48989899 72.5681818 18.4646465 76.3914141 8.48989899 80.709596 8.48989899"
                />
                <path
                    d="M92.936929,18.1414141 C92.9381024,18.4511289 92.9203926,18.7606293 92.8838384,19.0681818 L85.6313131,19.0681818 C85.9267677,20.5479798 86.959596,21.1919192 88.260101,21.1919192 C89.2093635,21.1715485 90.1225981,20.8246263 90.8459596,20.209596 L92.4621212,21.9772727 C91.3005051,23.0429293 89.8232323,23.5277778 88.1010101,23.5277778 C84.9646465,23.5277778 82.7979798,21.3964646 82.7979798,18.1742424 C82.7979798,14.9520202 84.9090909,12.7575758 87.9722222,12.7575758 C90.9090909,12.7651515 92.9267677,14.9520202 92.936929,18.1414141 Z M85.6414141,17.2020202 L90.1717172,17.2020202 C89.9191919,15.8939394 89.1262626,15.1540404 87.9419192,15.1540404 C86.6439394,15.1414141 85.8838384,15.9217172 85.6414141,17.2020202 Z"
                    id="Shape"
                    fill="#FFFFFF"
                />
                <path
                    d="M102.093434,14.1060606 L102.093434,8.06565657 L104.838384,8.06565657 L104.838384,23.2626263 L102.14899,23.2626263 L102.14899,22.1414141 C101.404473,23.0625604 100.269457,23.5790721 99.0858586,23.5353535 C96.2651515,23.5353535 94.1338384,21.2247475 94.1338384,18.1515152 C94.1338384,15.0782828 96.2651515,12.7651515 99.0858586,12.7651515 C100.242404,12.7198547 101.35413,13.2155107 102.093434,14.1060606 L102.093434,14.1060606 Z M96.9494949,18.1464646 C96.9494949,19.709596 97.9747475,20.9646465 99.5909091,20.9646465 C101.164141,20.9646465 102.229798,19.760101 102.229798,18.1464646 C102.229798,16.5328283 101.164141,15.3257576 99.5909091,15.3257576 C97.9747475,15.3308081 96.9494949,16.5883838 96.9494949,18.1515152 L96.9494949,18.1464646 Z"
                    id="Shape"
                    fill="#FFFFFF"
                />
                <path
                    d="M110.434343,9.69191919 C110.434343,10.6681801 109.642928,11.459596 108.666667,11.459596 C107.690406,11.459596 106.89899,10.6681801 106.89899,9.69191919 C106.89899,8.71565827 107.690406,7.92424242 108.666667,7.92424242 C109.138611,7.91378961 109.594325,8.09666785 109.928121,8.4304644 C110.261918,8.76426095 110.444796,9.21997533 110.434343,9.69191919 Z M110.065657,23.2626263 L107.308081,23.2626263 L107.308081,13.040404 L110.065657,13.040404 L110.065657,23.2626263 Z"
                    id="Shape"
                    fill="#FFFFFF"
                />
                <path
                    d="M121.44697,14.6969697 L119.462121,16.3560606 C118.838384,15.6893939 118.174242,15.3308081 117.277778,15.3308081 C115.85101,15.3308081 114.709596,16.4494949 114.709596,18.1515152 C114.709596,19.8535354 115.840909,20.969697 117.265152,20.969697 C118.122413,20.9599272 118.934312,20.5829412 119.494949,19.9343434 L121.457071,21.6136364 C120.470981,22.8696168 118.947133,23.5828079 117.35101,23.5353535 C114.088384,23.5353535 111.901515,21.2626263 111.901515,18.1515152 C111.901515,15.040404 114.088384,12.7651515 117.35101,12.7651515 C118.946492,12.7180213 120.468504,13.4358631 121.44697,14.6969697 L121.44697,14.6969697 Z"
                    id="Path"
                    fill="#FFFFFF"
                />
                <path
                    d="M132.883838,23.2626263 L130.191919,23.2626263 L130.191919,22.1414141 C129.447402,23.0625604 128.312386,23.5790721 127.128788,23.5353535 C124.310606,23.5353535 122.176768,21.2247475 122.176768,18.1515152 C122.176768,15.0782828 124.310606,12.7651515 127.128788,12.7651515 C128.314805,12.723263 129.450736,13.2448737 130.191919,14.1717172 L130.191919,13.040404 L132.883838,13.040404 L132.883838,23.2626263 Z M124.994949,18.1515152 C124.994949,19.7146465 126.020202,20.969697 127.636364,20.969697 C129.209596,20.969697 130.275253,19.7651515 130.275253,18.1515152 C130.275253,16.5378788 129.209596,15.3308081 127.636364,15.3308081 C126.020202,15.3308081 124.994949,16.5883838 124.994949,18.1515152 Z"
                    id="Shape"
                    fill="#FFFFFF"
                />
                <polygon
                    id="Path"
                    fill="#FFFFFF"
                    points="138.111111 23.2626263 135.353535 23.2626263 135.353535 8.06565657 138.111111 8.06565657"
                />
                <polygon
                    id="Path"
                    fill="#FFFFFF"
                    points="143.335859 23.2626263 140.580808 23.2626263 140.580808 8.06565657 143.335859 8.06565657"
                />
                <polygon
                    id="Path"
                    fill="#FFFFFF"
                    points="148.361111 27.5378788 145.330808 27.5378788 148.909091 21.0959596 144.497475 13.040404 147.623737 13.040404 150.431818 18.2979798 153.159091 13.040404 156.189394 13.040404"
                />
                <polygon
                    id="Path"
                    fill="#FFFFFF"
                    points="67.3510101 32.9873737 74.469697 32.9873737 74.469697 27.0328283 77.4166667 27.0328283 77.4166667 41.8156566 74.469697 41.8156566 74.469697 35.7323232 67.3510101 35.7323232 67.3510101 41.8156566 64.4065657 41.8156566 64.4065657 27.0328283 67.3510101 27.0328283"
                />
                <path
                    d="M90.5505051,36.6944444 C90.5505051,39.7777778 88.1969697,42.0808081 85.0075758,42.0808081 C81.8181818,42.0808081 79.4848485,39.7777778 79.4848485,36.6944444 C79.4848485,33.6111111 81.8282828,31.3080808 85.0075758,31.3080808 C88.1868687,31.3080808 90.5505051,33.6161616 90.5505051,36.6944444 Z M82.2929293,36.6944444 C82.2929293,38.3510101 83.4545455,39.5126263 85.0075758,39.5126263 C86.5606061,39.5126263 87.7323232,38.3510101 87.7323232,36.6944444 C87.7323232,35.0378788 86.5606061,33.8686869 85.0075758,33.8686869 C83.4545455,33.8686869 82.2929293,35.0353535 82.2929293,36.6944444 Z"
                    id="Shape"
                    fill="#FFFFFF"
                />
                <path
                    d="M108.967172,35.3005051 L108.967172,41.8156566 L106.212121,41.8156566 L106.212121,36.1868687 C106.212121,34.709596 105.454545,33.8964646 104.214646,33.8964646 C103.032828,33.8964646 102.050505,34.6540404 102.050505,36.209596 L102.050505,41.8156566 L99.2954545,41.8156566 L99.2954545,36.1868687 C99.2954545,34.709596 98.5555556,33.8964646 97.2979798,33.8964646 C96.1161616,33.8964646 95.1338384,34.6540404 95.1338384,36.209596 L95.1338384,41.8156566 L92.3787879,41.8156566 L92.3787879,31.5959596 L95.0606061,31.5959596 L95.0606061,32.8005051 C95.7878788,31.7121212 96.9090909,31.3207071 98.0707071,31.3207071 C99.4257625,31.2746371 100.70664,31.9390084 101.449495,33.0732323 C102.239899,31.7752525 103.580808,31.3207071 104.921717,31.3207071 C107.330808,31.3080808 108.967172,32.9242424 108.967172,35.3005051 Z"
                    id="Path"
                    fill="#FFFFFF"
                />
                <path
                    d="M120.856077,36.6843434 C120.856677,36.99481 120.839815,37.3050652 120.805556,37.6136364 L113.550505,37.6136364 C113.84596,39.0909091 114.881313,39.7348485 116.179293,39.7348485 C117.128555,39.7144778 118.04179,39.3675556 118.765152,38.7525253 L120.381313,40.520202 C119.219697,41.5858586 117.742424,42.0732323 116.020202,42.0732323 C112.883838,42.0732323 110.717172,39.9393939 110.717172,36.719697 C110.717172,33.5 112.828283,31.3005051 115.891414,31.3005051 C118.830808,31.3080808 120.835859,33.4949495 120.856077,36.6843434 Z M113.560606,35.7323232 L118.090909,35.7323232 C117.838384,34.4242424 117.045455,33.6843434 115.863636,33.6843434 C114.563131,33.6843434 113.80303,34.4671717 113.560606,35.7323232 Z"
                    id="Shape"
                    fill="#FFFFFF"
                />
                <path
                    d="M123.029119,34.3828914 C123.885338,34.3828914 124.585701,33.6845013 124.585701,32.8282828 C124.585701,31.9720644 123.885338,31.2736742 123.029119,31.2736742 C122.172901,31.2736742 121.474511,31.9720644 121.474511,32.8282828 C121.474511,33.6845013 122.172901,34.3828914 123.029119,34.3828914 Z M123.029119,34.079072 C122.328756,34.079072 121.77833,33.5286458 121.77833,32.8282828 C121.77833,32.1279198 122.328756,31.5774937 123.029119,31.5774937 C123.731455,31.5774937 124.281881,32.1279198 124.281881,32.8282828 C124.281881,33.5286458 123.731455,34.079072 123.029119,34.079072 Z M122.731218,33.7140941 C122.784485,33.7140941 122.812105,33.6864741 122.812105,33.6332071 L122.812105,33.0591067 C122.812105,33.0058396 122.839725,32.9782197 122.892992,32.9782197 L123.037011,32.9782197 C123.082386,32.9782197 123.115925,32.9999211 123.131708,33.041351 L123.380287,33.6509628 C123.398043,33.6904198 123.431581,33.7140941 123.474984,33.7140941 L123.666351,33.7140941 C123.727509,33.7140941 123.751184,33.6766098 123.727509,33.6213699 L123.461174,32.9979482 C123.439473,32.9505997 123.455256,32.9170612 123.496686,32.8874684 C123.63084,32.7967172 123.717645,32.6428346 123.717645,32.4613321 C123.717645,32.1732955 123.490767,31.9404987 123.196812,31.9404987 L122.557607,31.9404987 C122.50434,31.9404987 122.47672,31.9681187 122.47672,32.0213857 L122.47672,33.6332071 C122.47672,33.6864741 122.50434,33.7140941 122.557607,33.7140941 L122.731218,33.7140941 Z M123.147491,32.6901831 L122.892992,32.6901831 C122.839725,32.6901831 122.812105,32.6625631 122.812105,32.6092961 L122.812105,32.3192866 C122.812105,32.2679924 122.839725,32.2383996 122.892992,32.2383996 L123.147491,32.2383996 C123.28559,32.2383996 123.394097,32.3311237 123.394097,32.4672506 C123.394097,32.6053504 123.28559,32.6901831 123.147491,32.6901831 Z"
                    id="®"
                    fill="#FFFFFF"
                />
                <path
                    d="M0,0 L0,50 L50,50 L50,0 L0,0 Z M6.06060606,43.9393939 L6.06060606,6.06060606 L43.9469697,6.06060606 L43.9469697,43.9444444 L6.06060606,43.9393939 Z"
                    id="Shape"
                    fill="url(#linearGradient-1)"
                />
                <polygon
                    id="Path"
                    fill="url(#linearGradient-2)"
                    points="39.2929293 19.7171717 39.2929293 9.97474747 33.3838384 9.97474747 33.3838384 16.5580808 25 20.7979798 16.6161616 16.5580808 16.6161616 9.97474747 10.7070707 9.97474747 10.7070707 19.7171717 25 26.9469697"
                />
                <polygon
                    id="Path"
                    fill="url(#linearGradient-3)"
                    points="10.7070707 23.4368687 10.7070707 40.0227273 16.6161616 40.0227273 16.6161616 31.8434343 25 35.9419192 33.3838384 31.8434343 33.3838384 40.0227273 39.2954545 40.0227273 39.2954545 23.4368687 25 30.4242424"
                />
                <polygon
                    id="Path"
                    fill="url(#linearGradient-4)"
                    opacity="0.65"
                    points="43.9469697 6.0530303 6.0530303 6.0530303 0 1.79429984e-15 50 1.79429984e-15"
                />
                <g id="Group" opacity="0.65" transform="translate(10.707071, 16.558081)">
                    <polygon
                        id="Path"
                        fill="url(#linearGradient-5)"
                        points="5.90909091 -3.58859968e-15 14.2929293 4.23989899 14.2929293 10.3888889 1.79429984e-15 3.15909091"
                    />
                    <polygon
                        id="Path"
                        fill="url(#linearGradient-6)"
                        points="22.6767677 -3.58859968e-15 14.2929293 4.23989899 14.2929293 10.3888889 28.5858586 3.15909091"
                    />
                </g>
                <polygon
                    id="Path"
                    fill="url(#linearGradient-7)"
                    opacity="0.65"
                    points="33.3838384 31.8434343 25 35.9419192 25 30.4242424 39.2954545 23.4368687"
                />
                <polygon
                    id="Path"
                    fill="url(#linearGradient-8)"
                    opacity="0.65"
                    points="10.7070707 23.4368687 10.7070707 40.0227273 16.6161616 40.0227273 16.6161616 31.8434343"
                />
                <polygon
                    id="Path"
                    fill="url(#linearGradient-9)"
                    opacity="0.65"
                    points="16.6161616 31.8434343 25 35.9419192 25 30.4242424 10.7070707 23.4368687"
                />
                <polygon
                    id="Path"
                    fill="url(#linearGradient-10)"
                    opacity="0.65"
                    points="6.07575758 6.0530303 6.07575758 43.9444444 0.0202020202 50 0.0202020202 0"
                />
                <polygon
                    id="Path"
                    fill="url(#linearGradient-11)"
                    opacity="0.65"
                    points="6.0530303 43.9444444 43.9469697 43.9444444 50 50 0 50"
                />
            </g>
        </g>
    </svg>
);
