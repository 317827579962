import {AppStartListening} from '@/redux/store/listenerMiddleware';
import alarmService from '@/services/alarmService';

import {userIdSelector} from './selectors';
import {
    getUrgentAlerts,
    getUrgentAlertsCount,
    getUrgentAlertsCountFail,
    getUrgentAlertsCountSuccess,
    getUrgentAlertsFail,
    getUrgentAlertsSuccess,
} from './slice';

export const addFeatureListeners = (startListening: AppStartListening) => {
    startListening({
        actionCreator: getUrgentAlerts,
        effect: async (_, {dispatch, getState}) => {
            const userId = userIdSelector(getState());

            try {
                const alertData = await alarmService.getPatientIncidents(userId);
                dispatch(getUrgentAlertsSuccess(alertData));
            } catch (err) {
                dispatch(getUrgentAlertsFail());
            }
        },
    });

    startListening({
        actionCreator: getUrgentAlertsCount,
        effect: async (_, {dispatch, getState}) => {
            const userId = userIdSelector(getState());

            try {
                const alertsCount = await alarmService.getUrgentAlertsCount(userId);
                dispatch(getUrgentAlertsCountSuccess(alertsCount));
            } catch (err) {
                dispatch(getUrgentAlertsCountFail());
            }
        },
    });
};
