import {AppStartListening} from '@/redux/store/listenerMiddleware';
import shiftManagementService from '@/services/shiftManagementService';

import {
    addShift,
    addShiftFail,
    addShiftSuccess,
    endShift,
    endShiftFail,
    endShiftSuccess,
    getPersonnel,
    getPersonnelFail,
    getPersonnelSuccess,
    getShifts,
    getShiftsFail,
    getShiftsSuccess,
} from './slice';

export const addFeatureListeners = (startListening: AppStartListening) => {
    startListening({
        actionCreator: getShifts,
        effect: async (_, {dispatch}) => {
            try {
                const shifts = await shiftManagementService.getShifts();
                dispatch(getShiftsSuccess(shifts));
            } catch (err) {
                dispatch(getShiftsFail());
            }
        },
    });
    startListening({
        actionCreator: addShift,
        effect: async (action, {dispatch}) => {
            try {
                const {shift} = action.payload;
                await shiftManagementService.createShift(shift);
                dispatch(addShiftSuccess());
                dispatch(getShifts());
            } catch (err) {
                dispatch(addShiftFail());
            }
        },
    });
    startListening({
        actionCreator: endShift,
        effect: async (action, {dispatch}) => {
            try {
                const {id, shiftEndReason} = action.payload;
                await shiftManagementService.endShift(id, shiftEndReason);
                dispatch(endShiftSuccess());
                dispatch(getShifts());
            } catch (err) {
                dispatch(endShiftFail());
            }
        },
    });
    startListening({
        actionCreator: getPersonnel,
        effect: async (_, {dispatch}) => {
            try {
                const personnel = await shiftManagementService.getPersonnel(['Nurse', 'Service Coordinator']);
                dispatch(getPersonnelSuccess({personnel}));
            } catch (err) {
                dispatch(getPersonnelFail());
            }
        },
    });
};
