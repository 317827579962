export class LocalStorageManager<T extends Record<string | number, unknown>> {
    private readonly name: string;
    private readonly version: string;
    private readonly key: string;

    constructor(name: string, version: string) {
        this.name = name;
        this.version = version;
        this.key = `${name}-${version}`;

        try {
            const storedVersion = localStorage.getItem(this.name);
            if (storedVersion !== this.version) {
                localStorage.removeItem(`${name}-${storedVersion}`);
                localStorage.setItem(this.name, this.version);
            } else if (!storedVersion) {
                localStorage.setItem(this.name, this.version);
            }
        } catch (error) {
            throw new Error(`An error occurred while accessing local storage on storage setup: ${error}`);
        }
    }

    public setData(data: T): void {
        try {
            localStorage.setItem(this.key, JSON.stringify(data));
        } catch (error) {
            throw new Error(`An error occurred while setting data in local storage: ${error}`);
        }
    }

    public getData(): T | null {
        try {
            return JSON.parse(localStorage.getItem(this.key));
        } catch (error) {
            throw new Error(`An error occurred while getting data from local storage: ${error}`);
        }
    }

    public clearData(): void {
        try {
            localStorage.removeItem(this.key);
        } catch (error) {
            throw new Error(`An error occurred while clearing data in local storage: ${error}`);
        }
    }

    public setPartially(partialData: Partial<T>): void {
        try {
            const storedData = this.getData();
            const data = {...storedData, ...partialData};
            this.setData(data);
        } catch (error) {
            throw new Error(`An error occurred while setting partial data in local storage: ${error}`);
        }
    }
}
