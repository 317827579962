import {AppStartListening} from '@/redux/store/listenerMiddleware';
import performanceImprovementLogService from '@/services/performanceImprovementLogService';
import {LogCountDTO} from '@/types/performanceIssueTrackerDataModels';

import {
    getPerformanceImprovementLogCounter,
    getPerformanceImprovementLogCounterFail,
    getPerformanceImprovementLogCounterSuccess,
} from './slice';

const defaultCounterData: LogCountDTO = {
    totalCount: 0,
    newCount: 0,
    inReviewCount: 0,
    highestLevelOfImpact: null,
};

const addFeatureListeners = (startListening: AppStartListening) => {
    startListening({
        actionCreator: getPerformanceImprovementLogCounter,
        effect: async (_action, listenerApi) => {
            try {
                const data = await performanceImprovementLogService.getImprovementLogCounter();
                listenerApi.dispatch(getPerformanceImprovementLogCounterSuccess(data || defaultCounterData));
            } catch (err) {
                listenerApi.dispatch(getPerformanceImprovementLogCounterFail());
            }
        },
    });
};

export default addFeatureListeners;
