import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {PatientIncidentDTO} from '@/types/observationsModels';

import {UrgentAlertsState} from './stateTypes';

const initialState: UrgentAlertsState = {
    data: [],
    urgentAlertsCount: 0,
    loadingHandler: {
        getUrgentAlertsLoading: false,
        getUrgentAlertsCountLoading: false,
    },
};

const SLICE_NAME = 'urgentAlerts';
const {reducer, actions} = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        getUrgentAlerts: (state) => {
            state.loadingHandler.getUrgentAlertsLoading = true;
        },
        getUrgentAlertsSuccess: (state, action: PayloadAction<PatientIncidentDTO[]>) => {
            state.data = action.payload;
            state.loadingHandler.getUrgentAlertsLoading = false;
        },
        getUrgentAlertsFail: (state) => {
            state.loadingHandler.getUrgentAlertsLoading = false;
        },
        getUrgentAlertsCount: (state) => {
            state.loadingHandler.getUrgentAlertsCountLoading = true;
        },
        getUrgentAlertsCountSuccess: (state, action: PayloadAction<number>) => {
            state.urgentAlertsCount = action.payload;
            state.loadingHandler.getUrgentAlertsCountLoading = false;
        },
        getUrgentAlertsCountFail: (state) => {
            state.loadingHandler.getUrgentAlertsCountLoading = false;
        },
        setUrgentAlertsCount: (state, action: PayloadAction<number>) => {
            state.urgentAlertsCount = action.payload;
        },
    },
});

const {
    getUrgentAlerts,
    getUrgentAlertsCount,
    setUrgentAlertsCount,
    getUrgentAlertsCountFail,
    getUrgentAlertsCountSuccess,
    getUrgentAlertsSuccess,
    getUrgentAlertsFail,
} = actions;

export {
    getUrgentAlerts,
    getUrgentAlertsCount,
    getUrgentAlertsCountFail,
    getUrgentAlertsCountSuccess,
    getUrgentAlertsFail,
    getUrgentAlertsSuccess,
    reducer,
    setUrgentAlertsCount,
    SLICE_NAME,
};
