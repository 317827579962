import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ChangeMissionControlState} from './stateTypes';

const initialState: ChangeMissionControlState = {
    selectedMissionControlId: null,
    confirmModalData: null,

    loadingHandler: {getMissionControlsLoading: false},
};

const SLICE_NAME = 'changeMissionControlReducer';
const {reducer, actions} = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        getMissionControls: (state, _action: PayloadAction<{[key: string]: number}>) => {
            state.loadingHandler.getMissionControlsLoading = true;
        },
        getMissionControlsSuccess: (
            state,
            action: PayloadAction<{
                defaultMissionControlId: number;
                email: string;
                selectedMissionControls?: {[key: string]: number};
            }>
        ) => {
            const {selectedMissionControls, email, defaultMissionControlId} = action.payload;
            state.selectedMissionControlId = selectedMissionControls[email.toLowerCase()] || defaultMissionControlId;
            state.loadingHandler.getMissionControlsLoading = false;
        },
        getMissionControlsFail: (state) => {
            state.loadingHandler.getMissionControlsLoading = false;
        },
        setConfirmModalData: (state, action: PayloadAction<{id?: number; name?: string}>) => {
            state.confirmModalData = action.payload || null;
        },
        setSelectedMissionControlId: (state, action: PayloadAction<number>) => {
            state.selectedMissionControlId = action.payload;
        },
        clearMissionControlsReducerData: () => initialState,
    },
});

export const {
    getMissionControls,
    getMissionControlsSuccess,
    getMissionControlsFail,
    setConfirmModalData,
    setSelectedMissionControlId,
    clearMissionControlsReducerData,
} = actions;

export default reducer;
