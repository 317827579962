import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import get from 'lodash/get';

import {MHDialog} from '@/components/base';

import {getMissionControlName} from '../utils';

import styles from './confirmDialog.module.scss';

type ConfirmDialogProps = {
    setNewMissionControl: () => void;
    handleConfirmDialogToggle: (missionControl: {id?: number; name?: string} | null) => void;
    confirmModalData: {id?: number; name?: string};
};

export const ConfirmDialog = ({
    setNewMissionControl,
    handleConfirmDialogToggle,
    confirmModalData,
}: ConfirmDialogProps) => (
    <MHDialog
        onClose={() => handleConfirmDialogToggle(null)}
        onSubmit={setNewMissionControl}
        open
        dialogStyle={{
            paper: styles.confirmDialogContainer,
        }}
        dialogActionsStyles={{root: styles.confirmDialogActions}}
        title="Confirmation"
        submitButtonText="Confirm"
        cancelButtonText="Back"
        showControls
    >
        <>
            <div className={styles.dialogQuestion}>Are you sure you want to switch to another mission control</div>
            <div className={styles.dialogMission}>
                <BusinessOutlinedIcon />
                {getMissionControlName(get(confirmModalData, 'name', ''))}
            </div>
        </>
    </MHDialog>
);
