export const loadScript = (src: string) => {
    return new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        console.info(`Loading script: ${src}`);
        script.onload = () => {
            console.info(`${src} loaded successfully`);
            resolve();
        };
        script.onerror = () => {
            console.error(`Error loading script: ${src}`);
            reject();
        };
        document.head.appendChild(script);
    });
};
