import {AppStartListening} from '@/redux/store/listenerMiddleware';
import {loggerService} from '@/services/loggerService';

import {errorBoundarySelector} from './selectors';
import {logError} from './slice';

const addFeatureListeners = (startListening: AppStartListening) => {
    startListening({
        actionCreator: logError,
        effect: (action, listenerApi) => {
            try {
                const {actionName, message} = action.payload;
                const email = errorBoundarySelector(listenerApi.getState());

                loggerService.logEvent({
                    severity: 'Critical',
                    email: email,
                    action: actionName,
                    ...(message ? {message} : {}),
                });
            } catch (err) {
                console.error(err);
            }
        },
    });
};

export default addFeatureListeners;
