import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {CheckoutDataResponseDTO} from '@/types/gatewayDataModels';

import {UndoCheckInCheckOutDialogState} from './stateTypes';

const initialState: UndoCheckInCheckOutDialogState = {
    activities: [],
    appointment: {},
    hasAdditionalActivities: false,
    performer: {},
    loadingHandler: {appointmentLoading: false, undoAppointmentLoading: false},
};

type UndoProps = {
    comment?: string;
    id: number;
    onActionSuccess?: () => void;
};

const SLICE_NAME = 'undoCheckInCheckOut';
const UndoCheckInCheckOutSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        getAppointment: (state, _action: PayloadAction<number>) => {
            state.loadingHandler.appointmentLoading = true;
        },
        getAppointmentSuccess: (state, action: PayloadAction<CheckoutDataResponseDTO>) => {
            Object.assign(state, {
                loadingHandler: {...state.loadingHandler, appointmentLoading: false},
                ...action.payload,
            });
        },
        getAppointmentFail: (state) => {
            state.loadingHandler.appointmentLoading = false;
        },
        undoCheckIn: (state, _action: PayloadAction<UndoProps>) => {
            state.loadingHandler.undoAppointmentLoading = true;
        },
        undoCheckInSuccess: (state) => {
            state.loadingHandler.undoAppointmentLoading = false;
        },
        undoCheckInFail: (state) => {
            state.loadingHandler.undoAppointmentLoading = false;
        },
        undoCheckOut: (state, _action: PayloadAction<UndoProps>) => {
            state.loadingHandler.undoAppointmentLoading = true;
        },
        undoCheckOutSuccess: (state) => {
            state.loadingHandler.undoAppointmentLoading = false;
        },
        undoCheckOutFail: (state) => {
            state.loadingHandler.undoAppointmentLoading = false;
        },
        undoConfirm: (state, _action: PayloadAction<UndoProps>) => {
            state.loadingHandler.undoAppointmentLoading = true;
        },
        undoConfirmSuccess: (state) => {
            state.loadingHandler.undoAppointmentLoading = false;
        },
        undoConfirmFail: (state) => {
            state.loadingHandler.undoAppointmentLoading = false;
        },
        clearState: () => initialState,
    },
});
const {reducer, actions} = UndoCheckInCheckOutSlice;

export const {
    getAppointment,
    getAppointmentSuccess,
    getAppointmentFail,
    undoCheckIn,
    undoCheckInSuccess,
    undoCheckInFail,
    undoCheckOut,
    undoCheckOutSuccess,
    undoCheckOutFail,
    undoConfirm,
    undoConfirmSuccess,
    undoConfirmFail,
    clearState,
} = actions;

export default reducer;
