import {AppStartListening} from '@/redux/store/listenerMiddleware';
import educationDocumentsService from '@/services/educationDocumentsService';

import {
    getEducationDocumentHistory,
    getEducationDocumentHistoryFail,
    getEducationDocumentHistorySuccess,
    getEducationMedia,
    getEducationMediaFail,
    getEducationMediaSuccess,
} from './slice';

const addFeatureListeners = (startListening: AppStartListening) => {
    startListening({
        actionCreator: getEducationDocumentHistory,
        effect: async (action, listenerApi) => {
            try {
                const response = await educationDocumentsService.getEducationMaterialFullHistory({
                    id: action.payload.id,
                });
                listenerApi.dispatch(getEducationDocumentHistorySuccess(response));
            } catch (err) {
                listenerApi.dispatch(getEducationDocumentHistoryFail());
            }
        },
    });
    startListening({
        actionCreator: getEducationMedia,
        effect: async (action, {dispatch}) => {
            const {fileName, cb} = action.payload;
            try {
                const response = await educationDocumentsService.getEducationMedia(fileName);
                dispatch(getEducationMediaSuccess());

                if (cb && response) {
                    cb(URL.createObjectURL(response));
                }
            } catch (error) {
                dispatch(getEducationMediaFail());
            }
        },
    });
};

export default addFeatureListeners;
