import {colors} from '@mui/material';

import colorConstants from '../styles/colors_styleguide.module.scss';

const white = colorConstants.white;
const black = colorConstants.black_100;
const greyBg = colorConstants.black_5;

const headersColor = '#38414a';
const headersLightGrey = '#61686F';
const thresholdWarning = colorConstants.red_100;

// Side Menu
const menuBg = colorConstants.black_5;
const menuIconColor = colorConstants.black_60;
const menuIconActiveColor = colorConstants.navyBlue_60;

export default {
    black,
    white,
    greyBg,
    headersColor,
    headersLightGrey,
    thresholdWarning,
    menuBg,
    menuIconColor,
    menuIconActiveColor,
    primary: {
        contrastText: white,
        dark: colorConstants.teal_100,
        main: colorConstants.teal_100,
        light: colorConstants.teal_5,
    },
    secondary: {
        contrastText: white,
        dark: colors.blue[900],
        main: colorConstants.teal_100,
        light: '#D9E9E9',
    },

    success: {
        contrastText: white,
        dark: colorConstants.teal_100,
        main: colorConstants.teal_100,
        light: colorConstants.teal_10,
    },
    info: {
        contrastText: white,
        dark: colorConstants.navyBlue_100,
        main: colorConstants.navyBlue_100,
        light: colorConstants.navyBlue_60,
    },
    warning: {
        contrastText: white,
        dark: colorConstants.orange_100,
        main: colorConstants.orange_100,
        light: colorConstants.orange_10,
    },
    error: {
        contrastText: white,
        dark: colorConstants.red_100,
        main: colorConstants.red_100,
        light: colorConstants.red_10,
    },
    text: {
        primary: colorConstants.black_100,
        secondary: colorConstants.black_80,
        link: colorConstants.teal_100,
    },
    background: {
        default: colorConstants.white,
    },
    icon: colors.blueGrey[600],
    divider: colorConstants.black_80,
    lightBlue: {
        dark: '#636e7a',
        main: '#adc3db',
        light: '#f5f9ff',
    },
};
