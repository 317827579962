import {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {MenuItem} from '@mui/material';

import {MHButton, MHLoader, MHTypography} from '@/components/base';

import {addShiftModalSelector, endShiftModalSelector, hasShiftsModifyAccess, shiftsSelector} from '../redux/selectors';
import {
    addShift,
    clearState,
    endShift,
    getShifts,
    hideAddShiftModal,
    hideEndShiftModal,
    showAddShiftModal,
    showEndShiftModal,
} from '../redux/slice';
import {convertDateTimeToUTC} from '../utils';
import {AddShiftFormTypes, AddShiftModal} from './AddShiftModal';
import {EndShiftFormTypes, EndShiftModal} from './EndShiftModal';
import {ShiftList} from './ShiftList';

import styles from '../../shared/common.module.scss';
import shiftMenuStyles from './shiftMenu.module.scss';

type ShiftMenuProps = {
    toggleRowExpand: (shiftId: number) => void;
    rowExpandedStateById: Record<string, boolean>;
    handleClose: () => void;
};

export const ShiftMenu = ({handleClose, toggleRowExpand, rowExpandedStateById}: ShiftMenuProps) => {
    const dispatch = useDispatch();
    const {shifts, areShiftsLoading} = useSelector(shiftsSelector, shallowEqual);
    const {isAddShiftModalActive, isShiftAddLoading} = useSelector(addShiftModalSelector, shallowEqual);
    const {isShiftEndLoading, editedShiftId} = useSelector(endShiftModalSelector, shallowEqual);
    const canModifyShifts = useSelector(hasShiftsModifyAccess, shallowEqual);

    const openAddShiftModal = () => {
        dispatch(showAddShiftModal());
    };

    const openEndShiftModal = (id: number) => {
        dispatch(showEndShiftModal({id}));
    };

    const handleAddShift = (shift: AddShiftFormTypes) => {
        dispatch(
            addShift({
                shift: {
                    ...shift,
                    shiftStart: convertDateTimeToUTC(shift.shiftStart),
                    shiftEnd: convertDateTimeToUTC(shift.shiftEnd),
                },
            }),
        );
    };

    const handleEndShift = (shiftId: number, {shiftEndReason}: EndShiftFormTypes) => {
        dispatch(endShift({shiftEndReason, id: shiftId}));
    };

    useEffect(() => {
        dispatch(getShifts());

        return () => {
            dispatch(clearState());
        };
    }, [dispatch]);

    return (
        <>
            {isAddShiftModalActive && (
                <AddShiftModal
                    onClose={() => dispatch(hideAddShiftModal())}
                    onConfirm={handleAddShift}
                    isLoading={isShiftAddLoading}
                />
            )}
            {editedShiftId && (
                <EndShiftModal
                    onClose={() => dispatch(hideEndShiftModal())}
                    isLoading={isShiftEndLoading}
                    onConfirm={(formValues) => handleEndShift(editedShiftId, formValues)}
                />
            )}
            <MenuItem classes={{root: styles.menuItem}} onClick={handleClose}>
                <div className={styles.listTitle}>
                    <MHTypography color="white" variant="headline5">
                        Shifts
                    </MHTypography>
                </div>
            </MenuItem>
            <div className={shiftMenuStyles.shiftsWrapper}>
                <div className={shiftMenuStyles.scrollableDiv}>
                    {areShiftsLoading ? (
                        <div className={shiftMenuStyles.loaderWrapper}>
                            <MHLoader />
                        </div>
                    ) : (
                        <ShiftList
                            shifts={shifts}
                            rowExpandedStateById={rowExpandedStateById}
                            toggleRowExpand={toggleRowExpand}
                            openEndShiftModal={openEndShiftModal}
                            canModifyShifts={canModifyShifts}
                        />
                    )}
                </div>
                {canModifyShifts && (
                    <div className={shiftMenuStyles.actionButtonWrapper}>
                        <MHButton
                            className={shiftMenuStyles.addShiftBtn}
                            text="Add shift"
                            onClickHandler={openAddShiftModal}
                            buttonType="primary"
                        />
                    </div>
                )}
            </div>
        </>
    );
};
