import {AppStartListening} from '@/redux/store/listenerMiddleware';
import callRouterService from '@/services/callRouter';

import {activeMeetingsSelector, userIdSelector} from './selectors';
import {
    getActiveMeetings,
    getActiveMeetingsCount,
    getActiveMeetingsCountFail,
    getActiveMeetingsCountSuccess,
    getActiveMeetingsFail,
    getActiveMeetingsSuccess,
    markSeenCalls,
    markSeenCallsFail,
    markSeenCallsSuccess,
} from './slice';

export const addFeatureListeners = (startListening: AppStartListening) => {
    startListening({
        actionCreator: getActiveMeetingsCount,
        effect: async (_, {dispatch}) => {
            try {
                const {unviewedMissedCallsCount} = await callRouterService.missedCallsUnviewedCount();
                dispatch(getActiveMeetingsCountSuccess(unviewedMissedCallsCount));
            } catch (err) {
                dispatch(getActiveMeetingsCountFail());
            }
        },
    });

    startListening({
        actionCreator: getActiveMeetings,
        effect: async (_, {dispatch, getState}) => {
            const userId = userIdSelector(getState());

            try {
                const activeMeetings = await callRouterService.getActiveMeetings(userId);
                dispatch(getActiveMeetingsSuccess(activeMeetings));
            } catch (err) {
                dispatch(getActiveMeetingsFail());
            }
        },
    });

    startListening({
        actionCreator: markSeenCalls,
        effect: async (_, {dispatch, getState}) => {
            try {
                const {data} = activeMeetingsSelector(getState());
                const callIds = data.patientMissedCalls.reduce((acc, {missedCalls}) => {
                    const patientIds = missedCalls.reduce(
                        (ids, {viewed, missedCallIds}) => (viewed ? ids : [...ids, ...missedCallIds]),
                        []
                    );
                    return patientIds.length ? [...acc, ...patientIds] : acc;
                }, []);
                await callRouterService.markSeenCalls({missedCallIds: callIds});
                dispatch(markSeenCallsSuccess());
            } catch (err) {
                dispatch(markSeenCallsFail());
            }
        },
    });
};
