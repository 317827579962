import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ChangePartnerData, ConfirmPartnerChangeDialogState} from './stateTypes';

const initialState: ConfirmPartnerChangeDialogState = {
    loadingHandler: {
        changePartnerLoading: false,
    },
};

const SLICE_NAME = 'confirmPartnerChangeDialog';

const ConfirmPartnerChangeDialogSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        changePartner: (state, _action: PayloadAction<ChangePartnerData>) => {
            state.loadingHandler.changePartnerLoading = true;
        },
        changePartnerSuccess: (state) => {
            state.loadingHandler.changePartnerLoading = false;
        },
        changePartnerFail: (state) => {
            state.loadingHandler.changePartnerLoading = false;
        },
        clearState: () => initialState,
    },
});

const {reducer, actions} = ConfirmPartnerChangeDialogSlice;

export const {changePartner, changePartnerSuccess, changePartnerFail, clearState} = actions;

export default reducer;
