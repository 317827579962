import {useCallback, useEffect, useRef, useState} from 'react';
import {datadogLogs} from '@datadog/browser-logs';

import {onTensorFlowUnload} from '@/components/general/MHHeader/VideoCall/utils/tensorFlowBodyPix';
import {VideoCallWidget} from '@/components/general/MHHeader/VideoCall/VideoCallWidget';
import {useInitializeDatadogRum} from '@/services/dataDogRum';
import {MEETING_ID_KEY, VIDEO_CALL_USER_KEY} from '@/services/videoService';

import {loadScript} from './loadScript';

import 'vidyoclient-nativewebrtc-sdk/dist/VidyoClient.css';

declare global {
    interface Window {
        tf: any;
        banubaIsLoaded: boolean;
    }
}

const jquerySrc = '/jquery-3.1.1.min.js';
const tfBeSrc = '/tensorflow-tf-backend-wasm.js';
const tfSrc = '/tensorflow-tfjs';
const posenetSrc = '/posenet';
const bodyPixSrc = '/body-pix-2.0.js';
const DELAY_BEFORE_CLOSE = 3200; // ms

let beforeUnloadHandled = false;

const maximizeDatadogLogsBeforeClosingVideoTab = () => {
    if (beforeUnloadHandled) {
        return;
    }
    beforeUnloadHandled = true;
    datadogLogs.logger.info('Shutting down VideoCall screen');
};

window.addEventListener('beforeunload', maximizeDatadogLogsBeforeClosingVideoTab);

export const VideoCall = () => {
    const [isReady, setIsReady] = useState(false);
    const endCallRef = useRef<ReturnType<typeof setTimeout>>(null);
    const meetingId = sessionStorage.getItem(MEETING_ID_KEY);
    const userJson = sessionStorage.getItem(VIDEO_CALL_USER_KEY);
    const user = JSON.parse(userJson) as {
        firstName: string;
        lastName: string;
        id: number;
    };

    useInitializeDatadogRum();

    useEffect(() => {
        const fn = async () => {
            if (window?.['VIDYO_IS_BANUBA_ENABLED'] === true) {
                await new Promise<void>((resolve) => {
                    window.addEventListener('load', () => {
                        import('../../assets/banuba/BanubaPlugin')
                            .then((banubaPlugin) => {
                                banubaPlugin.initBanubaPlugin();
                                window['banubaIsLoaded'] = true;
                                resolve();

                                setTimeout(() => {
                                    setIsReady(true);
                                }, 50);
                            })
                            .catch((error) => {
                                console.error('Failed to load Banuba plugin', error);
                                resolve();
                            });
                    });
                });
                return;
            }

            await Promise.all([
                (async () => {
                    await loadScript(jquerySrc);
                })(),
                (async () => {
                    await loadScript(tfSrc),
                        await Promise.all([loadScript(tfBeSrc), loadScript(posenetSrc), loadScript(bodyPixSrc)]);
                })(),
            ]);

            if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
                await window.tf.setBackend('webgl');
            } else {
                console.error('API not supported');
            }

            setIsReady(true);
        };

        fn();

        return () => {
            if (window?.['VIDYO_IS_BANUBA_ENABLED'] !== true) {
                onTensorFlowUnload();
            }
        };
    }, []);

    const handleDisconnect = useCallback(() => {
        if (!('vidyoConnector' in window)) {
            return;
        }

        window?.vidyoConnector?.Disconnect?.();
        window?.vidyoConnector?.SetCameraPrivacy?.({
            privacy: true,
        });
        window?.vidyoConnector?.SetMicrophonePrivacy?.({
            privacy: true,
        });
    }, []);

    const handleEndCall = useCallback(() => {
        sessionStorage.removeItem(MEETING_ID_KEY);
        sessionStorage.removeItem(VIDEO_CALL_USER_KEY);

        clearTimeout(endCallRef.current);

        handleDisconnect();

        endCallRef.current = setTimeout(() => {
            window.close?.();
        }, DELAY_BEFORE_CLOSE);
    }, [handleDisconnect]);

    return (
        <>
            {isReady && (
                <VideoCallWidget
                    currentMeetingId={meetingId}
                    onClose={handleEndCall}
                    onDisconnect={handleDisconnect}
                    user={{
                        firstName: user.firstName,
                        lastName: user?.lastName,
                        id: user?.id,
                    }}
                />
            )}
        </>
    );
};
