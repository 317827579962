import type {TypedStartListening} from '@reduxjs/toolkit';
import {createListenerMiddleware} from '@reduxjs/toolkit';

import {connectRootListeners} from '@/redux/rootListeners';

import type {AppDispatch} from './';
import type {StoreStateShape} from './storeStateTypes';

const listenerMiddleware = createListenerMiddleware();
type AppStartListening = TypedStartListening<StoreStateShape, AppDispatch>;
const startAppListening = listenerMiddleware.startListening as AppStartListening;

connectRootListeners(startAppListening);

export {type AppStartListening, listenerMiddleware, startAppListening};
