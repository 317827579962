import urls from '@/constants/endpoints/gateway';
import {UnviewedMissedCallsCountDTO} from '@/types/gatewayDataModels';
import {AcknowledgeMissedCallsRequestDTO, ActiveMeetingsDTO} from '@/types/observationsModels';
import {PhoneBlackListDTO, PhoneWhiteListDTO} from '@/types/telephonyDataModels';

import httpApi from './httpApi_new';

class CallRouterService {
    missedCallsUnviewedCount = (): Promise<UnviewedMissedCallsCountDTO> =>
        httpApi.get({
            url: urls.MISSED_CALLS_UNVIEWED_COUNT,
        });
    getPhoneWhiteList = (): Promise<PhoneWhiteListDTO[]> =>
        httpApi.get({
            url: urls.PHONE_WHITE_LIST,
        });
    getPhoneBlackList = (): Promise<PhoneBlackListDTO[]> =>
        httpApi.get({
            url: urls.PHONE_BLACK_LIST,
        });
    addPhoneToWhitelist = (data: PhoneWhiteListDTO): Promise<PhoneWhiteListDTO> =>
        httpApi.post({
            url: urls.PHONE_WHITE_LIST,
            data,
        });
    addPhoneToBlacklist = (data: PhoneBlackListDTO): Promise<PhoneBlackListDTO> =>
        httpApi.post({
            url: urls.PHONE_BLACK_LIST,
            data,
        });
    updatePhoneToWhitelist = (phone: string, data: PhoneWhiteListDTO): Promise<PhoneWhiteListDTO> =>
        httpApi.put({
            url: urls.UPDATE_PHONE_WHITE_LIST(phone),
            data,
        });
    updatePhoneToBlacklist = (phone: string, data: PhoneBlackListDTO): Promise<PhoneBlackListDTO> =>
        httpApi.put({
            url: urls.UPDATE_PHONE_BLACK_LIST(phone),
            data,
        });
    deletePhoneToWhitelist = (phone: string) =>
        httpApi.delete({
            url: urls.UPDATE_PHONE_WHITE_LIST(phone),
        });
    deletePhoneToBlacklist = (phone: string) =>
        httpApi.delete({
            url: urls.UPDATE_PHONE_BLACK_LIST(phone),
        });
    getActiveMeetings = (nurseId: number): Promise<ActiveMeetingsDTO> =>
        httpApi.get({
            url: urls.PATIENT_ACTIVE_MEETING(nurseId),
        });
    markSeenCalls = (data: AcknowledgeMissedCallsRequestDTO) =>
        httpApi.put({
            url: urls.MARK_SEEN_CALLS,
            data,
        });
}

const callRouterService = new CallRouterService();
export default callRouterService;
