import urls from '@/constants/endpoints/gateway';
import {
    EducationDocumentPackageDTO,
    EducationDocumentPackageHistoryDTO,
    SimpleMessageResponseDTO,
} from '@/types/gatewayDataModels';

import httpApi from './httpApi_new';

class EducationDocumentsService {
    getEducationDocumentTypes = (): Promise<('Program Information' | 'Patient Education')[]> =>
        httpApi.get({url: urls.EDUCATION_DOCUMENTS_TYPES});

    createEducationDocument = (data: FormData): Promise<EducationDocumentPackageDTO> =>
        httpApi.post({url: urls.EDUCATION_DOCUMENTS, data});

    editEducationDocument = (data: FormData, id: number): Promise<EducationDocumentPackageDTO> =>
        httpApi.put({url: `${urls.EDUCATION_DOCUMENTS}/${id}`, data});

    getEducationDocuments = (queryParams: {
        sponsor?: string;
        product?: string;
        archived?: boolean;
        titlePart?: string;
        languageCode?: string;
    }): Promise<EducationDocumentPackageDTO[]> => httpApi.get({url: urls.EDUCATION_DOCUMENTS, queryParams});

    getEducationDocument = (id: number): Promise<EducationDocumentPackageDTO> =>
        httpApi.get({url: urls.EDUCATION_DOCUMENT(id)});

    getEducationMaterialFullHistory = ({id}: {id: number}): Promise<EducationDocumentPackageHistoryDTO[]> =>
        httpApi.get({
            url: urls.EDUCATION_DOCUMENT_HISTORY(id),
        });

    getEducationMedia = (fileName: string) =>
        httpApi.get({
            url: urls.EDUCATION_DOCUMENT_FILE,
            queryParams: {fileName},
            customHandler: (response) => response.blob(),
        });

    changeDocumentOrder = (id: number, newPosition: number): Promise<SimpleMessageResponseDTO> =>
        httpApi.put({url: urls.EDUCATION_DOCUMENT_CHANGE_ORDER(id), queryParams: {newPosition}});

    archiveDocument = (id: number): Promise<SimpleMessageResponseDTO> =>
        httpApi.patch({url: urls.EDUCATION_DOCUMENT_ARCHIVE(id)});

    restoreDocument = (id: number): Promise<SimpleMessageResponseDTO> =>
        httpApi.patch({url: urls.EDUCATION_DOCUMENT_RESTORE(id)});
}

const educationDocumentsService = new EducationDocumentsService();
export default educationDocumentsService;
