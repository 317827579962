import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {CallReducerState} from './stateTypes';
import {ActiveCall} from './types';

const initialState: CallReducerState = {
    activeCalls: [],
    activeVideoCallsList: [],
    showVideoCallWidget: false,
    currentCallMeetingId: null,
    currentCallPatientId: null,
};

const SLICE_NAME = 'callReducer';
const {actions, reducer} = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        showCallNotification: (state, action: PayloadAction<ActiveCall>) => {
            const isActiveCallExist = state.activeCalls.some(({meetingId}) => meetingId === action.payload.meetingId);

            if (isActiveCallExist) {
                return;
            }

            state.activeCalls = [...state.activeCalls, action.payload];
        },
        hideCallNotification: (state, action: PayloadAction<string>) => {
            state.activeCalls = state.activeCalls.length
                ? state.activeCalls.filter(({meetingId}) => meetingId !== action.payload)
                : [];
        },
        updateVideoNotifications: (state, action: PayloadAction<ActiveCall[]>) => {
            const safePayload = action.payload ? action.payload : [];
            const activeAwsCall = state.activeCalls.length
                ? state.activeCalls.find(({meetingId}) => meetingId === 'awsCall')
                : null;

            state.activeCalls = activeAwsCall ? [...safePayload, activeAwsCall] : safePayload;
        },
        updateVideoCallsList: (state, action: PayloadAction<ActiveCall[]>) => {
            state.activeVideoCallsList = action.payload;
        },
        clearReducerData: () => initialState,
        openVideoCallWidget: (
            state,
            action: PayloadAction<{
                meetingId: string;
                patientId?: number;
            }>
        ) => {
            state.showVideoCallWidget = true;
            state.currentCallMeetingId = action.payload.meetingId;
            state.currentCallPatientId = action.payload?.patientId;
        },
        closeVideoCallWidget: (state) => {
            state.showVideoCallWidget = false;
            state.currentCallMeetingId = null;
            state.currentCallPatientId = null;
        },
        hideVideoCallWidget: (state) => {
            state.showVideoCallWidget = false;
        },
        showVideoCallWidget: (state) => {
            state.showVideoCallWidget = true;
        },
    },
});

export const {
    showCallNotification,
    hideCallNotification,
    updateVideoNotifications,
    clearReducerData,
    updateVideoCallsList,
    openVideoCallWidget,
    closeVideoCallWidget,
    hideVideoCallWidget,
    showVideoCallWidget,
} = actions;

export default reducer;
