import palette from '../palette';

export default {
    root: {
        color: palette.primary.main,
        '&:hover': {
            textDecoration: 'none',
        },
    },
};
