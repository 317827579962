import {FeatureApiBean, PersonnelDTO} from '@/types/gatewayDataModels';

import urls from '../constants/endpoints/gateway';
import httpApi from './httpApi_new';

class UserService {
    getCurrentUser = (): Promise<PersonnelDTO> =>
        httpApi.get({
            url: urls.CURRENT_USER,
        });

    getUserFeatures = (): Promise<FeatureApiBean[]> =>
        httpApi.get({
            url: urls.FEATURES,
        });
}

const userService = new UserService();
export default userService;
