import {MHRegularButton, MHTableColumnConfig} from '@/components/base';

import {AvailableCallTransferAgentsUser} from '../shared/types';

import commonStyles from '../shared/common.module.scss';
import styles from './transferAgentsListTable.module.scss';

const STATUS_AVAILABLE = 'Available';

export const getColumnConfig = ({
    callTransferToAgentQueueHandler,
}: {
    callTransferToAgentQueueHandler: (data: string) => void;
}): MHTableColumnConfig<AvailableCallTransferAgentsUser>[] => [
    {
        title: 'Full Name',
        content: ({fullName}) => <span className={styles.userName}>{fullName}</span>,
    },
    {
        title: 'Routing Profile',
        content: ({routingProfile}) => <span className={styles.routingProfile}>{routingProfile}</span>,
    },
    {
        title: 'Current Status',
        content: ({login, currentStatus}) => (
            <>
                <div className={commonStyles.transferButton}>
                    <MHRegularButton
                        text="Transfer"
                        btnType="primary-positive"
                        onClick={() => {
                            callTransferToAgentQueueHandler(login);
                        }}
                    />
                </div>
                <span
                    className={`${commonStyles.transferTextAgent} ${
                        currentStatus === STATUS_AVAILABLE ? '' : commonStyles.transferBlockedText
                    }`}
                >
                    {currentStatus}
                </span>
            </>
        ),
    },
];
