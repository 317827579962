import urls from '@/constants/endpoints/gateway';

import httpApi from '../httpApi_new';

class LoggerService {
    logEvent = <T>(data: T) => {
        if (window.LOGGING_SERVICE_URL)
            void httpApi.post({
                url: '/log',
                origin: window.LOGGING_SERVICE_URL,
                data: {...data, url: window.location.pathname},
            });
    };

    logDataFromSource = <T>(contactId: number, dataToLog?: T) => {
        if (dataToLog) void this.logData(contactId, dataToLog);
    };

    private logData = <T>(contactId: number, data: T) => {
        return httpApi.post({
            queryParams: {initialContactId: contactId},
            url: urls.SEND_CALL_ATTRIBUTES,
            origin: window.REACT_APP_CORE_API_URL,
            data,
        });
    };
}

export const loggerService = new LoggerService();
