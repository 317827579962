import observationsUrls from '@/constants/endpoints/observations';
import {
    AlarmCommentDTO,
    AlarmDTO,
    AlarmTemplateDTO,
    CreateAlarmTemplateDTO,
    IncidentDTO,
    MeasurementUnitDTO,
    ObservationTypes,
    PageAlarmDTO,
    PageIncidentDTO,
    PageIncidentHistoryDTO,
    PatientIncidentDTO,
    TechTypesDTO,
} from '@/types/observationsModels';

import httpApi from './httpApi_new';

class AlarmService {
    getAllIncidents = ({
        subjectId,
        page,
        size,
        statusFilterValues,
        thresholdTypes,
    }: {
        subjectId: number;
        thresholdTypes?: string;
        statusFilterValues?: string;
        page?: number;
        size?: number;
        sort?: string[];
    }): Promise<PageIncidentDTO> =>
        httpApi.get({
            url: observationsUrls.ALL_INCIDENTS,
            queryParams: {
                page,
                size,
                subjectId,
                ...(statusFilterValues && {statusFilterValues}),
                ...(thresholdTypes && {thresholdTypes}),
            },
        });

    getOpenIncidents = ({
        patientId,
        page,
        size,
    }: {
        page?: number;
        size?: number;
        patientId: number;
        sort?: string[];
    }): Promise<PageIncidentDTO> =>
        httpApi.get({
            url: observationsUrls.OPEN_INCIDENTS,
            queryParams: {
                page,
                size,
                subjectId: patientId,
            },
        });

    getPatientIncidents = (nurseId: number): Promise<PatientIncidentDTO[]> =>
        httpApi.get({
            url: observationsUrls.PATIENT_INCIDENTS,
            queryParams: {
                nurseId,
            },
        });

    acknowledgeIncident = ({data, incidentId}: {incidentId: number; data: AlarmCommentDTO}): Promise<IncidentDTO> =>
        httpApi.put({
            url: observationsUrls.INCIDENT_ACKNOWLEDGE(incidentId),
            data,
        });

    getAletTamplates = ({
        patientId,
        page,
        size,
    }: {
        patientId: number;
        page?: number;
        size?: number;
    }): Promise<PageAlarmDTO> =>
        httpApi.get({
            url: observationsUrls.INCIDENTS_CONFIGURATION,
            queryParams: {
                page,
                size,
                subjectId: patientId,
            },
        });

    createAlertTemplate = ({data, patientId}: {data: CreateAlarmTemplateDTO; patientId: number}): Promise<AlarmDTO> =>
        httpApi.post({
            url: observationsUrls.CREATE_INCIDENT_TEMPLATE,
            data: {...data, update: true, subjectId: patientId},
        });

    createAlert = (data: CreateAlarmTemplateDTO): Promise<AlarmDTO> =>
        httpApi.post({
            url: observationsUrls.CREATE_INCIDENT_TEMPLATE,
            data,
        });
    updateAlert = (data: AlarmTemplateDTO, templateId: number): Promise<AlarmDTO> =>
        httpApi.put({
            url: observationsUrls.EDIT_INCIDENT_TEMPLATE(templateId),
            data,
        });
    getIncidentHistory = ({
        incidentId,
        page,
        size,
    }: {
        page?: number;
        size?: number;
        sort?: string[];
        incidentId: number;
    }): Promise<PageIncidentHistoryDTO> =>
        httpApi.get({
            url: observationsUrls.INCIDENT_HISTORY(incidentId),
            queryParams: {
                page,
                size,
            },
        });

    getAlertData = (alertId: number): Promise<AlarmDTO> =>
        httpApi.get({
            url: observationsUrls.GET_ALERT(alertId),
        });
    getPatientThresholdAlerts = (queryParams: {
        subjectId: number;
        observationType?: ObservationTypes;
        alarmType?: 'ADVANCED' | 'THRESHOLD';
    }): Promise<AlarmDTO[]> => httpApi.get({url: observationsUrls.GET_PATIENT_THRESHOLD_ALERTS, queryParams});

    enableAlert = (alarmId: number): Promise<unknown> => httpApi.put({url: observationsUrls.ENABLE_ALERT(alarmId)});
    disableAlert = (alarmId: number): Promise<unknown> => httpApi.put({url: observationsUrls.DISABLE_ALERT(alarmId)});
    getTechTypes = (): Promise<TechTypesDTO[]> => httpApi.get({url: observationsUrls.GET_TECH_TYPES});
    getMeasurementUnits = (observationType: ObservationTypes): Promise<MeasurementUnitDTO[]> =>
        httpApi.get({
            url: observationsUrls.MEASUREMENT_UNITS,
            queryParams: {observationType},
        });

    getUrgentAlertsCount = (nurseId: number): Promise<number> =>
        httpApi.get({url: observationsUrls.GET_URGENT_ALERT_COUNT, queryParams: {nurseId}});
}

const alarmService = new AlarmService();
export default alarmService;
