import {useState} from 'react';
import {FormControlLabel, Radio, RadioGroup} from '@mui/material';

import {MHButton} from '@/components/base';
import {VIDEO_CUSTOM_BG_BASE64_SRC, VIDEO_FILTER_OPTION, VIDEO_IS_CUSTOM_BG_SELECTED} from '@/constants/constants';
import {VideoFilterOptions} from '@/types/video';
import {generalNotify} from '@/utils/notifyMessages';

import {defaultBgImage} from './utils/defaultBgImage';
import {registerFilter, unregisterFilter} from './utils';

import styles from './videoCall.module.scss';

export const SettingsBgFilters = () => {
    const savedCustomBg = localStorage.getItem(VIDEO_CUSTOM_BG_BASE64_SRC);
    const savedIsCustomBgSelected = localStorage.getItem(VIDEO_IS_CUSTOM_BG_SELECTED);
    const savedFilterOption = localStorage.getItem(VIDEO_FILTER_OPTION) as VideoFilterOptions;

    const [filterOption, setFilterOption] = useState<VideoFilterOptions>(savedFilterOption || 'none');
    const [isCustomBgSelected, setIsCustomBgSelected] = useState<boolean>(savedIsCustomBgSelected === 'true');
    const [curBgImage, setCurBgImage] = useState<string>((savedIsCustomBgSelected && savedCustomBg) || defaultBgImage);

    return (
        <>
            <RadioGroup
                value={filterOption}
                classes={{root: styles.radioWrapper}}
                onChange={(e) => {
                    const value = e.currentTarget.value;

                    if (value === 'none') {
                        setFilterOption('none');
                        localStorage.setItem(VIDEO_FILTER_OPTION, 'none');
                        unregisterFilter();
                        return;
                    }

                    if (value === 'blur') {
                        unregisterFilter();
                        setFilterOption('blur');
                        localStorage.setItem(VIDEO_FILTER_OPTION, 'blur');
                        setTimeout(() => {
                            registerFilter('blur');
                        }, 0);
                        return;
                    }

                    if (value === 'bg_image') {
                        unregisterFilter();
                        setFilterOption('bg_image');
                        localStorage.setItem(VIDEO_FILTER_OPTION, 'bg_image');
                        setTimeout(() => {
                            registerFilter('bg_image', curBgImage);
                        }, 0);
                        return;
                    }
                }}
            >
                <FormControlLabel
                    label="None"
                    value="none"
                    control={<Radio color="primary" inputProps={{'aria-label': 'None'}} />}
                    classes={{
                        label: styles.typography,
                    }}
                />

                <FormControlLabel
                    label="Blur"
                    value="blur"
                    control={<Radio color="primary" inputProps={{'aria-label': 'Blur'}} />}
                    classes={{
                        label: styles.typography,
                    }}
                />
                <FormControlLabel
                    label="Background image"
                    value="bg_image"
                    control={<Radio color="primary" inputProps={{'aria-label': 'Background image'}} />}
                    classes={{
                        label: styles.typography,
                    }}
                />
            </RadioGroup>

            {filterOption === 'bg_image' && (
                <div>
                    <RadioGroup
                        value={isCustomBgSelected ? 'customBg' : 'defaultBg'}
                        classes={{root: styles.radioWrapper}}
                        onChange={(e) => {
                            const value = e.currentTarget.value;

                            if (value === 'customBg') {
                                setIsCustomBgSelected(true);
                                localStorage.setItem(VIDEO_IS_CUSTOM_BG_SELECTED, 'true');
                                const savedCustomBg =
                                    localStorage.getItem(VIDEO_CUSTOM_BG_BASE64_SRC) || defaultBgImage;

                                if (curBgImage === savedCustomBg) {
                                    return;
                                }

                                setCurBgImage(savedCustomBg);

                                unregisterFilter();
                                setTimeout(() => {
                                    registerFilter('bg_image', savedCustomBg);
                                }, 0);
                                return;
                            }

                            if (value === 'defaultBg') {
                                setCurBgImage(defaultBgImage);
                                setIsCustomBgSelected(false);
                                localStorage.removeItem(VIDEO_IS_CUSTOM_BG_SELECTED);

                                unregisterFilter();
                                setTimeout(() => {
                                    registerFilter('bg_image', defaultBgImage);
                                }, 0);
                                return;
                            }
                        }}
                    >
                        <FormControlLabel
                            label="Default background image"
                            value="defaultBg"
                            control={<Radio color="primary" inputProps={{'aria-label': 'None'}} />}
                            classes={{
                                label: styles.typography,
                            }}
                        />

                        {
                            <FormControlLabel
                                label="Custom background image"
                                value="customBg"
                                control={<Radio color="primary" inputProps={{'aria-label': 'Blur'}} />}
                                classes={{
                                    label: styles.typography,
                                }}
                            />
                        }
                    </RadioGroup>
                    <div
                        style={{
                            display: 'flex',
                            gap: '10px',
                            marginTop: '10px',
                        }}
                    >
                        {curBgImage && (
                            <div style={{display: 'flex', flexFlow: 'column', justifyItems: 'center'}}>
                                <img
                                    src={curBgImage}
                                    alt="bg image"
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        objectFit: 'cover',
                                        transform: 'scaleX(-1)',
                                    }}
                                />
                                {isCustomBgSelected && curBgImage === defaultBgImage && (
                                    <div
                                        style={{
                                            marginTop: '10px',
                                            color: 'white',
                                            fontSize: '12px',
                                        }}
                                    >
                                        (Default image is selected until custom image is uploaded)
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {isCustomBgSelected && (
                        <div>
                            <div
                                style={{
                                    paddingTop: '20px',
                                    color: 'white',
                                    fontSize: '14px',
                                }}
                            >
                                Upload custom background image (JPEG or PNG up to 2MB)
                            </div>
                            <input
                                id="customBgUploader"
                                type="file"
                                accept="image/*"
                                style={{display: 'none'}}
                                onChange={(e) => {
                                    const file = e.target.files?.[0];
                                    if (!file) {
                                        e.target.value = '';
                                        return;
                                    }
                                    if (!file.type.match('image/jpeg') && !file.type.match('image/png')) {
                                        generalNotify({
                                            message: 'Only JPG and PNG files are allowed',
                                            status: 'error',
                                        });
                                        e.target.value = '';
                                        return;
                                    }
                                    if (file.size > 2097152) {
                                        generalNotify({
                                            message: 'File size must be less than 2MB',
                                            status: 'error',
                                        });
                                        e.target.value = '';
                                        return;
                                    }
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                        const data = e.target?.result;
                                        localStorage.setItem(VIDEO_IS_CUSTOM_BG_SELECTED, 'true');

                                        const img = new Image();
                                        img.src = data as string;

                                        img.onload = function () {
                                            const modifiedData = data as string;

                                            localStorage.setItem(VIDEO_CUSTOM_BG_BASE64_SRC, modifiedData);
                                            setCurBgImage(modifiedData);
                                            unregisterFilter();
                                            setTimeout(() => {
                                                registerFilter('bg_image', modifiedData);
                                            }, 0);
                                        };
                                    };
                                    reader.readAsDataURL(file);
                                    e.target.value = '';
                                }}
                            />
                            <div style={{display: 'flex', gap: '10px'}}>
                                {defaultBgImage !== curBgImage && (
                                    <MHButton
                                        buttonType="danger"
                                        onClickHandler={() => {
                                            localStorage.removeItem(VIDEO_CUSTOM_BG_BASE64_SRC);
                                            localStorage.removeItem(VIDEO_IS_CUSTOM_BG_SELECTED);
                                            setCurBgImage(defaultBgImage);
                                            setIsCustomBgSelected(false);
                                            unregisterFilter();
                                            setTimeout(() => {
                                                registerFilter('bg_image', defaultBgImage);
                                            }, 0);
                                        }}
                                        text="REMOVE"
                                    />
                                )}
                                <MHButton
                                    buttonType="primary"
                                    onClickHandler={() => {
                                        const el = document.getElementById('customBgUploader');

                                        el?.click();
                                    }}
                                    text="UPLOAD"
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
