import {BaseIconProps} from '@/assets/icons/types';

type WeightIconProps = BaseIconProps;

export const WeightIcon = ({width = '32', height = '28', viewBox = '0 0 32 28', fill = '#001A70'}: WeightIconProps) => {
    return (
        <svg width={width} height={height} viewBox={viewBox} fill="none">
            <path
                d="M27.1875 0C28.7383 0 30 1.26172 30 2.8125V29.1875C30 30.7383 28.7383 32 27.1875 32H4.8125C3.26172 32 2 30.7383 2 29.1875V2.8125C2 1.26172 3.26172 0 4.8125 0H27.1875ZM27.1875 1.875H4.8125C4.29565 1.875 3.875 2.29565 3.875 2.8125V29.1875C3.875 29.7043 4.29565 30.125 4.8125 30.125H27.1875C27.7043 30.125 28.125 29.7043 28.125 29.1875V2.8125C28.125 2.29565 27.7043 1.875 27.1875 1.875ZM15.9662 3.75C19.4675 3.75 22.7983 4.8518 25.5982 6.93603C25.7755 7.06814 25.8927 7.2648 25.9238 7.4824C25.9551 7.70021 25.8979 7.92148 25.7646 8.09741L21.3559 13.919C21.0791 14.2847 20.556 14.3587 20.1874 14.0841C18.9603 13.1708 17.5005 12.6879 15.9662 12.6879C14.4316 12.6879 12.972 13.1708 11.7449 14.0841C11.5947 14.196 11.4187 14.25 11.2445 14.25C10.9909 14.25 10.7404 14.1357 10.5762 13.919L6.16749 8.09741C6.03438 7.92148 5.97718 7.70021 6.00828 7.4824C6.0396 7.26458 6.15683 7.06814 6.33408 6.93603C9.13401 4.8518 12.4648 3.75 15.9662 3.75ZM15.9662 5.40788C13.1092 5.40788 10.3789 6.22668 8.01939 7.78332L11.4337 12.292C12.5603 11.6077 13.8156 11.1932 15.131 11.0697V8.54318C15.131 8.08532 15.5049 7.71424 15.9662 7.71424C16.4275 7.71424 16.8013 8.08532 16.8013 8.54318V11.0697C18.1167 11.1932 19.372 11.6077 20.4986 12.292L23.9129 7.78332C21.5534 6.22668 18.8231 5.40788 15.9662 5.40788Z"
                fill={fill}
            />
        </svg>
    );
};
