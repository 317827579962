import 'react-dates/initialize';

import {Suspense, useEffect, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {matchPath, Redirect, Switch} from 'react-router';

import MHLoader from '@/components/base/MHLoader';
import {CallNotification} from '@/components/general/CallNotification';
import {LeftNavbar} from '@/components/general/LeftNavbar';
import {MHHeader} from '@/components/general/MHHeader';
import {MHWebsocket} from '@/components/general/MHWebsocket';
import {PageNotAccessibleModal} from '@/components/general/PageNotAccessibleModal';
import {renderRoutes} from '@/components/Router';
import {LoggingActions} from '@/constants/loggingActions';
import {useAuth0} from '@/services/auth0';
import awsConnectService from '@/services/awsConnectApiService';
import {useInitializeDatadogRum} from '@/services/dataDogRum';
import httpApi_new from '@/services/httpApi_new';
import {loggerService} from '@/services/loggerService';
import {Route} from '@/types/commonTypes';
import {clearLocalStorage} from '@/utils/localStoreUtils';

import {isVideoCallsEnabledSelector, navigationSelector} from './redux/selectors';

import '@/assets/css/vendors/datepicker.css';
import styles from './app.module.scss';

type AWSConnectService = typeof awsConnectService & {contactStatus?: string};

export const App = () => {
    const [isWSInitialized, setWSInitialized] = useState(false);
    const {routes, email, nurseId, loading} = useSelector(navigationSelector, shallowEqual);
    const {isVideoCallsEnabled} = useSelector(isVideoCallsEnabledSelector, shallowEqual);

    useInitializeDatadogRum();

    const routesForNav = [
        `/my-page/${nurseId}`,
        '/patients',
        '/calendar',
        '/vendor-dictionary',
        '/action-queue',
        '/service-area',
        '/administrate',
        '/performance-improvement-log',
        '/multi-multi-not-supported',
    ];

    const {getTokenSilently = null, isAuthenticated} = useAuth0();

    useEffect(() => {
        if (!loading) {
            loggerService.logEvent({
                severity: 'Info',
                email: email,
                action: LoggingActions.appInitialization,
                message: 'App initialized or user has changed an email',
            });
        }
    }, [email, loading]);
    httpApi_new.setTokenGetter(getTokenSilently);

    //TODO THIS IS A TEMPORARY FIX, remove later
    useEffect(() => {
        const hasBeenCleared = localStorage.getItem('localStorageHasBeenClearedTemporaryFix');
        if (hasBeenCleared !== 'true') {
            clearLocalStorage();
            localStorage.setItem('localStorageHasBeenClearedTemporaryFix', 'true');
            // eslint-disable-next-line no-console
            console.log('local storage has not been cleared before. Clearing now...');
        } else {
            // eslint-disable-next-line no-console
            console.log('localStorage has already been cleared');
        }
    }, []);

    const defaultRoute: Route = {route: '/no-roles', title: 'no roles', exact: true, isHidden: true};
    const navigationRoutes = !routes.length
        ? [defaultRoute]
        : routesForNav.reduce<Route[]>((acc, navUrl) => {
              routes
                  .filter(({route}) =>
                      matchPath(navUrl, {
                          path: route,
                          exact: true,
                          strict: false,
                      }),
                  )
                  .forEach((item) => acc.push({...item, route: navUrl}));
              return acc;
          }, []);

    if (loading) return <MHLoader rootClassName={styles.loaderContainer} />;

    window.addEventListener('beforeunload', function (event) {
        const onACall = (window.awsConnectService as AWSConnectService).contactStatus === 'connected';
        if (onACall) {
            event.preventDefault();
            return (event.returnValue = '');
        } else {
            awsConnectService.goOffline();
        }
    });

    return (
        <>
            {isAuthenticated && <MHWebsocket setWSInitialized={setWSInitialized} />}
            {isWSInitialized ? (
                <>
                    {isAuthenticated && <MHHeader />}
                    <CallNotification isVideoCallsEnabled={isVideoCallsEnabled} />
                    <div className={styles.contentWrapper}>
                        {isAuthenticated && <LeftNavbar routes={navigationRoutes} />}
                        <PageNotAccessibleModal />
                        <div className={styles.content}>
                            <Suspense fallback={<MHLoader />}>
                                <Switch>
                                    {renderRoutes(routes.length ? routes : [defaultRoute])}
                                    <Redirect to={navigationRoutes[0].route} />
                                </Switch>
                            </Suspense>
                        </div>
                    </div>
                </>
            ) : (
                <MHLoader rootClassName={styles.loaderContainer} />
            )}
        </>
    );
};
