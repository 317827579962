import {StoreStateShape} from '@/redux/store/storeStateTypes';

export const leftNavbarSelector = ({
    commonUserDataReducer: {employment, permissions, leftNavbarOpen},
    leftNavbarReducer: {
        logCount: {newCount, inReviewCount, highestLevelOfImpact},
    },
}: StoreStateShape) => ({
    newCount,
    permissions,
    inReviewCount,
    highestLevelOfImpact,
    nurseId: employment?.id,
    leftNavbarOpen,
});
