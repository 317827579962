import {MHTypography} from '@/components/base';
import {MissedCallSummary} from '@/types/observationsModels';
import {formatDateTimeToPatient, getDateTimeFormats} from '@/utils/timeFormatter';

import {CallType} from './CallType';

import styles from './meetings.module.scss';

const {DATE_FORMAT, TIME_FORMAT} = getDateTimeFormats();
type MeetingsProps = {
    missedCalls: MissedCallSummary[];
    patientTimeZone: string;
};

const CallText = {
    AUDIO: 'Missed Audio Call',
    PERS: 'Missed PERS Call',
    VIDEO: 'Missed Video Call',
    WIFI: 'Missed WIFI Call',
};

export const Meetings = ({missedCalls, patientTimeZone}: MeetingsProps) => {
    return (
        <div className={styles.container}>
            {missedCalls.map(({type, viewed, count, latestStartTime}, index) => (
                <div key={index} className={styles.wrapper}>
                    <div className={styles.info}>
                        <CallType type={type} viewed={viewed} />
                        <MHTypography variant="bodyM" marginLeft="8px" color={viewed ? 'black_100' : 'red_100'}>
                            {CallText[type]} ({count})
                        </MHTypography>
                    </div>

                    <MHTypography variant="bodyM" color="black_60" data-testid="hidden-date">
                        {formatDateTimeToPatient(latestStartTime, TIME_FORMAT, null, patientTimeZone)},{' '}
                        {formatDateTimeToPatient(latestStartTime, DATE_FORMAT, null, patientTimeZone)}
                    </MHTypography>
                </div>
            ))}
        </div>
    );
};
