import {DATE_TIME_API_FORMAT} from '@/constants/timeFormats';
import {AppStartListening} from '@/redux/store/listenerMiddleware';
import spiService from '@/services/spiService';
import {formatDateTime, getDateTimeFormats} from '@/utils/timeFormatter';

import {
    changeVendorReferralDateTime,
    changeVendorReferralDateTimeFail,
    changeVendorReferralDateTimeSuccess,
} from './slice';

const {NEW_DATE_TIME_FORMAT} = getDateTimeFormats();

export default (startListening: AppStartListening) => {
    startListening({
        actionCreator: changeVendorReferralDateTime,
        effect: async (action, listenerApi) => {
            const {cb, actorId, sentDateTime, acceptedDateTime} = action.payload;

            if (cb) {
                cb({sentDateTime, acceptedDateTime});
            }

            try {
                const sent = sentDateTime
                    ? formatDateTime(sentDateTime, DATE_TIME_API_FORMAT, NEW_DATE_TIME_FORMAT)
                    : null;
                const accepted = acceptedDateTime
                    ? formatDateTime(acceptedDateTime, DATE_TIME_API_FORMAT, NEW_DATE_TIME_FORMAT)
                    : null;

                await spiService.changeVendorReferralDates({
                    actorId,
                    sentDateTime: sent,
                    acceptedDateTime: accepted,
                });
                listenerApi.dispatch(changeVendorReferralDateTimeSuccess());
            } catch (err) {
                listenerApi.dispatch(changeVendorReferralDateTimeFail());
            }
        },
    });
};
