import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {AvailableCallTransferAgentsUser} from '../shared/types';
import {AvailableCallTransferAgentsState} from './stateTypes';

const initialState: AvailableCallTransferAgentsState = {
    destinationAgentEmail: '',
    users: [],
    loadingHandler: {
        usersLoading: false,
        callTransferLoading: false,
    },
};

const SLICE_NAME = 'availableCallTransferAgentsReducer';

const {reducer, actions} = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        getAvailableAgents: (state, _action: PayloadAction) => {
            state.loadingHandler.usersLoading = true;
        },
        getAvailableAgentsSuccess: (state, action: PayloadAction<AvailableCallTransferAgentsUser[]>) => {
            state.loadingHandler.usersLoading = false;
            state.users = action.payload;
        },
        getAvailableAgentsFail: (state, _action: PayloadAction) => {
            state.loadingHandler.usersLoading = false;
        },

        setCallTransferData: (state, action: PayloadAction<{destinationAgentEmail: string}>) => {
            state.destinationAgentEmail = action.payload.destinationAgentEmail;
        },
        callTransferToAgentQueue: (state, _action: PayloadAction) => {
            state.loadingHandler.callTransferLoading = true;
        },
        callTransferToAgentQueueSuccess: (state, _action: PayloadAction) => {
            state.destinationAgentEmail = '';
            state.loadingHandler.callTransferLoading = false;
        },
        callTransferToAgentQueueFail: (state, _action: PayloadAction) => {
            state.destinationAgentEmail = '';
            state.loadingHandler.callTransferLoading = false;
        },
        clearCallTransferData: (state, _action: PayloadAction) => {
            state.destinationAgentEmail = '';
            state.loadingHandler = {usersLoading: false, callTransferLoading: false};
        },
    },
});

export const {
    getAvailableAgents,
    getAvailableAgentsSuccess,
    getAvailableAgentsFail,
    setCallTransferData,
    callTransferToAgentQueue,
    callTransferToAgentQueueSuccess,
    callTransferToAgentQueueFail,
    clearCallTransferData,
} = actions;

export default reducer;
