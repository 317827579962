import permissions from '@/constants/PERMISSIONS';
import {StoreStateShape} from '@/redux/store/storeStateTypes';

const {READ_URGENT_ALERTS} = permissions;

const urgentAlertsPermissionsSelector = ({commonUserDataReducer: {permissions}}: StoreStateShape) =>
    permissions[READ_URGENT_ALERTS];

const phoneCallDataSelector = (state: StoreStateShape) => state.commonUserDataReducer.phoneCallData;
const missionControlIdsSelector = (state: StoreStateShape) => state.commonUserDataReducer.missionControlIds;
const userSelector = (state: StoreStateShape) => state.commonUserDataReducer.user;
const activeVideoCallsSelector = (state: StoreStateShape) => state.callReducer.activeVideoCallsList;
const employmentSelector = (store: StoreStateShape) => store.commonUserDataReducer.employment;
const patientIdSelector = (store: StoreStateShape) => store.patientInfoReducer?.patientInfo?.id;
const featureFlagSelector = ({
    commonUserDataReducer: {
        features: {AllowPatientPrecaution},
    },
}: StoreStateShape) => AllowPatientPrecaution;

const selectClinicName = ({
    commonUserDataReducer: {selectedMissionControl},
    commonDataReducer: {missionControls},
}: StoreStateShape) => {
    const activeMissionControl = missionControls.find((item) => item.id === selectedMissionControl);
    return activeMissionControl?.fullName;
};

const selectBuildInfo = ({commonDataReducer: {buildInfo}}: StoreStateShape) => buildInfo;

const isVideoCallsEnabledSelector = ({
    commonUserDataReducer: {
        features: {CapabilityVideoCalls},
    },
}: StoreStateShape) => ({
    isVideoCallsEnabled: CapabilityVideoCalls,
});

export {
    activeVideoCallsSelector,
    employmentSelector,
    featureFlagSelector,
    isVideoCallsEnabledSelector,
    missionControlIdsSelector,
    patientIdSelector,
    phoneCallDataSelector,
    selectBuildInfo,
    selectClinicName,
    urgentAlertsPermissionsSelector,
    userSelector,
};
