import {StoreStateShape} from '@/redux/store/storeStateTypes';

const getActiveCalls = ({callReducer, commonUserDataReducer: {employment}}: StoreStateShape) => ({
    activeCalls: callReducer.activeCalls,
    states: employment.states,
});

const selectShowVideoCallWidget = ({callReducer: {showVideoCallWidget}}: StoreStateShape) => showVideoCallWidget;
const selectCurrentCallMeetingId = ({callReducer: {currentCallMeetingId}}: StoreStateShape) => currentCallMeetingId;
const selectCurrentCallPatientId = ({callReducer: {currentCallPatientId}}: StoreStateShape) => currentCallPatientId;

export {getActiveCalls, selectCurrentCallMeetingId, selectCurrentCallPatientId, selectShowVideoCallWidget};
