import {AppStartListening} from '@/redux/store/listenerMiddleware';
import patientService from '@/services/patientService';

import {getPatientInfo, getPatientInfoFailed, getPatientInfoSuccess} from './slice';

const addFeatureListeners = (startListening: AppStartListening) => {
    startListening({
        actionCreator: getPatientInfo,
        effect: async (action, listenerApi) => {
            try {
                const data = await patientService.getPatient(action.payload);
                listenerApi.dispatch(getPatientInfoSuccess(data));
            } catch (err) {
                listenerApi.dispatch(getPatientInfoFailed());
            }
        },
    });
};

export default addFeatureListeners;
