import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {LogCountDTO} from '@/types/performanceIssueTrackerDataModels';

import {LeftNavbarState} from './stateTypes';

const initialState: LeftNavbarState = {
    logCount: {
        totalCount: 0,
        newCount: 0,
        inReviewCount: 0,
        highestLevelOfImpact: null,
    },
    loadingHandler: {
        getPerformanceImprovementLogCounterLoading: false,
    },
};

const {reducer, actions} = createSlice({
    name: 'leftNavbar',
    initialState,
    reducers: {
        getPerformanceImprovementLogCounter: (state) => {
            state.loadingHandler.getPerformanceImprovementLogCounterLoading = true;
        },
        getPerformanceImprovementLogCounterSuccess: (state, action: PayloadAction<LogCountDTO>) => {
            state.logCount = action.payload;
            state.loadingHandler.getPerformanceImprovementLogCounterLoading = false;
        },
        getPerformanceImprovementLogCounterFail: (state) => {
            state.loadingHandler.getPerformanceImprovementLogCounterLoading = false;
        },
    },
});

export const {
    getPerformanceImprovementLogCounter,
    getPerformanceImprovementLogCounterSuccess,
    getPerformanceImprovementLogCounterFail,
} = actions;

export default reducer;
