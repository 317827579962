import {useCallback, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {Box} from '@mui/material';

import routes from '@/constants/routes';
import {
    selectCurrentCallMeetingId,
    selectCurrentCallPatientId,
    selectShowVideoCallWidget,
} from '@/redux/commonCalls/selectors';
import {MEETING_ID_KEY, VIDEO_CALL_USER_KEY, VIDEO_PROVIDER_SETTINGS, videoProvider} from '@/services/videoService';

import {employmentSelector} from '../redux/selectors';
import {VideoCallToolbar} from './VideoCallToolbar';

export const VideoCall = () => {
    const checkWindowOpenIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const newWindowRef = useRef<Window | null>(null);
    const meetingId = useSelector(selectCurrentCallMeetingId);
    const patientId = useSelector(selectCurrentCallPatientId);
    const showVideoCallWidget = useSelector(selectShowVideoCallWidget);
    const history = useHistory();
    const endCallRef = useRef(null);
    const user = useSelector(employmentSelector);

    const handleEndCall = useCallback(() => {
        clearTimeout(endCallRef.current);
        newWindowRef.current?.close?.();
        clearInterval(checkWindowOpenIntervalRef.current);

        endCallRef.current = setTimeout(() => {
            if (meetingId) {
                videoProvider.leaveCall({
                    meetingId,
                    url: VIDEO_PROVIDER_SETTINGS.VIDYO.providerSpecificBaseUrl,
                });
            }

            newWindowRef.current = null;
        }, 1000);
    }, [meetingId]);

    const handleGoToPatientPage = useCallback(() => {
        if (!patientId) {
            console.error('currentCallPatientId is not defined');
            return;
        }

        history.push(routes.PATIENT_SINGLE_OVERVIEW(patientId.toString()));
    }, [history, patientId]);

    useEffect(() => {
        if (meetingId && !newWindowRef.current && showVideoCallWidget) {
            sessionStorage.setItem(MEETING_ID_KEY, meetingId || '');
            sessionStorage.setItem(
                VIDEO_CALL_USER_KEY,
                JSON.stringify({firstName: user?.firstName, lastName: user?.lastName, id: user?.id}),
            );

            newWindowRef.current = window.open(
                `/video-call`,
                '_blank',
                `popup=yes,height=600,width=800,left=${window.innerWidth / 2 - 800 / 4},top=${window.innerHeight / 2 - 600 / 4}`,
            );
            newWindowRef.current?.focus();
            newWindowRef.current?.addEventListener('beforeunload', handleEndCall);
            return;
        }
    }, [handleEndCall, meetingId, showVideoCallWidget, user?.firstName, user?.id, user?.lastName]);

    useEffect(() => {
        checkWindowOpenIntervalRef.current = setInterval(() => {
            if (!meetingId) {
                handleEndCall();
                clearInterval(checkWindowOpenIntervalRef.current);
                return;
            }

            if (newWindowRef.current?.closed) {
                handleEndCall();
                clearInterval(checkWindowOpenIntervalRef.current);
                return;
            }
        }, 1000);

        return () => {
            clearInterval(checkWindowOpenIntervalRef.current);
        };
    }, [handleEndCall, meetingId, showVideoCallWidget]);

    useEffect(() => {
        if (newWindowRef?.current?.closed) {
            handleEndCall();
            clearInterval(checkWindowOpenIntervalRef.current);
        }
    });

    if (!meetingId) {
        return null;
    }

    return (
        <Box
            sx={{
                display: 'grid',
                gridAutoFlow: 'column',
                gap: 2,
                paddingRight: 2,
            }}
        >
            <VideoCallToolbar
                onGoToPatientPage={handleGoToPatientPage}
                onEndCall={handleEndCall}
                meetingId={meetingId}
            />
        </Box>
    );
};
