import {deviceListenerCb} from './utils/deviceListenerCb';

declare global {
    interface Window {
        vidyoConnector: any;
        speaker: any;
    }
}

type Props = {
    onAdded?: (localSpeaker: {id: string; name: string}) => void;
    onRemoved?: (localSpeakerId: string) => void;
    setCurSpeaker?: (speaker: any) => void;
};

export const registerSpeaker = async (props?: Props) => {
    const {onAdded, onRemoved, setCurSpeaker} = props || {};

    window['speaker'] = [];

    try {
        await window.vidyoConnector.RegisterLocalSpeakerEventListener(
            deviceListenerCb({onAdded, onRemoved, setCurDevice: setCurSpeaker, deviceName: 'speaker'}),
        );
        console.info('LocalSpeakerEventListener registered');
    } catch (error) {
        console.error('RegisterLocalSpeakerEventListener failed', error);
    }
};
