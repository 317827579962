import {STATES} from '@/constants/commonDataNames';
import {AppStartListening} from '@/redux/store/listenerMiddleware';

import {getAllStates} from './slice';
import {getCommonData} from './softDependencies';

const addFeatureListeners = (startListening: AppStartListening) => {
    startListening({
        actionCreator: getAllStates,
        effect: (_action, listenerApi) => {
            listenerApi.dispatch(getCommonData([STATES]));
        },
    });
};

export default addFeatureListeners;
