import {MHRegularButton, MHTableColumnConfig} from '@/components/base';
import {QueueARNItem} from '@/redux/commonUserData/stateTypes';

import commonStyles from '../shared/common.module.scss';
import styles from './transferQueuesListTable.module.scss';

export const getColumnConfig = ({
    callTransferToQueueHandler,
}: {
    callTransferToQueueHandler: (data: QueueARNItem) => void;
}): MHTableColumnConfig<QueueARNItem>[] => [
    {
        title: 'Queue name',
        content: ({name}) => <span className={styles.queueName}>{name}</span>,
    },
    {
        title: 'Transfer',
        content: (row) => (
            <>
                <div className={commonStyles.transferButton}>
                    <MHRegularButton
                        text="Transfer"
                        btnType="primary-positive"
                        onClick={() => {
                            callTransferToQueueHandler(row);
                        }}
                    />
                </div>
                <span className={commonStyles.transferText}>Transfer</span>
            </>
        ),
    },
];
