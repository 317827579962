import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {LoggingActions} from '@/constants/loggingActions';
import {StoreStateShape} from '@/redux/store/storeStateTypes';
import {loggerService} from '@/services/loggerService';
import websocketService from '@/services/webSocketService';
import {generalNotify} from '@/utils/notifyMessages';

export const MHWebsocket = ({setWSInitialized}: {setWSInitialized: (state: boolean) => void}) => {
    const email = useSelector(({commonUserDataReducer}: StoreStateShape) => commonUserDataReducer?.user.email);

    useEffect(() => {
        websocketService.initWebSocket(
            () => {
                console.info('socket connected');
                loggerService.logEvent({
                    severity: 'Info',
                    email: email,
                    action: LoggingActions.websocketInitialization,
                    message: 'socket connected',
                });
                setWSInitialized(true);
            },
            () => {
                console.info('socket disconnected');
                loggerService.logEvent({
                    severity: 'Info',
                    email: email,
                    action: LoggingActions.websocketDisconnect,
                    message: 'socket disconnected',
                });
                setWSInitialized(true);
                console.error(`WebSocket was disconnected. Real-time notifications and counters don't work!!!`);
                generalNotify({
                    title: 'Error',
                    message: `WebSocket was disconnected. Real-time notifications don't work!`,
                    status: 'error',
                });
            }
        );

        return () => {
            websocketService.disconnect();
        };
    }, [setWSInitialized, email]);

    return <></>;
};
