import {useState} from 'react';
import {ArrowDropUp, Check} from '@mui/icons-material';
import {FormControl, InputLabel, Menu, MenuItem, Select, SelectChangeEvent, styled} from '@mui/material';

import {SettingsDevicesProps} from './Settings';
import {SelectOption} from './VideoCallWidget';

import styles from './videoCall.module.scss';

const CustomInputLabel = styled(InputLabel)({
    position: 'absolute',
    top: '-26px',
    left: '-4px',
    padding: '0 4px',
    zIndex: 1,
    transform: 'none',
    transition: 'none',
});

const CustomSelect = ({
    selectedValue,
    options,
    onChange,
    label,
    dropdownOnly = false,
}: {
    dropdownOnly?: boolean;
    label: string;
    selectedValue?: SelectOption;
    options?: {
        value: string;
        label: string;
    }[];
    onChange: (event: SelectChangeEvent<string>) => void;
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    if (!options) {
        return null;
    }

    if (dropdownOnly) {
        const bbox = anchorEl?.getBoundingClientRect();

        return (
            <>
                <div
                    onClick={handleOpen}
                    style={{
                        display: 'flex',
                        height: 60,
                        cursor: 'pointer',
                        width: 52,
                        justifyContent: 'start',
                        alignItems: 'center',
                        backgroundColor: 'rgb(19, 38, 58)',
                    }}
                >
                    <ArrowDropUp style={{fill: 'rgba(255,255,255,.5)', width: '40px', height: '40px', padding: 0}} />
                </div>
                <Menu
                    open={open}
                    onClose={handleClose}
                    style={{
                        overflow: 'visible',
                        left: bbox ? `${bbox?.left}px` : '-100vh',
                        top: `-40px`,
                        display: bbox ? 'flex' : 'hidden',
                    }}
                >
                    {options?.map((option) => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                            onClick={() => {
                                onChange({target: {value: option.value}} as SelectChangeEvent<string>);
                                handleClose();
                            }}
                        >
                            {option.label}{' '}
                            {selectedValue?.value === option.value && (
                                <Check style={{paddingLeft: 4}} fontSize="small" />
                            )}
                        </MenuItem>
                    ))}
                </Menu>
            </>
        );
    }

    return (
        <FormControl fullWidth variant="outlined">
            <CustomInputLabel id="custom-select-label">{label}</CustomInputLabel>
            <Select
                labelId="custom-select-label"
                id="custom-select"
                value={selectedValue?.value || ''}
                onChange={onChange}
                sx={{
                    backgroundColor: 'white',
                    height: 40,
                    '& > #custom-select > svg': {
                        position: 'absolute',
                        top: 8,
                    },
                }}
                style={{display: 'flex'}}
            >
                {options?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}{' '}
                        {selectedValue?.value === option.value && <Check style={{paddingLeft: 4}} fontSize="small" />}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const SettingsDevices = ({
    cameraList,
    micList,
    speakerList,
    curMic,
    curSpeaker,
    curCamera,
    setCurMic,
    setCurSpeaker,
    setCurCamera,
}: SettingsDevicesProps) => {
    return (
        <>
            <div className={styles.settingsRow}>
                <CustomSelect
                    label="Microphone"
                    selectedValue={curMic}
                    options={micList}
                    onChange={(e) => {
                        const mic = micList.find((m) => m.value === e.target.value);
                        setCurMic(mic);
                    }}
                />
            </div>
            <div className={styles.settingsRow} style={{marginTop: 36}}>
                <CustomSelect
                    label="Camera"
                    selectedValue={curCamera}
                    options={cameraList}
                    onChange={(e) => {
                        const cam = cameraList.find((m) => m.value === e.target.value);
                        setCurCamera(cam);
                    }}
                />
            </div>
            <div className={styles.settingsRow} style={{marginTop: 36}}>
                <CustomSelect
                    label="Speaker"
                    selectedValue={curSpeaker}
                    options={speakerList}
                    onChange={(e) => {
                        const speaker = speakerList.find((m) => m.value === e.target.value);
                        setCurSpeaker(speaker);
                    }}
                />
            </div>
        </>
    );
};

export {CustomSelect, SettingsDevices};
