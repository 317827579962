import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {CreateImprovementLogEntryButtonState} from './stateTypes';

const initialState: CreateImprovementLogEntryButtonState = {
    potentialIssuesMessages: {},
    loadingHandler: {potentialIssueLoading: false},
};

const SLICE_NAME = 'createImprovementLogEntryButton';
const AppointmentOverview = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        getPotentialIssue: (state, _action: PayloadAction<number>) => state,
        getPotentialIssueStart: (state) => {
            state.loadingHandler.potentialIssueLoading = true;
        },
        getPotentialIssueSuccess: (
            state,
            action: PayloadAction<{appointmentId: number; potentialIssuesMessages: string[]}>
        ) => {
            const {appointmentId, potentialIssuesMessages} = action.payload;
            state.potentialIssuesMessages = {
                ...state.potentialIssuesMessages,
                [appointmentId]: potentialIssuesMessages,
            };
            state.loadingHandler.potentialIssueLoading = false;
        },
        getPotentialIssueFail: (state) => {
            state.loadingHandler.potentialIssueLoading = false;
        },
        removeAppointmentPotentialIssueMessages: (state, action: PayloadAction<number>) => {
            const potentialIssuesMessages = state.potentialIssuesMessages;
            delete potentialIssuesMessages[action.payload];
            state.potentialIssuesMessages = potentialIssuesMessages;
        },
        clearState: () => initialState,
    },
});
const {reducer, actions} = AppointmentOverview;

export const {
    getPotentialIssue,
    getPotentialIssueStart,
    getPotentialIssueSuccess,
    getPotentialIssueFail,
    removeAppointmentPotentialIssueMessages,
    clearState,
} = actions;

export default reducer;
