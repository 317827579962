import {AppStartListening} from '@/redux/store/listenerMiddleware';
import appointmentService from '@/services/appointmentsService';
import handOffService from '@/services/handOffService';
import {generalNotify} from '@/utils/notifyMessages';

import {changePartner, changePartnerFail, changePartnerSuccess} from './slice';
import {ChangePartnerData, SelectedPartnerData} from './stateTypes';

const addFeatureListeners = (startListening: AppStartListening) => {
    startListening({
        actionCreator: changePartner,
        effect: async (action, listenerApi) => {
            try {
                const {
                    appointmentId,
                    changeVendorRequestId,
                    selectedPartner,
                    createServiceRequest,
                    callBack,
                    reason,
                }: ChangePartnerData = action.payload;

                const {actor, siteCode, vendorCode, id}: SelectedPartnerData = selectedPartner;
                const successData = appointmentId
                    ? await appointmentService.changeAppointmentVendor({
                          appointmentId,
                          data: {
                              reason,
                              createServiceRequest,
                              performer: {siteCode, vendorCode, actorCode: actor.code},
                          },
                      })
                    : await handOffService.changeVendorHandoff({
                          requestId: changeVendorRequestId,
                          data: {
                              handOffVendorId: id,
                              createServiceRequest,
                              selectedPartner: {
                                  siteCode,
                                  vendorCode,
                                  actorCode: actor.code,
                              },
                          },
                      });
                listenerApi.dispatch(changePartnerSuccess());
                generalNotify({
                    message: 'Vendor successfully changed',
                    status: 'success',
                });

                if ('id' in successData) callBack?.(successData.id);
                else callBack?.();

                if ('notificationId' in successData) {
                    const notification = await appointmentService.getPostponedNotification(successData.notificationId);
                    generalNotify({
                        title: 'Success',
                        message: notification.message,
                        status: 'success',
                    });
                }
            } catch (err) {
                listenerApi.dispatch(changePartnerFail());
            }
        },
    });
};

export default addFeatureListeners;
