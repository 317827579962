import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ActiveMeetingsDTO} from '@/types/observationsModels';

import {ActiveMeetingsState} from './stateTypes';

const initialState: ActiveMeetingsState = {
    data: {},
    activeMeetingsCount: 0,
    loadingHandler: {
        getActiveMeetingsCountLoading: false,
        getActiveMeetingsLoading: false,
        markSeenCallsLoading: false,
    },
};

const SLICE_NAME = 'activeMeetings';
const {reducer, actions} = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        getActiveMeetingsCount: (state) => {
            state.loadingHandler.getActiveMeetingsCountLoading = true;
        },
        getActiveMeetingsCountSuccess: (state, action: PayloadAction<number>) => {
            state.activeMeetingsCount = action.payload;
            state.loadingHandler.getActiveMeetingsCountLoading = false;
        },
        getActiveMeetingsCountFail: (state) => {
            state.loadingHandler.getActiveMeetingsCountLoading = false;
        },
        setActiveMeetingsCount: (state, action: PayloadAction<number>) => {
            state.activeMeetingsCount = action.payload;
        },
        getActiveMeetings: (state) => {
            state.loadingHandler.getActiveMeetingsLoading = true;
        },
        getActiveMeetingsSuccess: (state, action: PayloadAction<ActiveMeetingsDTO>) => {
            state.data = action.payload;
            state.loadingHandler.getActiveMeetingsLoading = false;
        },
        getActiveMeetingsFail: (state) => {
            state.loadingHandler.getActiveMeetingsLoading = false;
        },
        markSeenCalls: (state) => {
            state.loadingHandler.markSeenCallsLoading = true;
        },
        markSeenCallsSuccess: (state) => {
            state.loadingHandler.markSeenCallsLoading = false;
        },
        markSeenCallsFail: (state) => {
            state.loadingHandler.markSeenCallsLoading = false;
        },
    },
});

const {
    getActiveMeetingsCount,
    setActiveMeetingsCount,
    getActiveMeetingsCountFail,
    getActiveMeetingsCountSuccess,
    getActiveMeetings,
    getActiveMeetingsSuccess,
    getActiveMeetingsFail,
    markSeenCalls,
    markSeenCallsSuccess,
    markSeenCallsFail,
} = actions;

export {
    getActiveMeetings,
    getActiveMeetingsCount,
    getActiveMeetingsCountFail,
    getActiveMeetingsCountSuccess,
    getActiveMeetingsFail,
    getActiveMeetingsSuccess,
    markSeenCalls,
    markSeenCallsFail,
    markSeenCallsSuccess,
    reducer,
    setActiveMeetingsCount,
    SLICE_NAME,
};
