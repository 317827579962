import urls from '@/constants/endpoints/gateway';
import httpApi from '@/services/httpApi_new';
import {CreateShiftDTO, PersonnelWithRolesDTO, ShiftDTO} from '@/types/gatewayDataModels';

class ShiftManagementService {
    getShifts = (): Promise<ShiftDTO[]> => httpApi.get({url: urls.SHIFTS});
    createShift = (shift: CreateShiftDTO): Promise<void> => httpApi.post({url: urls.SHIFTS, data: shift});
    endShift = (id: number, shiftEndReason: string): Promise<void> =>
        httpApi.post({
            url: urls.END_SHIFT(id),
            data: {
                shiftEndReason,
            },
        });
    getPersonnel = (roles: string[]): Promise<PersonnelWithRolesDTO[]> =>
        httpApi.get({url: urls.GET_PERSONNEL_FULL_MODEL_BY_ROLE(roles)});
}

const shiftManagementService = new ShiftManagementService();
export default shiftManagementService;
