import urls from '../constants/endpoints/gateway';
import httpApi from './httpApi_new';

class AvailableAgentsService {
    getAgents = () =>
        httpApi.get({
            url: urls.ACTIVE_AGENTS,
            origin: window.REACT_APP_CORE_API_URL,
        });

    callTransferToAgentQueue = (data: {
        destinationAgentEmail: string;
        initialContactId: string;
        sourceAgentEmail: string;
    }) => httpApi.post({url: urls.UPDATE_CALL_CONTACT_ATTR, origin: window.REACT_APP_CORE_API_URL, data});
}

const availableAgentsService = new AvailableAgentsService();
export default availableAgentsService;
