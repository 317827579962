import routes from '@/constants/routes';
import lazyWithRetry from '@/utils/componentLoader';

import PrivateRoute from './PrivateRoute';

const Appointments = lazyWithRetry(() =>
    import('@/pages/Appointments').then((module) => ({default: module.Appointments}))
);
const NoRoles = lazyWithRetry(() => import('./NoRoles'));
const Administrate = lazyWithRetry(() =>
    import('@/pages/Administrate').then(async (module) => {
        return {default: module.Administrate};
    })
);

const Calendar = lazyWithRetry(() => import('@/pages/Calendar').then((module) => ({default: module.Calendar})));

const PerformanceImprovementLog = lazyWithRetry(() =>
    import('@/pages/PerformanceImprovementLog').then((module) => {
        return {default: module.PerformanceImprovementLog};
    })
);

const PatientPage = lazyWithRetry(() =>
    import('@/pages/Patient').then(async (module) => {
        return {default: module.Patient};
    })
);

const PatientsCensus = lazyWithRetry(() =>
    import('@/pages/Patients').then(async (module) => ({default: module.Patients}))
);

const ActionQueuePage = lazyWithRetry(() =>
    import('@/pages/ActionQueuePage').then(async (module) => {
        await import('@/pages/ActionQueuePage/redux/initialize');
        return module;
    })
);

const VendorDictionary = lazyWithRetry(() =>
    import('@/pages/VendorDictionary').then(async (module) => {
        await import('@/pages/VendorDictionary/redux/initialize');
        return {default: module.VendorDictionary};
    })
);

const MyPage = lazyWithRetry(() =>
    import('@/pages/MyPage').then((module) => ({
        default: module.MyPage,
    }))
);

const MultiMultiNotSupported = lazyWithRetry(() =>
    import('@/pages/MultiMultiNotSupported').then((module) => ({
        default: module.MultiMultiNotSupported,
    })),
);

const Orders = lazyWithRetry(() => import('@/pages/Orders'));

const CarePlanning = lazyWithRetry(() =>
    import('@/pages/CarePlanning').then(async (module) => ({
        default: module.CarePlanning,
    }))
);

const routeToComponentMapping = {
    '/patients': PatientsCensus,
    '/patient/:patientId': PatientPage,
    '/orders': Orders,
    [routes.APPOINTMENTS]: Appointments,
    '/no-roles': NoRoles,
    '/vendor-dictionary/:vendorId?/:siteId?/:actorId?/:activityId?': VendorDictionary,
    [routes.ACTION_QUEUE]: ActionQueuePage,
    '/calendar': Calendar,
    '/administrate': Administrate,
    '/my-page/:nurseId': MyPage,
    '/performance-improvement-log': PerformanceImprovementLog,
    [routes.MULTI_MULTI_NOT_SUPPORTED]: MultiMultiNotSupported,
    [routes.CARE_PLANNING()]: CarePlanning, // TODO: move care planning to patient folder after the structure will be changed
};

export const renderRoutes = (routes) => {
    return routes.map(({route, parentRoute, exact}) => (
        <PrivateRoute
            key={route}
            path={route}
            exact={!!exact}
            component={routeToComponentMapping[parentRoute ? parentRoute : route]}
        />
    ));
};
