import React, {MouseEvent, ReactNode} from 'react';
import {Button} from '@mui/material';
import classnames from 'classnames';

import MHLoader from '../MHLoader';

import styles from './mhButtons.module.scss';

type ButtonLoaderProps = {
    loaderSize?: string;
    externalLoaderClass?: string;
};

const ButtonLoader = ({loaderSize, externalLoaderClass}: ButtonLoaderProps) => {
    return <MHLoader color="primary" size={loaderSize} rootClassName={externalLoaderClass} />;
};

type MHButtonProps = {
    type?: 'button' | 'submit' | 'reset';
    buttonType?: string;
    smartLoading?: boolean;
    children?: ReactNode;
    disabled?: boolean;
    loading?: boolean;
    customButtonClasses?: {[key: string]: string};
    onClickHandler?: (
        value?:
            | (React.Dispatch<React.SetStateAction<boolean>> & {stopPropagation?: MouseEvent['stopPropagation']})
            | MouseEvent
    ) => void;
    text?: ReactNode;
    variant?: 'text' | 'outlined' | 'contained';
    size?: 'small' | 'medium' | 'large';
    color?: 'inherit' | 'primary' | 'secondary';
    loaderSize?: string;
    externalLoaderClass?: string;
    className?: string;
    id?: string;
};

const MHButton = ({
    type,
    buttonType,
    smartLoading,
    children,
    disabled,
    loading,
    customButtonClasses = {},
    onClickHandler,
    text = '',
    variant = 'outlined',
    size = 'small',
    color = 'primary',
    loaderSize = '12px',
    externalLoaderClass = '',
    className,
    id,
}: MHButtonProps) => {
    const [showLoading, setShowLoading] = React.useState(false);

    const onClickAction = (event: MouseEvent) => {
        if (smartLoading) {
            setShowLoading(true);
        }
        onClickHandler && onClickHandler(smartLoading ? setShowLoading : event);
    };

    const colorMapper: {[key: string]: string} = {
        primary: styles.primaryButton,
        primarySecond: styles.primarySecondButton,
        primaryThird: styles.primaryThirdButton,
        secondary: styles.secondaryButton,
        secondarySecond: styles.secondarySecond,
        secondaryGreen: styles.secondaryGreen,
        secondaryRed: styles.secondaryRed,
        secondaryYellow: styles.secondaryYellow,
        cancel: styles.greyButton,
        remove: styles.removeButton,
        removePrimary: styles.removePrimaryButton,
        action: styles.actionButton,
        warning: styles.warningButton,
        table: styles.outsideTableButton,
        ['link-primary']: styles.linkPrimaryButton,
        ['link-secondary']: styles.linkSecondaryButton,
        ['link-remove']: styles.linkRemoveButton,
    };

    const withLoading = !['link-primary', 'link-secondary', 'link-remove'].includes(buttonType);
    const defaultProps = {color, variant};

    const classes: {[key: string]: string} = {root: colorMapper[buttonType] || ''};
    //Merge external classes with base classes
    Object.keys(customButtonClasses).forEach((key) => {
        classes[key] = classnames(customButtonClasses[key], classes[key] || '');
    });

    return (
        <Button
            id={id}
            className={className}
            classes={classes}
            onClick={onClickAction}
            size={size}
            disabled={loading || showLoading || disabled}
            {...(buttonType ? {} : defaultProps)}
            type={type}
        >
            {text ? <span className={classnames({[styles.btn]: loading})}>{text}</span> : children}
            {withLoading && (loading || showLoading) && (
                <ButtonLoader loaderSize={loaderSize} externalLoaderClass={externalLoaderClass} />
            )}
        </Button>
    );
};

export default MHButton;
