import isEmpty from 'lodash/isEmpty';

import {MISSION_CONTROLS} from '@/constants/commonDataNames';
import {AppStartListening} from '@/redux/store/listenerMiddleware';

import {updateLocalStorage} from '../utils';
import {changeMissionControlSagaSelector} from './selectors';
import {
    getMissionControls,
    getMissionControlsFail,
    getMissionControlsSuccess,
    setSelectedMissionControlId,
} from './slice';
import {getCommonData, getCommonDataSuccess, setSelectedMissionControl} from './softDependencies';

const addFeatureListeners = (startListening: AppStartListening) => {
    startListening({
        actionCreator: getMissionControls,
        effect: async (action, listenerApi) => {
            try {
                listenerApi.dispatch(getCommonData([MISSION_CONTROLS]));
                await listenerApi.condition((action) => action.type === getCommonDataSuccess.type);

                const {missionControls, email} = changeMissionControlSagaSelector(listenerApi.getState());
                const selectedMissionControls = action.payload;
                const defaultMissionControlId = !isEmpty(missionControls) ? missionControls[0].id : 0;
                const selectedMissionControl = selectedMissionControls[email.toLowerCase()] || defaultMissionControlId;

                listenerApi.dispatch(setSelectedMissionControlId(+selectedMissionControl));
                listenerApi.dispatch(setSelectedMissionControl(+selectedMissionControl));

                listenerApi.dispatch(
                    getMissionControlsSuccess({email, selectedMissionControls, defaultMissionControlId})
                );

                updateLocalStorage(email.toLowerCase(), selectedMissionControl);
            } catch (error) {
                listenerApi.dispatch(getMissionControlsFail());
            }
        },
    });
};

export default addFeatureListeners;
