import {Grid} from '@mui/material';
import moment from 'moment/moment';

import {MHTypography} from '@/components/base';
import {formatDateTime, getDateTimeFormats} from '@/utils/timeFormatter';

import styles from './infoItem.module.scss';

const {DATE_FORMAT} = getDateTimeFormats();

type InfoItemProps = {
    birthDate: string;
    sex: string;
    townName: string;
    product: string;
};
export const InfoItem = ({birthDate, sex, townName, product}: InfoItemProps) => {
    return (
        <Grid container>
            <Grid item className={styles.infoItemsLeft}>
                <Grid container gap="16px">
                    <div>
                        <MHTypography variant="bodyM" color="black_60">
                            DOB
                        </MHTypography>
                        <MHTypography variant="bodyM" color="black_60">
                            Sex
                        </MHTypography>
                    </div>
                    <div className={styles.valueBlock} data-dd-action-name="Patient birth date">
                        <MHTypography noWrap variant="bodyM">{`${formatDateTime(
                            birthDate,
                            DATE_FORMAT,
                        )} (${moment().diff(birthDate, 'years')} y.o)`}</MHTypography>
                        <MHTypography noWrap variant="bodyM">
                            {sex || '-'}
                        </MHTypography>
                    </div>
                </Grid>
            </Grid>
            <Grid item className={styles.infoItemsRight}>
                <Grid container gap="16px">
                    <div>
                        <MHTypography variant="bodyM" color="black_60">
                            City
                        </MHTypography>
                        <MHTypography variant="bodyM" color="black_60">
                            Program
                        </MHTypography>
                    </div>
                    <div className={styles.valueBlock} data-dd-action-name="Patient city">
                        <MHTypography noWrap variant="bodyM">
                            {townName || '-'}
                        </MHTypography>
                        <MHTypography noWrap variant="bodyM">
                            {product || '-'}
                        </MHTypography>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};
