import {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import {Table, TableContainer} from '@mui/material';
import classnames from 'classnames';

import {MHLoader, MHTableBody, MHTextField} from '@/components/base';
import awsConnectService from '@/services/awsConnectApiService';

import {transferAgentsListSelector} from '../redux/selectors';
import {callTransferToAgentQueue, clearCallTransferData, getAvailableAgents, setCallTransferData} from '../redux/slice';
import {AvailableCallTransferAgentsProps} from '../redux/stateTypes';
import {AvailableCallTransferAgentsUser} from '../shared/types';
import {getColumnConfig} from './columnConfig';

import commonStyles from '../shared/common.module.scss';
import styles from './transferAgentsListTable.module.scss';

const withFullNameColumn = (arr: AvailableCallTransferAgentsUser[]) =>
    arr.map(({firstName, lastName, ...item}) => ({...item, fullName: `${firstName} ${lastName}`}));

export const TransferAgentsListTable = ({handlePopoverClose}: AvailableCallTransferAgentsProps) => {
    const [agents, setAgents] = useState([]);
    const [filteredAgents, setFilteredAgents] = useState<AvailableCallTransferAgentsUser[]>([]);
    const [searchString, setSearchString] = useState('');
    const {users, loadingHandler, user} = useSelector(transferAgentsListSelector, shallowEqual);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAvailableAgents());
    }, [dispatch]);

    useEffect(() => {
        const filteredUsers = users.filter(({login}) => login !== user.user.email);

        const agentsWithFullName = withFullNameColumn(filteredUsers);
        setAgents(agentsWithFullName);
        setFilteredAgents(agentsWithFullName);
    }, [user.user.email, users]);

    useEffect(() => {
        if (loadingHandler.callTransferLoading) {
            awsConnectService.transferToAgentQueue();
            handlePopoverClose();
            dispatch(clearCallTransferData());
        }
    }, [dispatch, handlePopoverClose, loadingHandler.callTransferLoading]);

    const callTransferToAgentQueueHandler = (destinationAgentEmail: string) => {
        dispatch(setCallTransferData({destinationAgentEmail}));
        dispatch(callTransferToAgentQueue());
    };

    const filterHandler = (str = '') => {
        setSearchString(str);
        setFilteredAgents(
            agents.filter(({fullName}: AvailableCallTransferAgentsUser) =>
                fullName?.toLowerCase().includes(str.toLowerCase())
            )
        );
    };

    if (loadingHandler.usersLoading) return <MHLoader className={styles.loader} />;

    const loadedAndNoData = !filteredAgents.length;

    return (
        <>
            <MHTextField
                placeholder="Search by Name"
                value={searchString}
                rootClassName={styles.searchField}
                icon={<SearchIcon className={styles.searchIcon} />}
                onChange={({target}) => {
                    filterHandler(target.value);
                }}
            />

            <TableContainer className={commonStyles.tableContainerRoot}>
                {loadedAndNoData && <div className={commonStyles.noDataMessage}>No available agents</div>}
                <Table>
                    <MHTableBody
                        data={filteredAgents}
                        columns={getColumnConfig({callTransferToAgentQueueHandler})}
                        externalCellStyles={commonStyles.tableRowDisabled}
                        externalRowStyles={(classes) => ({
                            ...classes,
                            root: classnames(classes.root, commonStyles.tableRoot),
                        })}
                    />
                </Table>
            </TableContainer>
        </>
    );
};
